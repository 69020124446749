import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Fastfood from '@material-ui/icons/Fastfood';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconContentPublish from '@material-ui/icons/Publish';
import { Storage } from 'aws-amplify';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    BooleanInput,
    NumberField,
    TextField,
    useDataProvider,
    useNotify,
    useTranslate,
} from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import config from '../../config';
const breadcrumbsData = [
    {
        text: 'Recipe',
        url: '',
        icon: <Fastfood />,
    },
];
const RecipeListAction = ({
    basePath,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    permissions,
}) => {
    const notify = useNotify();
    const [isLoading, setLoading] = useState(false);
    const translation = useTranslate();
    const dataProvider = useDataProvider();
    const uploadFile = async (event) => {
        setLoading(true);
        try {
            const applianceType = filterValues.applianceType || 'IH';
            const file = event.target.files[0];
            if (!file) {
                notify('no file');
                return;
            }
            // to uncomment with new bucket
            const key = `recipe/import/recipes_${new Date().getTime()}_${applianceType}.xlsx`;
            const files = await Storage.put(key, file, {
                contentType: file.type,
                // bucket: config.bucketBea
            });
            await dataProvider.upload(resource, {
                data: { fileName: key },
            });
            setLoading(false);
            notify('resources.language.notification.import-all-completed');
        } catch (e) {
            notify('resources.language.notification.error-import');
            setLoading(false);
        }
    };
    const downloadFile = async () => {
        const { bucket } = config;
        window.open(
            `https://${bucket}.s3.amazonaws.com/public/recipe/export/exportAllRecipe.xlsx`,
            '_blank'
        );
    };
    return (
        <Toolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {permissions &&
                permissions.recipe &&
                permissions.recipe.update &&
                permissions.recipe.create && (
                    <div>
                        <label
                            htmlFor="upload-file"
                            style={{ cursor: 'pointer' }}
                        >
                            <Button
                                color="primary"
                                label="Upload recipe"
                                disabled
                                style={{
                                    color: '#3f51b5',
                                    cursor: 'pointer',
                                }}
                            >
                                {isLoading ? (
                                    <CircularProgress size={25} thickness={2} />
                                ) : (
                                    <IconContentPublish />
                                )}
                                <span>
                                    {translation(
                                        'resources.language.action.upload_recipe'
                                    )}
                                </span>
                            </Button>
                        </label>
                        <input
                            id="upload-file"
                            type="file"
                            onChange={uploadFile}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            style={{
                                display: 'none',
                            }}
                        />
                    </div>
                )}
            {permissions && permissions.recipe && permissions.recipe.read && (
                <Button
                    color="primary"
                    label="Export recipe"
                    onClick={downloadFile}
                >
                    <GetAppIcon />
                    <span>
                        {translation('resources.language.action.export_recipe')}
                    </span>
                </Button>
            )}
            {/* {(permissions
        && permissions.language
        && permissions.language.create)
        && <CreateButton basePath={basePath} />} */}
        </Toolbar>
    );
};
// const { filterValues } = componentProps;
const RecipeFilter = (componentProps) => (
    <CustomFilter {...componentProps}>
        <CustomSelectInput
            source="applianceType"
            choices={[
                { id: 'IH', name: 'IH' },
                { id: 'OV', name: 'OV' },
                { id: 'MW', name: 'MW' },
                { id: 'KT', name: 'KT' },
                { id: 'BL', name: 'BL' },
                { id: 'TO', name: 'TO' },
                { id: 'HB', name: 'HB' },
                { id: 'CH', name: 'CH' },
                { id: 'AF', name: 'AF' },
                { id: 'BM', name: 'BM' },
            ]}
            alwaysOn
            initialValue="IH"
            allowEmpty={false}
        />
        {/* <TextInput source="id" alwaysOn />
    <TextInput source="course" alwaysOn /> */}
        <BooleanInput source="probe" alwaysOn />
        <BooleanInput source="vision" alwaysOn />
        <BooleanInput source="preciTaste" alwaysOn />
    </CustomFilter>
);
const RecipeList = ({ permissions, ...props }) => (
    <>
        <CustomBreadcrumbs data={breadcrumbsData} />
        <CustomList
            perPage={25}
            exporter={false}
            filters={<RecipeFilter />}
            {...props}
            actions={<RecipeListAction permissions={permissions} />}
            bulkActionButtons={
                permissions && permissions.recipe && permissions.recipe.delete
            }
        >
            <CustomDatagrid selectable rowClick="edit">
                <TextField source="id" />
                <TextField source="title" />
                <TextField source="difficulty" />
                <TextField source="course" />
                <NumberField source="duration" />
            </CustomDatagrid>
        </CustomList>
    </>
);
export default RecipeList;
