import React from 'react';
import PageviewIcon from '@material-ui/icons/Pageview';
import {
    CustomBreadcrumbs,
    CustomList,
    CustomFilter,
} from 'candy-commons-components';
import { TextField, TextInput, useTranslate } from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import DynamoPagination from '../../components/DynamoPagination';
const ListResourceFactory = (resourceName) => {
    return function ListResource({ permissions, ...props }) {
        const translation = useTranslate();
        const breadcrumbsData = [
            {
                text: translation(`resources.${resourceName}.name`),
                url: '',
                icon: <PageviewIcon />,
            },
        ];

        return (
            <>
                <CustomBreadcrumbs data={breadcrumbsData} />
                <CustomList
                    perPage={50}
                    exporter={false}
                    filters={
                        <CustomFilter {...props}>
                            <TextInput label="Name" source="name" alwaysOn />
                        </CustomFilter>
                    }
                    pagination={<DynamoPagination resource={resourceName} />}
                    {...props}
                    bulkActionButtons={
                        permissions &&
                        permissions[resourceName] &&
                        permissions[resourceName].delete
                    }
                >
                    <CustomDatagrid selectable rowClick="edit">
                        <TextField label="id" source="id" />
                        <TextField label="name" source="name" />
                    </CustomDatagrid>
                </CustomList>
            </>
        );
    };
};

export default ListResourceFactory;
