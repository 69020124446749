import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { Storage } from 'aws-amplify';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    FieldsGenerator,
} from 'candy-commons-components';
import { FileField, FileInput, Show, SimpleForm, TextInput } from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const VideoTutorialShow = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const { id } = props;
    const breadcrumbsData = [
        {
            text: 'Video Tutorials',
            url: '',
            icon: <SettingsIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator
            fields={[
                {
                    label: 'Type',
                    type: 'textField',
                    source: 'brand',
                },
                {
                    label: 'Link',
                    type: 'textField',
                    source: 'link',
                },
                {
                    label: 'Title',
                    type: 'textField',
                    source: 'title',
                },
                {
                    label: 'Photo',
                    type: 'inputField',
                    source: 'photo',
                },
            ]}
            record={record}
            {...props}
        />
    );
    const handleClick = async (event) => {
        // setLoading(true);
        const file = event;
        if (!file) {
            // notify('no file');
            return;
        }
        const key = `videoTutorial/${file.name}`;
        const upload = await Storage.put(key, file, {
            contentType: file.type,
            // bucket: bucketBea
        });
        // setLoading(false);
        // notify('resources.language.notification.import-all-completed');
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Show {...props}>
                <SimpleForm
                    toolbar={<CustomToolbar permissions={permissions} />}
                >
                    <ShowList />
                    <hr className={classes.width} />
                    <ContainerFieldsList>
                        {/*Appliance type*/}
                        <TextInput source="applianceType" />
                        {/*Brand*/}
                        <TextInput source="brand" />
                        <TextInput source="series" />
                        <TextInput source="link" label="Link" />
                        <TextInput source="title" label="Title" />
                        <FileInput
                            source="photo"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={handleClick}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                        {/* Languages */}
                        <TextInput
                            disabled
                            source="language"
                            label="Current languages"
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Show>
        </>
    );
};
export default VideoTutorialShow;
