import SettingsIcon from '@material-ui/icons/Settings';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import { TextField, useTranslate, TextInput } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';

const OnboardingList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.onboarding.name'),
            url: '',
            icon: <SettingsIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={100}
                pagination={<DynamoPagination resource="onboarding" />}
                exporter={false}
                filters={
                    <CommonApplianceFilter
                        applianceType
                        series
                        brand
                        seriesVersion
                        optionalFilters={[
                            <TextInput
                                label="Event Code"
                                source="eventCode"
                                alwaysOn
                            />,
                        ]}
                    />
                }
                sort={{ field: 'applianceType', order: 'DESC' }}
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField
                        source="applianceType"
                        label={'Appliance Type'}
                    />
                    <TextField source="eventCode" label={'Event Code'} />
                    <TextField source="brand" label={'Brand'} />
                    <TextField source="series" label={'Series'} />
                    <TextField
                        source="seriesVersion"
                        label={'Series Version'}
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default OnboardingList;
