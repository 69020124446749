import React, { useState } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { CustomBreadcrumbs } from 'candy-commons-components';
import {
    BooleanInput,
    TextInput,
    Create,
    SimpleForm,
    useTranslate,
    Toolbar,
    required,
    SaveButton,
} from 'react-admin';
import { PROMO, FORM_TYPE } from '../config';
import { useStyles } from '../styles';
import { QueryFormManager } from '../components/QueryFormManager';
import { ImpressionLogicInput } from './components/ImpressionLogicInput';
import {
    normalizeFormDataToSend,
    useGetSelectedCriteria,
} from '../hooks/normalizeFormDataToSend';
const PromoSpacesCreate = (props) => {
    const translation = useTranslate();
    const classes = useStyles();
    const [isShowNestedCriteria, toggleShowNestedCriteria] = useState(false);
    const [crt, getSelectedCriteriaHandler] = useGetSelectedCriteria();
    const breadcrumbsData = [
        {
            text: translation('resources.promo-spaces.name'),
            url: 'promo-spaces',
            icon: <SettingsIcon />,
        },
        {
            text: translation('resources.promo-spaces.name.create.name'),
            url: '',
        },
    ];
    const CustomToolbar = (props) => {
        return (
            <Toolbar {...props}>
                <SaveButton
                    redirect="list"
                    transform={(data) => {
                        return normalizeFormDataToSend({ data, crt });
                    }}
                />
            </Toolbar>
        );
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm
                    redirect="list"
                    className={classes.fullWidth}
                    toolbar={<CustomToolbar />}
                >
                    <BooleanInput
                        initialValue={true}
                        source="active"
                        label="Active"
                        validate={[required()]}
                        className={classes.fullWidth}
                    />
                    <TextInput
                        source="slug"
                        label="Slug"
                        validate={required()}
                        className={classes.fullWidth}
                    />
                    <TextInput
                        source="content.externalCmsStructureId"
                        label="External CMS Structure Id"
                        validate={[required()]}
                        className={classes.fullWidth}
                    />
                    <TextInput
                        source="content.externalCmsContentId"
                        label="External CMS Content Id"
                        validate={[required()]}
                        className={classes.fullWidth}
                    />
                    <TextInput
                        source="content.deepLink"
                        label="Hon deeplink"
                        className={classes.fullWidth}
                    />
                    <ImpressionLogicInput />
                    <QueryFormManager
                        variant={FORM_TYPE.CREATE}
                        promoType={PROMO.SPACE}
                        getActiveCriteria={getSelectedCriteriaHandler}
                    />
                </SimpleForm>
            </Create>
        </>
    );
};
export default PromoSpacesCreate;
