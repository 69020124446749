import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    FieldsGenerator,
} from 'candy-commons-components';
import {
    Edit,
    PasswordInput,
    ReferenceArrayInput,
    SimpleForm,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import { showListData } from './userStaticData';
const UserEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const isEditDisabled = !permissions?.user?.update;
    const translate = useTranslate();
    const { id } = props;
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator fields={showListData} record={record} {...props} />
    );
    const breadcrumbsData = [
        {
            text: translate('resources.user.name'),
            url: 'user',
            icon: <SupervisorAccountIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} id={props.id} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ShowList />
                    <ContainerFieldsList>
                        <PasswordInput
                            source="password"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    {permissions.role && permissions.role.read && (
                        <ReferenceArrayInput source="roles" reference="role">
                            <CustomSelectArrayInput
                                optionText="name"
                                validate={[required()]}
                                disabled={isEditDisabled}
                            />
                        </ReferenceArrayInput>
                    )}
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default UserEdit;
