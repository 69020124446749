import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
} from 'candy-commons-components';
import {
    BooleanInput,
    Create,
    SimpleForm,
    TextInput,
    useTranslate,
} from 'react-admin';
import { useStyles } from './styles';
import COUNTRIES from '../../data/countries.json';
const MultiAccount = (props) => {
    const translation = useTranslate();
    const styles = useStyles();
    const breadcrumbsData = [
        {
            text: translation('resources.multi-account.name'),
            url: 'multi-account',
            icon: <SettingsIcon />,
        },
        {
            text: translation('resources.multi-account.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm redirect="list">
                    <ContainerFieldsList>
                        <CustomSelectArrayInput
                            label="Country"
                            source="country"
                            choices={COUNTRIES}
                        />
                        <TextInput
                            initialValue={''}
                            label="Restricted Users (Use | separator)"
                            source="restrictedUsers"
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        {/*Country code*/}
                        <BooleanInput
                            initialValue={false}
                            label="Group Experience"
                            source="groupExperience"
                        />
                        <BooleanInput
                            initialValue={false}
                            label="Invitation"
                            source="invitation"
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default MultiAccount;
