import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import IconContentDelete from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import {
    Button,
    useDataProvider,
    useNotify,
    useGetList,
    useTranslate,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
export const TranslationDeleteButton = ({ id, record, languages }) => {
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [isLoading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const translation = useTranslate();
    const { language } = record;
    const { ids = [], data } = useGetList('language', {}, {});
    const allLanguages = ids.map((x) => ({ id: x, name: data[x].langName }));
    const findDeleteLanguageText =
        allLanguages && language && allLanguages.find((x) => x.id === language);
    const deleteLanguageText =
        (findDeleteLanguageText && findDeleteLanguageText.name) || '';
    const handleClick = async () => {
        try {
            setLoading(true);
            await dataProvider.update('translation', {
                id,
                data: { [`lang_${language}`]: null },
            });
            notify('resources.translation.notification.deleted');
        } catch (ex) {
            console.error(ex.message);
            notify('ra.page.error', 'warning');
        }
        setLoading(false);
    };
    const dialogHandleClick = () => {
        setShowDialog(true);
    };
    const dialogHandleCloseClick = () => {
        setShowDialog(false);
    };
    return (
        <Box className={classes.buttonContainer}>
            {language !== 'en' ? (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={dialogHandleClick}
                    >
                        {isLoading ? (
                            <CircularProgress
                                color="primary"
                                size={18}
                                thickness={8}
                            />
                        ) : (
                            <IconContentDelete />
                        )}
                    </Button>
                    <Dialog
                        fullWidth
                        open={showDialog}
                        onClose={dialogHandleCloseClick}
                        className={classes.dialog}
                    >
                        <DialogTitle>
                            {`${translation(
                                'resources.translation.translation_form.fields.delete'
                            )} ${deleteLanguageText}?`}
                        </DialogTitle>
                        <Box className={classes.dialogButtonContainer}>
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.dialogButton}
                                    label="resources.translation.button.cancel"
                                    onClick={dialogHandleCloseClick}
                                />
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.dialogButton}
                                    onClick={handleClick}
                                    label="resources.translation.button.delete"
                                />
                            </Box>
                        </Box>
                    </Dialog>
                </>
            ) : (
                ''
            )}
        </Box>
    );
};
const useStyles = makeStyles({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '400px',
        },
    },
    dialogButtonContainer: {
        display: 'flex',
        margin: 20,
        marginTop: 50,
        '& >.MuiBox-root': {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
        },
    },
    dialogButton: {
        width: 130,
    },
    buttonContainer: {
        minWidth: 64,
    },
});
export default TranslationDeleteButton;
