import React from 'react';
import RowingIcon from '@material-ui/icons/Rowing';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    BooleanInput,
    Create,
    ImageField,
    SimpleForm,
    SimpleShowLayout,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import connectivityOptions from '../../data/connectivityOptions.json';
import connectivityOptionsExtra from '../../data/connectivityOptionsExtra.json';
import countries from '../../data/countries.json';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
const HintCategoryCreate = (props) => {
    const translation = useTranslate();
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    const breadcrumbsData = [
        {
            text: translation('resources.hints-category.name'),
            url: 'hints',
            icon: <RowingIcon />,
        },
        {
            text: translation('resources.hints-category.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm redirect="list">
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceTypes}
                            validate={required()}
                        />
                        <TextInput source="code" validate={required()} />
                        <TextInput source="title" validate={required()} />
                        <TextInput label="Description" source="description" />
                        <CustomSelectArrayInput
                            label="Brand"
                            source="brand"
                            choices={brands}
                        />
                        <CustomSelectInput
                            label="Series"
                            source="series"
                            choices={series}
                        />
                        <CustomSelectInput
                            label="Series Version"
                            source="seriesVersion"
                            choices={seriesVersion}
                        />
                        <CustomSelectArrayInput
                            label="Countries"
                            source="countries"
                            choices={countries}
                            parse={(x) => (x.includes('ALL') ? ['ALL'] : x)}
                        />
                        <CustomSelectArrayInput
                            source="connectivity"
                            allowEmpty
                            choices={[
                                ...connectivityOptions,
                                ...connectivityOptionsExtra,
                            ]}
                        />
                        <BooleanInput
                            label="active"
                            source="active"
                            initialValue
                        />
                    </ContainerFieldsList>
                    <SimpleShowLayout>
                        <ImageField source="imagePath" addLabel={false} />
                    </SimpleShowLayout>
                    <ImageInput
                        source="pictures"
                        accept="image/*"
                        placeholder={
                            <p>
                                {translation(
                                    'resources.hints.fields.input_file'
                                )}
                            </p>
                        }
                    >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default HintCategoryCreate;
