import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { Storage } from 'aws-amplify';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
    FieldsGenerator,
} from 'candy-commons-components';
import {
    ArrayInput,
    Edit,
    FileField,
    ImageField,
    FileInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import languages from '../../data/languages.json';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
} from '../../selectors/cmsConfig';
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const VideoTutorialEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const isEditDisabled = !permissions?.['video-tutorial']?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: 'Video Tutorials',
            url: 'video-tutorial',
            icon: <SettingsIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator
            fields={[
                {
                    label: 'Type',
                    type: 'textField',
                    source: 'brand',
                },
                {
                    label: 'Type',
                    type: 'textField',
                    source: 'series',
                },
                {
                    label: 'Link',
                    type: 'textField',
                    source: 'link',
                },
                {
                    label: 'Title',
                    type: 'textField',
                    source: 'title',
                },
                {
                    label: 'Photo',
                    type: 'textField',
                    source: 'photo',
                },
            ]}
            record={record}
            {...props}
        />
    );
    const handleClick = async (event) => {
        // setLoading(true);
        const file = event;
        if (!file) {
            // notify('no file');
            return;
        }
        const key = `videoTutorial/${file.name}`;
        const upload = await Storage.put(key, file, {
            contentType: file.type,
            // bucket: bucketBea
        });
        // setLoading(false);
        // notify('resources.language.notification.import-all-completed');
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props} undoable={false}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    {/* <ShowList /> */}
                    <hr className={classes.width} />
                    <ContainerFieldsList>
                        {/*Appliance type*/}
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceTypes}
                            disabled={isEditDisabled}
                        />
                        {/*Brand*/}
                        <CustomSelectInput
                            source="brand"
                            choices={brands}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="series"
                            choices={series}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="link"
                            label="Link"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="title"
                            label="Title"
                            disabled={isEditDisabled}
                        />
                        {/* photo */}
                        <ImageField
                            disabled
                            source="photo"
                            label="Current photo"
                        />
                        {/* Languages */}
                        <TextInput
                            disabled
                            source="language"
                            label="Current languages"
                        />
                        <ArrayInput source="language" disabled={isEditDisabled}>
                            <SimpleFormIterator disableRemove>
                                <CustomSelectInput choices={languages} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </ContainerFieldsList>
                    {!isEditDisabled && (
                        <FileInput
                            source="photo"
                            label="photo(jpeg, 152x107)"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={handleClick}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                    )}
                </SimpleForm>
            </Edit>
        </>
    );
};
export default VideoTutorialEdit;
