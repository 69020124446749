import Toolbar from '@material-ui/core/Toolbar';
import SettingsIcon from '@material-ui/icons/Settings';

import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import { CreateButton, TextField, useTranslate } from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import config from '../../config';

const { basePath: apiPath } = config;

const AssetListAction = ({
    basePath,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    permissions,
}) => {
    const username = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
    );
    const token = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`
    );

    return (
        <Toolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {permissions && permissions.asset && permissions.asset.create && (
                <CreateButton basePath={basePath} />
            )}
        </Toolbar>
    );
};

const IamConfigurationList = ({ permissions, ...props }) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.iam-configuration.name'),
            url: '',
            icon: <SettingsIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={25}
                exporter={false}
                actions={<AssetListAction permissions={permissions} />}
                bulkActionButtons={
                    permissions &&
                    permissions['iam-configuration'] &&
                    permissions['iam-configuration'].delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="applicationType" />
                    <TextField source="minAppVersion" />
                    <TextField source="maxAppVersion" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};

export default IamConfigurationList;
