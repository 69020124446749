import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
    FieldsGenerator,
} from 'candy-commons-components';
import moment from 'moment';
import {
    DateInput,
    Edit,
    FormDataConsumer,
    ImageField,
    NumberInput,
    SimpleForm,
    TextInput,
    number,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import CODE_TYPES from '../../data/campaignCodeType.json';
import COUNTRIES from '../../data/countries.json';
import discoverOrderValidator from '../../validators/discoverOrderValidator';
const staticData = [
    {
        label: 'Slug',
        type: 'textField',
        source: 'slug',
    },
];
const useStyles = makeStyles({
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
    width: {
        width: '100% !important',
    },
});
const CampaignsEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const { id } = props;
    const translation = useTranslate();
    const isEditDisabled = !permissions?.campaigns?.update;
    const breadcrumbsData = [
        {
            text: translation('resources.campaigns.name'),
            url: 'campaigns',
            icon: <PageviewIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const dateFormatter = (v) => {
        const d = new Date(v);
        if (Number.isNaN(d)) return null;
        return moment(d).format('YYYY-MM-DD');
    };
    const ShowList = ({ record, ...p }) => (
        <FieldsGenerator fields={staticData} record={record} {...p} />
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ShowList />
                    <b>General</b>
                    <br />
                    <ContainerFieldsList>
                        <TextInput
                            source="title"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="description"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <NumberInput
                            min={1}
                            label="Order"
                            source="order"
                            validate={discoverOrderValidator}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <DateInput
                            source="from"
                            variant="outlined"
                            label="from"
                            format={(d) => dateFormatter(d)}
                            parse={(d) => dateFormatter(d)}
                            disabled={isEditDisabled}
                        />
                        <DateInput
                            source="to"
                            variant="outlined"
                            label="to"
                            format={(d) => dateFormatter(d)}
                            parse={(d) => dateFormatter(d)}
                            disabled={isEditDisabled}
                        />
                        <NumberInput
                            min={1}
                            label="Active duration"
                            source="activeDuration"
                            validate={[
                                number(),
                                (value) => {
                                    if (value < 0) {
                                        return 'Order must be a positive number';
                                    }
                                    if (!Number.isInteger(value)) {
                                        return 'Order must be a decimal number';
                                    }
                                    return null;
                                },
                            ]}
                            disabled={isEditDisabled}
                        />
                        <NumberInput
                            min={1}
                            label="Push reminder"
                            source="pushReminder"
                            validate={[
                                number(),
                                required(),
                                (value) => {
                                    if (value < 0) {
                                        return 'Order must be a positive number';
                                    }
                                    if (!Number.isInteger(value)) {
                                        return 'Order must be a decimal number';
                                    }
                                    return null;
                                },
                            ]}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <CustomSelectArrayInput
                            label="Countries"
                            source="countries"
                            choices={COUNTRIES}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="shopWebsiteUrl"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="shopAppUrl"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <FormDataConsumer>
                            {({ formData }) => (
                                <CustomSelectInput
                                    label="Code type"
                                    source="codeType"
                                    validate={required()}
                                    choices={CODE_TYPES}
                                    disabled={
                                        formData.codeType || isEditDisabled
                                    }
                                />
                            )}
                        </FormDataConsumer>
                    </ContainerFieldsList>
                    {/* colors */}
                    <hr className={classes.width} />
                    <div>
                        <b>Colors</b>
                        <div>(format: #ababab)</div>
                    </div>
                    <br />
                    <ContainerFieldsList>
                        <TextInput
                            source="mainColor1"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="mainColor2"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="foregroundColor1"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="foregroundColor2"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="activeMainColor1"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="activeMainColor2"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="activeForegroundColor1"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="activeForegroundColor2"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    {/* Card dashboard */}
                    <hr className={classes.width} />
                    <div>
                        <b>Card dashboard</b>
                    </div>
                    <br />
                    {/* images related to card dashboard */}
                    {[
                        'cardDiscountImg',
                        'cardBackgroundImg',
                        'cardProductImg',
                        'activeCardBackgroundImg',
                        'activeCardProductImg',
                        'cardBrandLogo',
                    ].map((field) => (
                        <>
                            {!isEditDisabled && (
                                <ImageInput
                                    source={field}
                                    accept="image/*"
                                    placeholder={
                                        <p>
                                            {translation(
                                                'resources.asset.fields.input_file'
                                            )}
                                        </p>
                                    }
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            )}
                            <ImageField source={field} title="title" label="" />
                            <br />
                        </>
                    ))}
                    <ContainerFieldsList>
                        <TextInput
                            source="cardPromoTxt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="cardPromoBtnTxt"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    {/* campaign details */}
                    <hr className={classes.width} />
                    <div>
                        <b>Campaign details (header)</b>
                    </div>
                    <br />
                    {/* images related to campaign details */}
                    {!isEditDisabled && (
                        <ImageInput
                            source="detailHeaderDiscountImg"
                            accept="image/*"
                            placeholder={
                                <p>
                                    {translation(
                                        'resources.asset.fields.input_file'
                                    )}
                                </p>
                            }
                        >
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    )}
                    <ImageField
                        source="detailHeaderDiscountImg"
                        title="title"
                        label=""
                    />
                    <br />
                    {!isEditDisabled && (
                        <ImageInput
                            source="detailHeaderProductImg"
                            accept="image/*"
                            placeholder={
                                <p>
                                    {translation(
                                        'resources.asset.fields.input_file'
                                    )}
                                </p>
                            }
                        >
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    )}
                    <ImageField
                        source="detailHeaderProductImg"
                        title="title"
                        label=""
                    />
                    <br />
                    <ContainerFieldsList>
                        <TextInput
                            source="detailHeaderDiscountTxt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailHeaderDescriptionTxt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailHeaderQuestionTxt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailHeaderOption1Txt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailHeaderOption2Txt"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    {/* campaign details banner */}
                    <hr className={classes.width} />
                    <div>
                        <b>Campaign details (banner)</b>
                    </div>
                    <br />
                    {!isEditDisabled && (
                        <ImageInput
                            source="detailBannerProductImg"
                            accept="image/*"
                            placeholder={
                                <p>
                                    {translation(
                                        'resources.asset.fields.input_file'
                                    )}
                                </p>
                            }
                        >
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    )}
                    <ImageField
                        source="detailBannerProductImg"
                        title="title"
                        label=""
                    />
                    <br />
                    {!isEditDisabled && (
                        <ImageInput
                            source="detailBannerBackgroundImg"
                            accept="image/*"
                            placeholder={
                                <p>
                                    {translation(
                                        'resources.asset.fields.input_file'
                                    )}
                                </p>
                            }
                        >
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    )}
                    <ImageField
                        source="detailBannerBackgroundImg"
                        title="title"
                        label=""
                    />
                    <br />
                    <ContainerFieldsList>
                        <TextInput
                            source="detailBannerTitleTxt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailBannerTitleTxtSmall"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailBannerSubtitleTxt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailBannerDescriptionTxt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailBannerBtnTxt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailBannerShowCodeBtnTxt"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="detailBannerShowCodeSubtitleTxt"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    {/* wine list banner */}
                    <hr className={classes.width} />
                    <div>
                        <b>Wine list (banner)</b>
                    </div>
                    <br />
                    {[
                        'wineListBannerBackgroundImg',
                        'wineListBannerBackgroundImgActive',
                        'wineListBannerActiveImg',
                        'wineListBannerProductImgActive',
                    ].map((field) => (
                        <>
                            {!isEditDisabled && (
                                <ImageInput
                                    source={field}
                                    accept="image/*"
                                    placeholder={
                                        <p>
                                            {translation(
                                                'resources.asset.fields.input_file'
                                            )}
                                        </p>
                                    }
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            )}
                            <ImageField source={field} title="title" label="" />
                            <br />
                        </>
                    ))}
                    <ContainerFieldsList>
                        <TextInput
                            source="wineListBannerTitle"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="wineListBannerTitleSmall"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="wineListBannerSubtitle"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="wineListBannerBtnTxt"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
export default CampaignsEdit;
