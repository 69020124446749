import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
} from 'candy-commons-components';
import moment from 'moment';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';

const useStyles = makeStyles({
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
    width: {
        width: '100% !important',
    },
});

const EditResourceFactory = (resourceName) => {
    return function EditResource({ permissions = {}, ...props }) {
        const classes = useStyles();
        const { id } = props;
        const translation = useTranslate();
        const isEditDisabled = !permissions?.[resourceName]?.update;
        const breadcrumbsData = [
            {
                text: translation(`resources.${resourceName}.name`),
                url: resourceName,
                icon: <PageviewIcon />,
            },
            {
                text: id,
                url: '',
            },
        ];

        return (
            <>
                <CustomBreadcrumbs data={breadcrumbsData} />
                <Edit className={classes.reverseButtonSave} {...props}>
                    <SimpleForm
                        toolbar={
                            <CustomToolbar
                                permissions={permissions}
                                isEditDisabled={isEditDisabled}
                            />
                        }
                    >
                        <ContainerFieldsList>
                            <TextInput source="id" disabled={true} />
                            <TextInput source="name" validate={required()} />
                        </ContainerFieldsList>
                    </SimpleForm>
                </Edit>
            </>
        );
    };
};

export default EditResourceFactory;
