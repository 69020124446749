import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RowingIcon from '@material-ui/icons/Rowing';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
    CustomSelectArrayInput,
} from 'candy-commons-components';
import React from 'react';
import {
    ArrayInput,
    BooleanInput,
    Create,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import programsListPageApplianceType from '../../data/programsListPageApplianceType.json';
import programsListPageType from '../../data/programsListPageType.json';
import { useSelector } from 'react-redux';
import { getBrands, getSeries } from '../../selectors/cmsConfig';
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const ProgramsListPageCreate = (props) => {
    const classes = useStyles();
    const translation = useTranslate();
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const breadcrumbsData = [
        {
            text: translation('resources.programs-list-page.name'),
            url: 'programs-list-page',
            icon: <RowingIcon />,
        },
        {
            text: translation('resources.programs-list-page.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm redirect="list">
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={[...programsListPageApplianceType]}
                            validate={required()}
                        />
                        <CustomSelectInput
                            label="Type"
                            source="type"
                            choices={[...programsListPageType]}
                            validate={required()}
                        />
                        <CustomSelectInput
                            label="Brand"
                            source="brand"
                            choices={brands}
                            validate={required()}
                        />
                        <CustomSelectArrayInput
                            choices={series}
                            label="Series"
                            source="series"
                            validate={required()}
                        />
                        <TextInput
                            label="Title"
                            source="title"
                            validate={required()}
                        />
                        <TextInput
                            label="Subtitle"
                            source="subtitle"
                            validate={required()}
                        />
                    </ContainerFieldsList>
                    <BooleanInput label="Active" source="isActive" />
                    <hr className={classes.width} />
                    <ArrayInput source="programs">
                        <SimpleFormIterator>
                            <TextInput
                                source="programName"
                                validate={required()}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default ProgramsListPageCreate;
