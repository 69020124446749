export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_PAGINATION_TOKEN = 'SET_PAGINATION_TOKEN';
export const setPagination = (params) => async (dispatch, getState) => {
    // const state = getState();
    // const payload = state.custom && state.custom.count ? state.custom.count + 1 : 1;
    dispatch({ type: SET_PAGINATION, payload: params });
};
export const setPaginationToken = (params) => async (dispatch, getState) => {
    dispatch({ type: SET_PAGINATION_TOKEN, payload: params });
};
