import { useEffect, useState } from 'react';
const useEnrollmentForm = ({ enrollmentType, singlePairing }) => {
    const [resetGestureDisabled, setResetGestureDisabled] = useState(false);
    const [easyEnrollmentDisabled, setEasyEnrollmentDisabled] = useState(false);
    const [standardEnrollmentDisabled, setStandardEnrollmentDisabled] =
        useState(false);
    const [singlePairingDisabled, setSinglePairingDisabled] = useState(false);
    useEffect(() => {
        setEasyEnrollmentDisabled(singlePairing || !!enrollmentType);
        setStandardEnrollmentDisabled(singlePairing || !!enrollmentType);
        setSinglePairingDisabled(!!enrollmentType);
        setResetGestureDisabled(!enrollmentType);
    }, [enrollmentType, singlePairing]);
    return {
        resetGestureDisabled,
        easyEnrollmentDisabled,
        standardEnrollmentDisabled,
        singlePairingDisabled,
    };
};
export default useEnrollmentForm;
