import React from 'react';
import AssistantIcon from '@material-ui/icons/Assistant';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    ArrayInput,
    BooleanInput,
    Create,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useTranslate,
} from 'react-admin';
import applianceTypeWash from '../../data/applianceTypeWash.json';
import COUNTRIES from '../../data/countries.json';
import { useSelector } from 'react-redux';
import { getBrands, getSeries } from '../../selectors/cmsConfig';
import { useStyles } from './styles';
const EcoDelayStart = (props) => {
    const translation = useTranslate();
    const styles = useStyles();
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const breadcrumbsData = [
        {
            text: translation('resources.eco-delay-start.name'),
            url: 'eco-delay-start',
            icon: <AssistantIcon />,
        },
        {
            text: translation('resources.eco-delay-start.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm redirect="list">
                    <ContainerFieldsList>
                        <ContainerFieldsList>
                            {/*Country code*/}
                            <TextInput label="Name" source="name" />
                            <CustomSelectArrayInput
                                required
                                label="Countries"
                                source="countries"
                                choices={COUNTRIES}
                            />
                            <BooleanInput
                                source="countriesToExclude"
                                label="Are countries to exclude?"
                            />
                        </ContainerFieldsList>
                        <ArrayInput
                            className={styles.arrayInput}
                            source="ecoDelayStartArray"
                            label="Smart Eco Delay Activation"
                        >
                            <SimpleFormIterator
                                className={{ form: styles.form }}
                                getItemLabel={(index) => `${index + 1}-key`}
                            >
                                {/*Appliance type*/}
                                <CustomSelectInput
                                    source="applianceType"
                                    choices={applianceTypeWash}
                                    required
                                />
                                {/*Brand*/}
                                <ArrayInput source="brand">
                                    <SimpleFormIterator>
                                        <CustomSelectInput
                                            source="brand"
                                            choices={brands}
                                        />
                                        {/* <BooleanInput label="" source="visibility" /> */}
                                    </SimpleFormIterator>
                                </ArrayInput>
                                {/*series*/}
                                <ArrayInput source="series">
                                    <SimpleFormIterator>
                                        <CustomSelectInput
                                            source="series"
                                            choices={series}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                                <TextInput source="applianceModel" />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <></>
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default EcoDelayStart;
