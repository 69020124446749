export const showListData = [
    {
        label: 'resources.assistance.fields.countryCode',
        type: 'textField',
        source: 'countryCode',
    },
    {
        label: 'resources.assistance.fields.secondaryCountryCode',
        type: 'textField',
        source: 'secondaryCountryCode',
    },
    {
        label: 'resources.assistance.fields.brand',
        type: 'textField',
        source: 'brand',
    },
    {
        label: 'resources.assistance.fields.applianceType',
        type: 'textField',
        source: 'applianceType',
    },
];
