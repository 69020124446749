import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import { TextField, TextInput } from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';

const breadcrumbsData = [
    {
        text: 'Video Tutorials',
        url: '',
        icon: <SettingsIcon />,
    },
];
const VideoTutorialList = ({ permissions, ...props }) => (
    <>
        <CustomBreadcrumbs data={breadcrumbsData} />
        <CustomList
            perPage={25}
            filters={
                <CommonApplianceFilter
                    applianceType
                    brand
                    series
                    optionalFilters={[<TextInput alwaysOn source="title" />]}
                />
            }
            exporter={false}
            {...props}
            bulkActionButtons={
                permissions &&
                permissions['video-tutorial'] &&
                permissions['video-tutorial'].delete
            }
        >
            <CustomDatagrid selectable rowClick="edit">
                {/* <TextField source="id" /> */}
                <TextField source="applianceType" />
                <TextField source="brand" />
                <TextField source="series" />
                <TextField source="link" />
                <TextField source="title" />
                <TextField source="photo" />
                <TextField source="language" />
            </CustomDatagrid>
        </CustomList>
    </>
);
export default VideoTutorialList;
