import SettingsIcon from '@material-ui/icons/Settings';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
} from 'candy-commons-components';
import React, { cloneElement } from 'react';
import {
    ArrayField,
    BooleanField,
    ChipField,
    SingleFieldList,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin';
import DynamoPagination from '../../../components/DynamoPagination';
import CustomDatagrid from '../../../components/organisms/customDatagrid';
const breadcrumbsData = [
    {
        text: 'Campaigns',
        url: '',
        icon: <SettingsIcon />,
    },
];
const PromoCampaignList = ({ permissions, ...props }) => {
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                {...props}
                exporter={false}
                bulkActionButtons={
                    permissions &&
                    permissions['promo-campaigns'] &&
                    permissions['promo-campaigns'].delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="id" />
                    <TextField source="slug" />
                    <BooleanField source="active" />
                    <TextField source="content.externalCmsContentId" />
                    <TextField source="content.externalCmsStructureId" />
                    <TextField source="content.redemptions" />
                    <TextField source="content.redemptionsPerUser" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default PromoCampaignList;
