export default [
    {
        id: 'true',
        name: 'true',
    },
    {
        id: 'false',
        name: 'false',
    },
];
