import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
const getActiveCrt = ({ level, crt }) => {
    const criteria = level === 1 ? crt?.criteria : crt?.nestedCriteria;
    return criteria
        ? Object.keys(criteria).filter((key) => criteria[key].isActive)
        : [];
};
const transform = ({ level, data, crt }) => {
    const dt = level === 1 ? data?.criteria : data?.nestedCriteria;
    const activeCriteria = getActiveCrt({ level, crt });
    if (crt?.nestedCriteria === null && level === 2) {
        return null;
    }
    return dt
        ? Object.keys(dt).reduce((acc, key) => {
              if (key === 'connective') {
                  return { ...acc, [key]: dt[key] };
              }
              if (activeCriteria.includes(key)) {
                  return { ...acc, [key]: dt[key] };
              } else {
                  if (key === 'position') {
                      return {
                          ...acc,
                          [key]: {
                              rule: null,
                              value: {
                                  lat: null,
                                  lon: null,
                                  radius: null,
                              },
                          },
                      };
                  }
                  return { ...acc, [key]: { rule: null, value: null } };
              }
          }, {})
        : null;
};
export const normalizeFormDataToSend = ({ data, crt }) => {
    const resultLevel1 = transform({ level: 1, data, crt });
    const resultLevel2 = transform({ level: 2, data, crt });
    return {
        ...data,
        criteria: resultLevel1,
        nestedCriteria: resultLevel2,
    };
};
export const useGetSelectedCriteria = () => {
    const [crt, setCrt] = useState(null);
    const getSelectedCriteriaHandler = ({ criteria, level }) => {
        if (level === 1) {
            setCrt((prev) => ({ ...prev, criteria }));
        } else {
            setCrt((prev) => ({
                ...prev,
                nestedCriteria: criteria,
            }));
        }
    };
    return [crt, getSelectedCriteriaHandler];
};
export const useGetOneQuery = (uri, id) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setHasError] = useState(false);
    const [hasNestedCriteria, toggleHasNestedCriteria] = useState(false);
    useEffect(() => {
        if (id) {
            if (!data) {
                setIsLoading(true);
                dataProvider
                    .getOne(uri, { id: id })
                    .then(({ data: response }) => {
                        setData(response);
                        const hasNC = Object.values(
                            response.nestedCriteria
                        ).some((v) => v.rule);
                        if (hasNC) {
                            toggleHasNestedCriteria(true);
                        }
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setHasError(error);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading]);
    return {
        data,
        isLoading,
        error,
        hasNestedCriteria,
        toggleHasNestedCriteria,
    };
};
export const useGetOne = (uri, id) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setHasError] = useState(false);
    useEffect(() => {
        if (id) {
            if (!data) {
                setIsLoading(true);
                dataProvider
                    .getOne(uri, { id: id })
                    .then(({ data: response }) => {
                        setData(response);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setHasError(error);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading]);
    return {
        data,
        isLoading,
        error,
    };
};
