'use strict';
Object.defineProperty(exports, '__esModule', {
    value: true,
});
var _extends =
    Object.assign ||
    function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];
            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
var _get2 = require('lodash/get');
var _get3 = _interopRequireDefault(_get2);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true,
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
var STORAGE_KEY = 'raColumnsConfig';
// Very basic storage helper
// values are stored in browser localStorage
var getRootValue = function getRootValue() {
    try {
        return JSON.parse(window.localStorage.getItem(STORAGE_KEY));
    } catch (e) {
        return undefined;
    }
};
var setRootValue = function setRootValue(value) {
    try {
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
    } catch (e) {}
};
var LocalStorage = {
    get: function get(key) {
        return (0, _get3.default)(getRootValue(), key);
    },
    set: function set(key, value) {
        var oldValue = getRootValue();
        setRootValue(_extends({}, oldValue, _defineProperty({}, key, value)));
    },
};
exports.default = LocalStorage;
