import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    ImageField,
    SimpleShowLayout,
    FormDataConsumer,
    useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { textAlign } from '@material-ui/system';
import ImageInput from '../ImageInput';
const ConditionalWrapper = ({ condition, children, ...rest }) => {
    return !condition || condition(rest)
        ? React.Children.map(children, (child) => {
              return React.cloneElement(child, rest);
          })
        : null;
};
const EmptyImage = () => {
    const classes = useStyle();
    return (
        <div className={classes.empty}>
            <div className={classes.emptyImgBox}>
                <CropOriginalIcon color="disabled" style={{ fontSize: 50 }} />
                <p>EMPTY</p>
            </div>
        </div>
    );
};
const CustomUpload = ({
    formData,
    imageSource,
    imageInputSource,
    imageFieldSource,
    isEditDisabled,
    ...otherProps
}) => {
    const classes = useStyle();
    const [emptyImg, setEmptyImg] = useState(false);
    const CustomImageField = ({ formData, imageSource, ...rest }) => {
        const form = useForm();
        const classes = useStyle();
        const translation = useTranslate();
        return (
            <>
                <ImageField source={imageSource} label="" {...rest} />
                {/* TODO: fixare perchè rimuove tutti i dati dal form non solo quello dell immagine */}
                {/* TODO: da non visualizzare se required? */}
                {/* <Button
          color="secondary"
          variant="outlined"
          className={classes.buttonRemove}
          startIcon={<DeleteIcon />}
          onClick={() => {form.change({ imageSource }, null); setEmptyImg(true)}}
          {...rest}
        >
          remove
        </Button> */}
            </>
        );
    };
    return (
        <Box className={classes.container}>
            <ConditionalWrapper
                condition={(props) => {
                    return props.record;
                }}
                {...otherProps}
            >
                <SimpleShowLayout>
                    <p>Current image</p>
                    {emptyImg ? <EmptyImage /> : ''}
                    <FormDataConsumer>
                        {(formDataProps) =>
                            formDataProps.formData[imageSource] && (
                                <>
                                    <CustomImageField
                                        imageSource={imageSource}
                                        {...formDataProps}
                                    />
                                </>
                            )
                        }
                    </FormDataConsumer>
                </SimpleShowLayout>
                <SimpleShowLayout>
                    {!isEditDisabled && (
                        <Box style={{ width: '100%' }}>
                            <p>Upload/change image</p>
                            <ImageInput
                                className={classes.imageInput}
                                source={imageInputSource}
                                accept="image/*"
                                label=""
                                placeholder={
                                    <div>
                                        <PublishIcon
                                            style={{ fontSize: 50 }}
                                            color="disabled"
                                        />
                                        <p>DROP YOUR FILE HERE</p>
                                    </div>
                                }
                            >
                                <ImageField
                                    source={imageFieldSource}
                                    title="title"
                                />
                            </ImageInput>
                        </Box>
                    )}
                </SimpleShowLayout>
            </ConditionalWrapper>
        </Box>
    );
};
const useStyle = makeStyles((theme) => ({
    emptyImgBox: {
        height: '160px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'dashed',
        borderColor: 'rgba(0, 0, 0, 0.09)',
    },
    empty: {
        width: '80%',
        paddingTop: 8,
        paddingBottom: 4,
    },
    buttonRemove: {
        marginBottom: '8px',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiCardContent-root': {
            width: '50%',
            height: '300px',
            padding: '0',
        },
        '& .MuiCardContent-root>div>div>img': {
            width: '80%',
            objectFit: 'cover',
            marginLeft: 0,
        },
        '& .MuiCardContent-root>div>div': {
            marginLeft: 0,
        },
        '& .MuiBox-root': {
            width: '50%',
            height: '300px',
        },
        '& .MuiFormControl-root': {
            display: 'flex',
            margin: '0 auto',
        },
        '& .MuiFormControl-root>div': {
            width: '80%',
            justifyContent: 'end',
            position: 'relative',
        },
        '& .MuiFormControl-root>div>div': {
            height: '160px',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderStyle: 'dashed',
            borderColor: 'rgba(0, 0, 0, 0.09)',
        },
        '& .MuiFormControl-root>div>.previews': {
            float: 'left',
            position: 'absolute',
            top: '12px',
            left: '3px',
            width: 'auto',
            borderStyle: 'none',
        },
        '& .MuiFormControl-root>div>.previews>div>button': {
            opacity: 1,
        },
        '& .MuiFormControl-root>div>.previews>div>div>img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            margin: 0,
        },
    },
}));
export default CustomUpload;
