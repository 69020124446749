import AssistantIcon from '@material-ui/icons/Assistant';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    BooleanInput,
    Create,
    DateInput,
    FormDataConsumer,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import PAGE_CONTENTS from '../../data/newFeaturesBannerPageOptions.json';
import { getApplianceTypes, getBrands } from '../../selectors/cmsConfig';
const NewFeaturesBannerCreate = (props) => {
    const translation = useTranslate();
    const [allApplianceFlag, setAllApplianceFlag] = useState(false);
    const [allBrandFlag, setAllBrandFlag] = useState(false);
    const brands = useSelector(getBrands);
    const applianceTypes = useSelector(getApplianceTypes);
    const breadcrumbsData = [
        {
            text: translation('resources.new-features-banner.name'),
            url: 'new-features-banner',
            icon: <AssistantIcon />,
        },
        {
            text: translation('resources.new-features-banner.create.name'),
            url: '',
        },
    ];
    const handleAllAppliance = (e) => setAllApplianceFlag(e.target.checked);
    const handleAllBrand = (e) => setAllBrandFlag(e.target.checked);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <TextInput
                            label="Title"
                            source="title"
                            validate={required()}
                        />
                        <CustomSelectInput
                            label="Page content"
                            source="pageContent"
                            validate={required()}
                            choices={PAGE_CONTENTS}
                        />
                        <DateInput
                            source="validFrom"
                            label="Valid from"
                            validate={required()}
                        />
                        <DateInput
                            source="validTo"
                            label="Valid to"
                            validate={required()}
                        />
                        <TextInput
                            label="Image 24x24"
                            source="image"
                            validate={required()}
                        />
                        <TextInput label="Deeplink" source="deeplink" />
                        {/* <TextInput
                            label="Description"
                            source="description"
                            validate={required()}
                        />
                        <TextInput label="Color es:#000" source="color" /> */}
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <FormDataConsumer>
                            {() => (
                                <>
                                    {!allApplianceFlag && (
                                        <CustomSelectArrayInput
                                            label="Appliance types"
                                            source="applianceType"
                                            choices={applianceTypes}
                                            validate={required()}
                                        />
                                    )}
                                    <BooleanInput
                                        label="All appliance"
                                        initialValue={false}
                                        source="isAllAppliance"
                                        onClick={handleAllAppliance}
                                    />
                                </>
                            )}
                        </FormDataConsumer>
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <FormDataConsumer>
                            {() => (
                                <>
                                    {!allBrandFlag && (
                                        <CustomSelectArrayInput
                                            label="Brand"
                                            source="brand"
                                            choices={brands.filter(
                                                (br) => br.id !== 'all'
                                            )}
                                            validate={required()}
                                        />
                                    )}
                                    <BooleanInput
                                        label="All brand"
                                        initialValue={false}
                                        source="isAllBrand"
                                        onClick={handleAllBrand}
                                    />
                                </>
                            )}
                        </FormDataConsumer>
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default NewFeaturesBannerCreate;
