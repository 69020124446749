import generalReducer from './general.reducers';
import paginationReducer from './pagination.reducers';
import cmsConfigReducer from './config.reducers';
const initialState = {};
const rootReducer = (state = initialState, action) => {
    let newState = {
        ...state,
    };
    const reducers = [generalReducer, paginationReducer, cmsConfigReducer];

    for (const r of reducers) {
        newState = r(newState, action);
    }
    return newState;
};
export default rootReducer;
