import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import RowingIcon from '@material-ui/icons/Rowing';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
    FieldsGenerator,
} from 'candy-commons-components';
import {
    BooleanInput,
    Edit,
    FormDataConsumer,
    ImageField,
    SimpleForm,
    SimpleShowLayout,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import connectivityOptions from '../../data/connectivityOptions.json';
import connectivityOptionsExtra from '../../data/connectivityOptionsExtra.json';
import countries from '../../data/countries.json';
import { useSelector } from 'react-redux';
import {
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
const CustomImageField = ({ formData, isEditDisabled, ...rest }) => {
    const form = useForm();
    const translation = useTranslate();

    return (
        <>
            <ImageField source="imagePath" label="" {...rest} />
            {!isEditDisabled && (
                <Button
                    color="primary"
                    onClick={() => form.change('imagePath', null)}
                    {...rest}
                >
                    {translation('resources.hints-category.button.delete')}
                </Button>
            )}
        </>
    );
};
export const HintsCategoryEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const translation = useTranslate();
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    const isEditDisabled = !permissions?.['hints-category']?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: translation('resources.hints-category.name'),
            url: 'hints-category',
            icon: <RowingIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator
            fields={[
                {
                    label: 'applianceType',
                    type: 'textField',
                    source: 'applianceType',
                },
                {
                    label: 'code',
                    type: 'textField',
                    source: 'code',
                },
            ]}
            record={record}
            {...props}
        />
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                    redirect="list"
                >
                    <ShowList />
                    <hr className={classes.width} />
                    <ContainerFieldsList>
                        <CustomSelectArrayInput
                            label="Countries"
                            source="countries"
                            choices={countries}
                            parse={(x) => (x.includes('ALL') ? ['ALL'] : x)}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="title"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="description"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            label="Brand"
                            source="brand"
                            choices={brands}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            label="Series"
                            source="series"
                            choices={series}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            label="Series Version"
                            source="seriesVersion"
                            choices={seriesVersion}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            source="connectivity"
                            allowEmpty
                            choices={[
                                ...connectivityOptions,
                                ...connectivityOptionsExtra,
                            ]}
                            disabled={isEditDisabled}
                        />
                        <BooleanInput
                            label="active"
                            source="active"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <SimpleShowLayout>
                        <FormDataConsumer>
                            {(formDataProps) =>
                                formDataProps.formData.imagePath && (
                                    <>
                                        <CustomImageField
                                            {...formDataProps}
                                            isEditDisabled={isEditDisabled}
                                        />
                                    </>
                                )
                            }
                        </FormDataConsumer>
                    </SimpleShowLayout>
                    {!isEditDisabled && (
                        <ImageInput
                            source="pictures"
                            accept="image/*"
                            placeholder={
                                <p>
                                    {translation(
                                        'resources.hints-category.fields.input_file'
                                    )}
                                </p>
                            }
                        >
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    )}
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default HintsCategoryEdit;
