import SettingsIcon from '@material-ui/icons/Settings';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React, { useState } from 'react';
import { TextField, NumberField, BooleanField } from 'react-admin';
import CustomDatagrid from '../../../components/organisms/customDatagrid';
const breadcrumbsData = [
    {
        text: 'Spaces',
        url: '',
        icon: <SettingsIcon />,
    },
];
const PromoSpacesList = ({ permissions, ...props }) => (
    <>
        <CustomBreadcrumbs data={breadcrumbsData} />
        <CustomList
            perPage={25}
            exporter={false}
            {...props}
            bulkActionButtons={
                permissions &&
                permissions['promo-spaces'] &&
                permissions['promo-spaces'].delete
            }
        >
            <CustomDatagrid selectable rowClick="edit">
                <TextField source="id" />
                <TextField source="slug" />
                <TextField source="content.externalCmsContentId" />
                <TextField source="content.deepLink" />
                <BooleanField source="active" />
            </CustomDatagrid>
        </CustomList>
    </>
);
export default PromoSpacesList;
