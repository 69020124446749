import Toolbar from '@material-ui/core/Toolbar';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import { CreateButton, TextField, useTranslate } from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import config from '../../config';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
const SupportGuideHelpFilter = (props) => {
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    return (
        <CustomFilter {...props}>
            <CustomSelectInput
                source="applianceType"
                label="Appliance Types"
                choices={[
                    { id: 'GENERIC', name: 'GENERIC' },
                    ...applianceTypes,
                ]}
                alwaysOn
                initialValue=""
                allowEmpty={false}
            />
            <CustomSelectInput
                label="Brand"
                source="brand"
                allowEmpty
                alwaysOn
                choices={brands}
            />
            <CustomSelectInput
                label="Series"
                source="series"
                choices={series}
                alwaysOn
            />
            <CustomSelectInput
                label="SeriesVersion"
                source="series"
                choices={seriesVersion}
                alwaysOn
            />
        </CustomFilter>
    );
};
const AssetListAction = ({
    basePath,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    permissions,
}) => {
    const username = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
    );
    const token = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`
    );
    return (
        <Toolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {permissions && permissions.asset && permissions.asset.create && (
                <CreateButton basePath={basePath} />
            )}
        </Toolbar>
    );
};
const SupportGuideHelpList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.support-guide-help.name'),
            url: '',
            icon: <SettingsIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={25}
                exporter={false}
                filters={<SupportGuideHelpFilter />}
                actions={<AssetListAction permissions={permissions} />}
                bulkActionButtons={
                    permissions &&
                    permissions['support-guide-help'] &&
                    permissions['support-guide-help'].delete
                        ? undefined
                        : false
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="applianceType" />
                    <TextField source="brand" />
                    <TextField source="series" />
                    <TextField source="seriesVersion" />
                    <TextField source="supportGuideHelpType" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default SupportGuideHelpList;
