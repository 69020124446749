export const showListData = [
    {
        label: 'resources.maintenance-generic.fields.countryCode',
        type: 'textField',
        source: 'countryCode',
    },
    {
        label: 'resources.maintenance-generic.fields.secondaryCountryCode',
        type: 'textField',
        source: 'secondaryCountryCode',
    },
    {
        label: 'resources.maintenance-generic.fields.brand',
        type: 'textField',
        source: 'brand',
    },
];
