import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from 'candy-commons-components';
import Notification from 'ra-ui-materialui/lib/layout/Notification';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/Lock';
import { useNotify } from 'react-admin';
import { useLocation, useHistory } from 'react-router-dom';
import { changePassword, login, logout } from '../../auth/authProvider';
import ChangePasswordForm from './changePassword';
import DefaultLoginForm from './loginForm';
import isNewPasswordRequired from './isNewPasswordRequired';
import sanitizeRestProps from './sanitizeRestProps';
const Login = ({ className, ...rest }) => {
    const classes = useStyles();
    const notify = useNotify();
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('login');
    const [cognitoUser, setCognitoUser] = useState();
    const nextPathName = location.state && location.state.nextPathname;
    const canUserAccess = (user) => {
        const ret = true;
        return ret;
    };
    const handleLogin = async (auth) => {
        try {
            setLoading(true);
            const user = await login(auth);
            setLoading(false);
            if (isNewPasswordRequired(user)) {
                setStep('change_password');
                setCognitoUser(user);
            } else {
                history.push(nextPathName || '/');
            }
        } catch (ex) {
            setLoading(false);
            notify(ex.message, 'warning');
        }
    };
    const handleChangePassword = async (auth) => {
        try {
            setLoading(true);
            await changePassword({
                ...auth,
                cognitoUser,
            });
            setLoading(false);
            history.push(nextPathName || '/');
        } catch (ex) {
            setLoading(false);
            notify(ex.message, 'warning');
        }
    };
    return (
        <div
            className={classnames(className, classes.main)}
            {...sanitizeRestProps(rest)}
        >
            <Card className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                        <LockIcon />
                    </Avatar>
                </div>
                {step === 'login' && (
                    <DefaultLoginForm isLoading={loading} login={handleLogin} />
                )}
                {step === 'change_password' && (
                    <ChangePasswordForm
                        isLoading={loading}
                        changePassword={handleChangePassword}
                    />
                )}
            </Card>
            <Notification />
        </div>
    );
};
Login.propTypes = {
    className: PropTypes.string,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    previousRoute: PropTypes.string,
    loginForm: PropTypes.element,
};
const useStyles = makeStyles((defaultTheme) => {
    return {
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            background:
                'linear-gradient(to top, hsla(210, 20%, 95%, 1), hsla(210, 20%, 90%, 1))',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        card: {
            minWidth: 300,
            marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            backgroundColor: CustomTheme.palette.primary[500],
        },
    };
});
export default Login;
