'use strict';
Object.defineProperty(exports, '__esModule', {
    value: true,
});
var _createClass = (function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor) descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps) defineProperties(Constructor.prototype, protoProps);
        if (staticProps) defineProperties(Constructor, staticProps);
        return Constructor;
    };
})();
var _react = require('react');
var _react2 = _interopRequireDefault(_react);
var _propTypes = require('prop-types');
var _propTypes2 = _interopRequireDefault(_propTypes);
var _DialogTitle = require('@material-ui/core/DialogTitle');
var _reactAdmin = require('react-admin');
var _DialogTitle2 = _interopRequireDefault(_DialogTitle);
var _DialogContent = require('@material-ui/core/DialogContent');
var _DialogContent2 = _interopRequireDefault(_DialogContent);
var _DialogActions = require('@material-ui/core/DialogActions');
var _DialogActions2 = _interopRequireDefault(_DialogActions);
var _Dialog = require('@material-ui/core/Dialog');
var _Dialog2 = _interopRequireDefault(_Dialog);
var _FormControlLabel = require('@material-ui/core/FormControlLabel');
var _FormControlLabel2 = _interopRequireDefault(_FormControlLabel);
var _FormGroup = require('@material-ui/core/FormGroup');
var _FormGroup2 = _interopRequireDefault(_FormGroup);
var _Checkbox = require('@material-ui/core/Checkbox');
var _Checkbox2 = _interopRequireDefault(_Checkbox);
var _Close = require('@material-ui/icons/Close');
var _Close2 = _interopRequireDefault(_Close);
var _Button = require('@material-ui/core/Button');
var _Button2 = _interopRequireDefault(_Button);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true,
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _possibleConstructorReturn(self, call) {
    if (!self) {
        throw new ReferenceError(
            "this hasn't been initialised - super() hasn't been called"
        );
    }
    return call && (typeof call === 'object' || typeof call === 'function')
        ? call
        : self;
}
function _inherits(subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
        throw new TypeError(
            'Super expression must either be null or a function, not ' +
                typeof superClass
        );
    }
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true,
        },
    });
    if (superClass)
        Object.setPrototypeOf
            ? Object.setPrototypeOf(subClass, superClass)
            : (subClass.__proto__ = superClass);
}
var SelectionDialog = (function (_Component) {
    _inherits(SelectionDialog, _Component);
    function SelectionDialog() {
        var _ref;
        var _temp, _this, _ret;
        _classCallCheck(this, SelectionDialog);
        for (
            var _len = arguments.length, args = Array(_len), _key = 0;
            _key < _len;
            _key++
        ) {
            args[_key] = arguments[_key];
        }
        return (
            (_ret =
                ((_temp =
                    ((_this = _possibleConstructorReturn(
                        this,
                        (_ref =
                            SelectionDialog.__proto__ ||
                            Object.getPrototypeOf(SelectionDialog)).call.apply(
                            _ref,
                            [this].concat(args)
                        )
                    )),
                    _this)),
                (_this.onColumnClicked = function (_ref2) {
                    var columnName = _ref2.target.value;
                    _this.props.onColumnClicked(columnName);
                }),
                _temp)),
            _possibleConstructorReturn(_this, _ret)
        );
    }
    _createClass(SelectionDialog, [
        {
            key: 'render',
            value: function render() {
                var _this2 = this;
                var _props = this.props,
                    columns = _props.columns,
                    selectionCheckbox = _props.selectionCheckbox,
                    onClose = _props.onClose,
                    resource = _props.resource,
                    translate = _props.translate,
                    disabled = _props.disabled;
                return _react2.default.createElement(
                    _Dialog2.default,
                    {
                        style: {
                            width: '100%',
                        },
                        maxWidth: 'xs',
                        'aria-labelledby': 'ra-columns-dialog-title',
                        onClose: onClose,
                        open: true,
                    },
                    _react2.default.createElement(
                        _DialogTitle2.default,
                        { id: 'ra-columns-dialog-title' },
                        translate('ra.table.columns_filter.modal_title')
                    ),
                    _react2.default.createElement(
                        _DialogContent2.default,
                        null,
                        _react2.default.createElement(
                            _FormGroup2.default,
                            null,
                            columns.map(function (_ref3, index) {
                                var source = _ref3.source,
                                    label = _ref3.label;
                                return _react2.default.createElement(
                                    _FormControlLabel2.default,
                                    {
                                        key: source,
                                        control: _react2.default.createElement(
                                            _Checkbox2.default,
                                            {
                                                checked:
                                                    !!selectionCheckbox[source],
                                                onChange:
                                                    _this2.onColumnClicked,
                                                value: source,
                                                color: 'primary',
                                                disabled: !!disabled[index],
                                            }
                                        ),
                                        label: _react2.default.createElement(
                                            _reactAdmin.FieldTitle,
                                            {
                                                label: label
                                                    ? label
                                                    : `resources.${resource}.fields.${source}`,
                                                source: source,
                                                resource: resource,
                                            }
                                        ),
                                    }
                                );
                            })
                        )
                    ),
                    _react2.default.createElement(
                        _DialogActions2.default,
                        {
                            style: {
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            },
                        },
                        _react2.default.createElement(
                            _Button2.default,
                            { onClick: this.props.onClose, color: 'primary' },
                            _react2.default.createElement(_Close2.default, null)
                        )
                    )
                );
            },
        },
    ]);
    return SelectionDialog;
})(_react.Component);
SelectionDialog.propTypes = {
    columns: _propTypes2.default.arrayOf(
        _propTypes2.default.shape({
            label: _propTypes2.default.string,
            source: _propTypes2.default.string.isRequired,
        })
    ).isRequired,
    selection: _propTypes2.default.object,
};
SelectionDialog.defaultProps = _defineProperty(
    {
        columns: [],
    },
    'columns',
    {}
);
exports.default = SelectionDialog;
