import React from 'react';
import { TextField, useTranslate } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const SmartDoorCounterListRule = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.smart-door-counter.name'),
            url: '',
            icon: <SettingsIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                exporter={false}
                {...props}
                bulkActionButtons={
                    permissions &&
                    permissions['smart-door-counter'] &&
                    permissions['smart-door-counter'].delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField label="Score" source="score" />
                    <TextField label="Type" source="category" />
                    <TextField label="Minimum" source="min" />
                    <TextField label="Maximum" source="max" />
                    <TextField source="description" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default SmartDoorCounterListRule;
