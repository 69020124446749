import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles({
    containerFormInput: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        gridGap: '20px',
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
});
