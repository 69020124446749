import React from 'react';
import {
    Edit,
    NumberInput,
    required,
    SimpleForm,
    useTranslate,
    TextInput,
    BooleanInput,
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import exportFromJSON from 'export-from-json';
import { Button } from '@material-ui/core';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { CustomBreadcrumbs } from 'candy-commons-components';
import { QueryFormManager } from '../components/QueryFormManager';
import { useStyles } from '../styles';
import { PROMO, FORM_TYPE } from '../config';
import {
    normalizeFormDataToSend,
    useGetSelectedCriteria,
} from '../hooks/normalizeFormDataToSend';
import { PromoRewardInput } from './components/PromoRewardInput';
import fetcher from '../../../fetcher';
import moment from 'moment';
const PromoCampaignsEdit = ({ permissions = {}, ...props }) => {
    const { id } = props;
    const translation = useTranslate();
    const isEditDisabled = !permissions?.['promo-campaigns']?.update;
    const classes = useStyles();
    const [crt, getSelectedCriteriaHandler] = useGetSelectedCriteria();
    const breadcrumbsData = [
        {
            text: translation('resources.promo-campaigns.name'),
            url: 'promo-campaigns',
            icon: <TimelapseIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const downloadTracking = async () => {
        const params = new URLSearchParams();
        params.append('filter', `{"promoCampaignId":${id}}`);
        params.append('range', `[0,0]`);
        // Since it is a complete xlx export, disable the pagination sending as offset null
        const response = await fetcher({
            method: 'GET',
            url: `/promo-user-redemptions/`,
            params,
        });
        if (response.data.length) {
            // Convert `createdAt` column data with moment in a format more understandable for humans
            response.data.map(({ createdAt }, index) => {
                response.data[index].createdAt = moment(createdAt).format(
                    'YYYY/MM/DD [at] HH:mm:ss'
                );
            });
        } else {
            // If response is empty (no redemptions for the provided campaign), push an empty record set
            // in order to add to excel file at least the headers, so the file doesn't appear corrupt
            response.data.push({
                userSfId: ' ',
                promoSpaceSlug: ' ',
                promoCampaignSlug: ' ',
                rewardType: ' ',
                createdAt: ' ',
            });
        }
        const fileName = `promo-tracking-campaign-${id}`;
        const exportType = 'xls';
        const fields = {
            userSfId: 'User ID',
            promoSpaceSlug: 'Promo Space',
            promoCampaignSlug: 'Campaign',
            rewardType: 'Reward type',
            createdAt: 'Redemption Timestamp',
        };
        return exportFromJSON({
            data: response.data,
            fileName,
            fields,
            exportType,
        });
    };
    const CustomToolbar = (props) => {
        return (
            <Toolbar {...props}>
                <SaveButton
                    redirect="list"
                    transform={(data) => {
                        return normalizeFormDataToSend({ data, crt });
                    }}
                    disabled={isEditDisabled}
                />
                <DeleteButton disabled={isEditDisabled} undoable />
            </Toolbar>
        );
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Button onClick={downloadTracking}>download tracking</Button>
            <Edit {...props} undoable={false}>
                <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
                    <BooleanInput
                        initialValue={true}
                        source="active"
                        label="Active"
                        validate={[required()]}
                        className={classes.fullWidth}
                        disabled={isEditDisabled}
                    />
                    <TextInput
                        source="slug"
                        label="Slug"
                        validate={required()}
                        className={classes.fullWidth}
                        disabled={isEditDisabled}
                    />
                    <TextInput
                        source="content.externalCmsStructureId"
                        label="External CMS Structure Id"
                        className={classes.fullWidth}
                        disabled={isEditDisabled}
                    />
                    <TextInput
                        source="content.externalCmsContentId"
                        label="External CMS Content Id"
                        className={classes.fullWidth}
                        disabled={isEditDisabled}
                    />
                    <NumberInput
                        min="1"
                        source="content.redemptions"
                        label="Redemptions"
                        className={classes.fullWidth}
                        validate={[required()]}
                        disabled={isEditDisabled}
                    />
                    <NumberInput
                        min="1"
                        source="content.redemptionsPerUser"
                        label="Redemptions per user"
                        className={classes.fullWidth}
                        disabled={isEditDisabled}
                    />
                    <PromoRewardInput
                        promoCampaignId={id}
                        isEditDisabled={isEditDisabled}
                    />
                    <QueryFormManager
                        variant={FORM_TYPE.EDIT}
                        promoType={PROMO.CAMPAIGNS}
                        queryID={id}
                        getActiveCriteria={getSelectedCriteriaHandler}
                        isEditDisabled={isEditDisabled}
                    />
                </SimpleForm>
            </Edit>
        </>
    );
};
export default PromoCampaignsEdit;
