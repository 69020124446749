import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    BooleanInput,
    Edit,
    FormDataConsumer,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import countries from '../../data/countries.json';
import { useSelector } from 'react-redux';

import { getApplianceTypes } from '../../selectors/cmsConfig';
const GetFeedBackTargetEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const [allApplianceFlag, setAllApplianceFlag] = useState(false);
    const { id } = props;
    const translation = useTranslate();
    const applianceTypes = useSelector(getApplianceTypes);

    const isEditDisabled = !permissions?.['getfeedback-target']?.update;
    const breadcrumbsData = [
        {
            text: translation('resources.getfeedback-target.name'),
            url: 'getfeedback-target',
            icon: <PageviewIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const handleAllAppliance = (e) => setAllApplianceFlag(e.target.checked);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ContainerFieldsList>
                        <TextInput
                            label="Event name"
                            source="eventName"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <BooleanInput
                            initialValue={false}
                            source="isActive"
                            label="Active"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <FormDataConsumer>
                            {({ formData }) => (
                                <>
                                    {!formData.AllAppliance &&
                                        !allApplianceFlag && (
                                            <CustomSelectArrayInput
                                                label="Appliance types"
                                                source="applianceType"
                                                choices={applianceTypes}
                                                disabled={
                                                    allApplianceFlag ||
                                                    isEditDisabled
                                                }
                                            />
                                        )}
                                    <BooleanInput
                                        label="All appliance"
                                        source="isAllAppliance"
                                        disabled={
                                            formData.NoAppliance ||
                                            isEditDisabled
                                        }
                                        onClick={handleAllAppliance}
                                    />
                                </>
                            )}
                        </FormDataConsumer>
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <BooleanInput
                            label="No enrolled appliance"
                            source="isNoAppliance"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <CustomSelectArrayInput
                            label="Countries"
                            source="countries"
                            choices={countries}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default GetFeedBackTargetEdit;
