import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../config';
const ProfileEdit = (props) => {
    const classes = useStyles();
    const username = useMemo(
        () =>
            localStorage.getItem(
                `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
            ),
        []
    );
    const userData = useMemo(
        () =>
            JSON.parse(
                localStorage.getItem(
                    `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.userData`
                )
            ),
        [username]
    );
    return (
        <>
            <div className={classes.mainContainer}>
                <h1 className={classes.title}>{username}</h1>
                <div className={classes.pageContainer}>
                    There are 0 system notifications.
                    <br />
                </div>
            </div>
        </>
    );
};
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
    mainContainer: {
        marginTop: 10,
    },
    pageContainer: {
        marginTop: 16,
    },
    title: {
        margin: '0 !important',
    },
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default ProfileEdit;
