import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import loadingTypes from '../../data/loadingType.json';
import { useSelector } from 'react-redux';
import { getBrands, getSeries } from '../../selectors/cmsConfig';
const SupportEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const { id } = props;
    const translation = useTranslate();
    const brands = useSelector(getBrands).filter((x) => x.id !== 'all');
    const series = useSelector(getSeries);
    const isEditDisabled = !permissions?.support?.update;
    const breadcrumbsData = [
        {
            text: translation('resources.support.name'),
            url: 'support',
            icon: <ContactSupportIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ContainerFieldsList>
                        <TextInput
                            source="applianceType"
                            validate={required()}
                            disabled
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={brands}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={series}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="loadingType"
                            choices={loadingTypes}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="title"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="type"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="screen"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="iconPath"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="description"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="tutorialName"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default SupportEdit;
