import { FETCH_CMS_CONFIG_SUCCESS } from '../actions/cmsConfig.actions';

const initialState = {
    config: [],
};

const cmsConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CMS_CONFIG_SUCCESS:
            return {
                ...state,
                config: action.payload,
            };
        default:
            return state;
    }
};

export default cmsConfigReducer;
