import React, { useCallback, useState } from 'react';
import {
    ImageInput as DefaultImageInput,
    useNotify,
    FileField,
} from 'react-admin';
import {
    validImageFormats,
    MAX_IMAGE_KB,
    checkImageFile,
} from '../utils/fileUtils';
const LABEL = `Image (max: ${MAX_IMAGE_KB}kb, ${validImageFormats.join(',')})`;

const ImageInput = (props) => {
    const notify = useNotify();
    const [validFiles, setValidFiles] = useState([]);

    const onChangeHandler = async (event) => {
        const file = event?.rawFile || event;
        if (!file) return;

        const error = await checkImageFile(file);
        if (error) {
            notify(error);
            setValidFiles([]);
            return;
        }

        setValidFiles([file]);
        if (props.onChange) {
            props.onChange(event);
        }
    };

    const renderChildren = useCallback(() => {
        if (validFiles.length) {
            return props.children;
        }
        return <FileField source="src" title="" />; // Placeholder
    }, [validFiles, props.children]);

    return (
        <DefaultImageInput
            {...props}
            label={LABEL}
            accept={validImageFormats.join(',')}
            onChange={onChangeHandler}
        >
            {renderChildren()}
        </DefaultImageInput>
    );
};

export default ImageInput;
