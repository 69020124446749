import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RowingIcon from '@material-ui/icons/Rowing';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
    CustomSelectArrayInput,
} from 'candy-commons-components';
import React, { useMemo, useState } from 'react';
import {
    ArrayInput,
    BooleanInput,
    Create,
    ImageField,
    NumberInput,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    TextInput,
    required,
    useGetList,
    useTranslate,
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import connectivityOptions from '../../data/connectivityOptions.json';
import connectivityOptionsExtra from '../../data/connectivityOptionsExtra.json';
import countries from '../../data/countries.json';
import { useSelector } from 'react-redux';
import {
    getBrands,
    getSeries,
    getSeriesVersion,
    getDrawerTypes,
} from '../../selectors/cmsConfig';

const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const HintCreate = (props) => {
    const classes = useStyles();
    const translation = useTranslate();
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    const drawerTypes = useSelector(getDrawerTypes);

    const breadcrumbsData = [
        {
            text: translation('resources.hints.name'),
            url: 'hints',
            icon: <RowingIcon />,
        },
        {
            text: translation('resources.hints.create.name'),
            url: '',
        },
    ];
    const { ids = [], data } = useGetList('hints-category', {}, {});
    const categories = useMemo(
        () =>
            ids.map((x) => ({
                id: data[x].code,
                name: data[x].code,
            })),
        [ids, data]
    );
    const [sourceFile, setSourceFile] = useState(null);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm redirect="list">
                    <ContainerFieldsList>
                        <CustomSelectInput
                            allowEmpty
                            source="category"
                            choices={categories}
                            validate={required()}
                        />
                        <CustomSelectArrayInput
                            label="Countries"
                            source="countries"
                            choices={countries}
                            parse={(x) => (x.includes('ALL') ? ['ALL'] : x)}
                        />
                        <TextInput source="title" validate={required()} />
                        <TextInput source="body" />
                        <CustomSelectArrayInput
                            label="Brand"
                            source="brand"
                            choices={brands}
                        />
                        <CustomSelectArrayInput
                            choices={series}
                            label="Series"
                            source="series"
                        />
                        <CustomSelectArrayInput
                            label="Series Version"
                            source="seriesVersion"
                            choices={seriesVersion}
                        />
                        <TextInput source="applianceModel" />
                        <CustomSelectArrayInput
                            source="connectivity"
                            allowEmpty
                            choices={[
                                ...connectivityOptions,
                                ...connectivityOptionsExtra,
                            ]}
                        />
                        <NumberInput label="order" source="sortOrder" />
                        <CustomSelectInput
                            source="drawerType"
                            label="Drawer Type"
                            choices={[
                                {
                                    id: '',
                                    name: '-',
                                },
                                ...drawerTypes.filter(
                                    (x) => x.id !== 'FL-CHG-AQUA'
                                ),
                            ]}
                        />
                    </ContainerFieldsList>
                    <SimpleShowLayout>
                        <ImageField source="imagePath" addLabel={false} />
                    </SimpleShowLayout>
                    <Typography variant="h6" component="h6">
                        Thumbnail image
                    </Typography>
                    <ImageInput
                        source="thumbnailImage"
                        accept="image/*"
                        placeholder={
                            <p>
                                {translation(
                                    'resources.hints.fields.input_file'
                                )}
                            </p>
                        }
                    >
                        <ImageField source="thumbnailImage" title="title" />
                    </ImageInput>
                    <BooleanInput
                        label="Multiple cards"
                        source="multipleCards"
                    />
                    <hr className={classes.width} />
                    <Typography variant="h6" component="h6">
                        Image above text
                    </Typography>
                    <CustomSelectInput
                        label="Image above Text"
                        source="sourceFile"
                        choices={[
                            { name: 'Asset already exist', id: 'exist' },
                            { name: 'Upload new asset', id: 'new' },
                        ]}
                        onChange={(e) => setSourceFile(e.target.value)}
                    />
                    {sourceFile && sourceFile === 'exist' && (
                        <>
                            <TextInput
                                label="Asset id"
                                source="assetId"
                                fullWidth
                            />
                        </>
                    )}
                    {sourceFile && sourceFile === 'new' && (
                        <>
                            <ImageInput
                                source="pictureAbove"
                                accept="image/*"
                                placeholder={
                                    <p>
                                        {translation(
                                            'resources.hints.fields.input_file'
                                        )}
                                    </p>
                                }
                            >
                                <ImageField
                                    source="pictureAbove"
                                    title="title"
                                />
                            </ImageInput>
                        </>
                    )}
                    <hr className={classes.width} />
                    <ArrayInput source="steps">
                        <SimpleFormIterator>
                            <TextInput source="title" validate={required()} />
                            <TextInput source="description" />
                            <TextInput source="warningText" />
                            <TextInput source="linkVideo" />
                            <ImageInput
                                source="imagePath"
                                accept="image/*"
                                placeholder={
                                    <p>
                                        {translation(
                                            'resources.hints.fields.input_file'
                                        )}
                                    </p>
                                }
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default HintCreate;
