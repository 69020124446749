import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    ArrayInput,
    BooleanInput,
    Create,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import countries from '../../data/countries.json';
import loadingTypes from '../../data/loadingType.json';
import connectivity from './applianceConnectivityChoices';
import { sectionChoices } from './sectionChoices';
import useFetchSelectValues from '../../hooks/useSelectValues';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
const breadcrumbsData = [
    {
        text: 'Appliance Configuration',
        url: 'appliance-configuration',
        icon: <ViewComfyIcon />,
    },
    {
        text: 'Create',
        url: '',
    },
];
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const ApplianceConfigurationCreate = (props) => {
    const classes = useStyles();

    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersions = useSelector(getSeriesVersion);

    useFetchSelectValues();
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceTypes}
                        />
                        <CustomSelectInput
                            source="applianceModel"
                            label="Series Version"
                            choices={seriesVersions}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={series}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={brands}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="country"
                            choices={countries}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="connectivity"
                            choices={connectivity}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="loadingType"
                            choices={loadingTypes}
                        />
                    </ContainerFieldsList>
                    <hr className={classes.width} />
                    <ArrayInput source="sections">
                        <SimpleFormIterator>
                            <CustomSelectInput
                                source="title"
                                choices={sectionChoices.sort((a, b) =>
                                    a.id.localeCompare(b.id)
                                )}
                            />
                            <BooleanInput label="" source="visibility" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="topicsSubscribe">
                        <SimpleFormIterator>
                            <TextInput source="topic" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="topicsPublish">
                        <SimpleFormIterator>
                            <TextInput source="topic" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default ApplianceConfigurationCreate;
