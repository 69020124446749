import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import { FunctionField, TextField, TextInput } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import applianceTypes from '../../data/programListRulesApplianceType.json';
import { useSelector } from 'react-redux';
import {
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';

const breadcrumbsData = [
    {
        text: 'Program List Rules',
        url: '',
        icon: <SettingsIcon />,
    },
];
const Filters = (props) => {
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    return (
        <CustomFilter {...props}>
            <CustomSelectInput
                source="applianceType"
                choices={applianceTypes}
                alwaysOn
                initialValue=""
                allowEmpty
            />
            <CustomSelectInput
                source="brand"
                allowEmpty
                alwaysOn
                choices={brands.filter((x) => x.id !== 'all')}
            />
            <CustomSelectInput source="series" choices={series} alwaysOn />
            <CustomSelectInput
                source="seriesVersion"
                choices={seriesVersion}
                alwaysOn
            />
        </CustomFilter>
    );
};
const ProgramListRulesList = ({ permissions, ...props }) => (
    <>
        <CustomBreadcrumbs data={breadcrumbsData} />
        <CustomList
            filters={<Filters />}
            perPage={25}
            exporter={false}
            pagination={<DynamoPagination resource="asset" />}
            {...props}
            bulkActionButtons={
                permissions &&
                permissions['program-list-rules'] &&
                permissions['program-list-rules'].delete
            }
        >
            <CustomDatagrid selectable rowClick="edit">
                <TextField source="applianceType" />
                <TextField source="brand" />
                <TextField source="series" />
                <TextField source="seriesVersion" />
                <FunctionField
                    label="Countries"
                    render={(item) => (item.countries || []).join(',')}
                    source="countries"
                />
            </CustomDatagrid>
        </CustomList>
    </>
);
export default ProgramListRulesList;
