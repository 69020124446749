import SettingsIcon from '@material-ui/icons/Settings';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    ArrayInput,
    Edit,
    ImageField,
    ImageInput,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import supportGuideHelpType from '../../data/supportGuideType.json';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
const SupportGuideHelpEdit = ({ permissions = {}, ...props }) => {
    const transaltion = useTranslate();
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    const isEditDisabled = !permissions?.['support-guide-help']?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: transaltion('resources.support-guide-help.name'),
            url: 'support-guide-help',
            icon: <SettingsIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props} undoable={false}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={[
                                { id: 'GENERIC', name: 'GENERIC' },
                                ...applianceTypes,
                            ]}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={brands}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={series}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="seriesVersion"
                            disabled={isEditDisabled}
                            choices={seriesVersion}
                        />
                        <TextInput source="title" disabled={isEditDisabled} />
                        <TextInput
                            source="description"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="supportGuideHelpType"
                            choices={supportGuideHelpType}
                            validate={required()}
                            onChange={(event) => {}}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ArrayInput source="steps" disabled={isEditDisabled}>
                        <SimpleFormIterator
                            TransitionProps={{ enter: false }}
                            getItemLabel={(index) => `${index + 1}-key`}
                            disableReordering={false}
                            key={(record) => record.id}
                        >
                            <TextInput label="title" source="title" />
                            <ArrayInput source="descriptions">
                                <SimpleFormIterator>
                                    <TextInput source="description" />
                                </SimpleFormIterator>
                            </ArrayInput>
                            {!isEditDisabled && (
                                <ImageInput
                                    source="imagePath"
                                    label="Image"
                                    accept="image/*"
                                    placeholder={<p>Drop your file here</p>}
                                >
                                    <ImageField source="src" />
                                </ImageInput>
                            )}
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
export default SupportGuideHelpEdit;
