import i18n from 'i18n-js';
import en from './en.json';
import it from './it.json';
const supportedLanguages = {
    en,
    en_US: en,
    it,
    it_IT: it,
};
const browserLanguage = navigator.language;
i18n.locale = browserLanguage.replace('-', '_');
i18n.fallbacks = true;
i18n.defaultLocale = 'en';
// i18n.missingBehaviour = "guess";
i18n.missingTranslation = function () {
    return undefined;
};
for (const [key, value] of Object.entries(supportedLanguages)) {
    i18n.translations[key] = value;
}
// react admin translations methods
const i18nProvider = {
    translate: (key, options) => {
        const string = i18n.t(key, options);
        const result = /[^.]*$/.exec(key)[0];
        if (!string) return result;
        return string;
    },
    changeLocale: (newLocale) => {
        i18n.locale = newLocale;
        return Promise.resolve();
    },
    getLocale: () => i18n.currentLocale(),
};
export default i18nProvider;
