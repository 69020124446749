import RowingIcon from '@material-ui/icons/Rowing';
import {
    CustomBreadcrumbs,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useMemo } from 'react';
import {
    FunctionField,
    TextField,
    TextInput,
    useTranslate,
    useGetList,
} from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';
const HintList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.hints.name'),
            url: '',
            icon: <RowingIcon />,
        },
    ];
    const { ids = [], data } = useGetList('hints-category', {}, {});
    const categories = useMemo(
        () =>
            ids.map((x) => ({
                id: data[x].code,
                name: data[x].code,
            })),
        [ids, data]
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                exporter={false}
                perPage={25}
                {...props}
                pagination={<DynamoPagination resource="hints" />}
                filters={
                    <CommonApplianceFilter
                        brand
                        series
                        seriesVersion
                        optionalFilters={[
                            <CustomSelectInput
                                label="Category"
                                source="category"
                                choices={categories}
                                alwaysOn
                            />,
                            <TextInput label="Title" source="title" alwaysOn />,
                        ]}
                    />
                }
                bulkActionButtons={
                    permissions && permissions.hints && permissions.hints.delete
                        ? undefined
                        : false
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="brand" />
                    <TextField source="series" />
                    <TextField source="seriesVersion" />
                    <TextField source="applianceModel" />
                    <TextField source="category" />
                    <TextField label="order" source="sortOrder" />
                    <TextField source="title" />
                    <TextField source="body" />

                    <FunctionField
                        label="Countries"
                        render={(item) => (item.countries || []).join(',')}
                        source="countries"
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default HintList;
