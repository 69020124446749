import SettingsIcon from '@material-ui/icons/Settings';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';

import {
    ArrayInput,
    Edit,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import applicationType from '../../data/applicationType.json';

const IamConfigurationEdit = ({ permissions = {}, ...props }) => {
    const transaltion = useTranslate();
    const [tdChoices, setTdChoices] = useState(false);
    const isEditDisabled = !permissions?.['iam-configuration']?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: transaltion('resources.iam-configuration.name'),
            url: 'iam-configuration',
            icon: <SettingsIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];

    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props} undoable={false}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ContainerFieldsList>
                        <CustomSelectInput
                            disabled
                            label="App Type"
                            source="applicationType"
                            choices={applicationType}
                            validate={required()}
                        />
                        <TextInput
                            source="minAppVersion"
                            label="Min AppVersion"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="maxAppVersion"
                            label="max AppVersion"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ArrayInput source="variables" disabled={isEditDisabled}>
                        <SimpleFormIterator
                            inline
                            disableAdd
                            disableRemove
                            TransitionProps={{ enter: false, exit: false }}
                        >
                            <TextInput
                                disabled
                                source="varName"
                                helperText={false}
                            />
                            <TextInput source="varValue" helperText={false} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};

export default IamConfigurationEdit;
