import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import IconContentAdd from '@material-ui/icons/Add';
import IconContentEdit from '@material-ui/icons/Edit';
import React, { useState, Fragment } from 'react';
import {
    Button,
    useDataProvider,
    useGetList,
    useNotify,
    useTranslate,
} from 'react-admin';
import TranslationForm from './missingTranslationForm';
import { getRestrictedLanguages } from '../../libs/utils';
const MissingTranslationModalButton = ({
    edit,
    record,
    id,
    setShowButtonAddLanguage,
    permissions,
}) => {
    const { languages = [], language, translation } = record;
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { ids = [], data } = useGetList('language', {}, {});
    const classes = useStyles();
    const allLanguages = ids.map((x) => ({ id: x, name: data[x].langName }));
    let availableLanguages = allLanguages.filter(
        (x) => !languages.some((y) => y.language === x.id)
    );
    if (
        permissions &&
        permissions.translation &&
        permissions.translation.restrictLanguages &&
        availableLanguages.length
    ) {
        const restrictedLanguages = getRestrictedLanguages({ permissions });
        availableLanguages = availableLanguages.filter((x) =>
            restrictedLanguages.includes(x.id)
        );
    }
    const handleClick = () => {
        setShowDialog(true);
    };
    const handleCloseClick = () => {
        setShowDialog(false);
    };
    const handleSubmit = async ({
        translation: tranToUpdate,
        language: langToUpate,
    }) => {
        try {
            setIsSubmitting(true);
            await dataProvider.update('translation', {
                id,
                data: { [`lang_${langToUpate}`]: tranToUpdate },
            });
            setShowDialog(false);
            notify('resources.translation.notification.created');
        } catch (ex) {
            console.error(ex.message);
            notify('ra.page.error', 'warning');
        }
        setIsSubmitting(false);
    };
    if (
        edit &&
        permissions &&
        permissions.translation &&
        permissions.translation.restrictLanguages &&
        !permissions.translation[language]
    )
        return null;
    return (
        <Box className={classes.buttonContainer}>
            {edit || availableLanguages.length ? (
                <Fragment>
                    <Button
                        variant="contained"
                        color="primary"
                        label={
                            edit
                                ? null
                                : 'resources.translation.button.language'
                        }
                        onClick={handleClick}
                    >
                        {edit ? <IconContentEdit /> : <IconContentAdd />}
                    </Button>
                    <Dialog
                        fullWidth
                        open={showDialog}
                        onClose={handleCloseClick}
                        className={classes.biggerWidth}
                    >
                        <DialogTitle>
                            {edit
                                ? translate(
                                      'resources.translation.translation_form.fields.edit_translation'
                                  )
                                : translate(
                                      'resources.translation.translation_form.fields.create_translation'
                                  )}
                        </DialogTitle>
                        <TranslationForm
                            isLoading={isSubmitting}
                            saveAction={handleSubmit}
                            handleCloseClick={handleCloseClick}
                            allLanguages={allLanguages}
                            availableLanguages={availableLanguages}
                            setShowButtonAddLanguage={setShowButtonAddLanguage}
                            edit={edit}
                            initialValues={{
                                translation,
                                language,
                            }}
                            id={id}
                        />
                    </Dialog>
                </Fragment>
            ) : (
                ''
            )}
        </Box>
    );
};
const useStyles = makeStyles({
    biggerWidth: {
        '& .MuiDialog-paper': {
            maxWidth: '800px',
            '& .rc-md-editor .tool-bar,.rc-md-editor .rc-md-navigation .navigation-nav.left .button-type-image':
                {
                    display: 'none',
                },
        },
    },
    buttonContainer: {
        minWidth: 64,
    },
});
export default MissingTranslationModalButton;
