import React from 'react';
import PageviewIcon from '@material-ui/icons/Pageview';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
} from 'candy-commons-components';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';

const CreateResourceFactory = (resourceName) => {
    return function CreateResource({ ...props }) {
        const translation = useTranslate();
        const breadcrumbsData = [
            {
                text: translation(`resources.${resourceName}.name`),
                url: resourceName,
                icon: <PageviewIcon />,
            },
            {
                text: translation(`resources.${resourceName}.create.name`),
                url: '',
            },
        ];

        return (
            <>
                <CustomBreadcrumbs data={breadcrumbsData} />
                <Create {...props}>
                    <SimpleForm>
                        <ContainerFieldsList>
                            <TextInput source="id" validate={required()} />
                            <TextInput source="name" validate={required()} />
                        </ContainerFieldsList>
                    </SimpleForm>
                </Create>
            </>
        );
    };
};

export default CreateResourceFactory;
