import React from 'react';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
    FieldsGenerator,
} from 'candy-commons-components';
import {
    BooleanInput,
    Edit,
    NumberInput,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../../components/CustomToolbar';
import { showListData } from './guidedWashingCycleStaticData';
const GuidedWashingCycleEdit = ({ permissions = {}, ...props }) => {
    // const classes = useStyles();
    const { id } = props;
    const translation = useTranslate();
    const isEditDisabled = !permissions?.['guided-washing']?.update;
    const breadcrumbsData = [
        {
            text: translation('resources.guided-washing.name_breadcrumbs'),
            url: 'guided-washing',
            icon: <TimelapseIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator fields={showListData} record={record} {...props} />
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props} undoable={false}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ShowList />
                    <ContainerFieldsList>
                        <CustomSelectInput
                            fullWidth
                            source="category"
                            choices={[
                                { id: 'HOME_CARE', name: 'HOME_CARE' },
                                { id: 'SPECIAL_CARE', name: 'SPECIAL_CARE' },
                                { id: 'FABRICS', name: 'FABRICS' },
                            ]}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <NumberInput
                            source="prCode"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="outputCycle"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        {
                            <>
                                <NumberInput
                                    fullWidth
                                    source="temperature"
                                    disabled={isEditDisabled}
                                />
                                <CustomSelectInput
                                    fullWidth
                                    source="dryingLevel"
                                    choices={[
                                        { id: 0, name: 'NO_DRY' },
                                        { id: 1, name: 'EXTRA' },
                                        { id: 2, name: 'IRON' },
                                        { id: 3, name: 'CLOSET' },
                                        { id: 9, name: 'WOOL' },
                                    ]}
                                    disabled={isEditDisabled}
                                />
                                <CustomSelectInput
                                    fullWidth
                                    source="spins"
                                    choices={[
                                        { id: 0, name: '0' },
                                        { id: 400, name: '400' },
                                        { id: 800, name: '800' },
                                        { id: 1000, name: '1000' },
                                        { id: 'MAX', name: 'MAX' },
                                    ]}
                                    disabled={isEditDisabled}
                                />
                                <CustomSelectArrayInput
                                    fullWidth
                                    source="spinsRange"
                                    choices={[
                                        { id: '0', name: '0' },
                                        { id: '400', name: '400' },
                                        { id: '800', name: '800' },
                                        { id: '1000', name: '1000' },
                                        { id: 'MAX', name: 'MAX' },
                                    ]}
                                    disabled={isEditDisabled}
                                />
                            </>
                        }
                        <NumberInput
                            fullWidth
                            source="dryingTime"
                            disabled={isEditDisabled}
                        />
                        <BooleanInput
                            source="steam"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
export default GuidedWashingCycleEdit;
