import React from 'react';
import WorkIcon from '@material-ui/icons/Work';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import { FunctionField, TextField, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getMaintenanceInstructionsCategories,
} from '../../selectors/cmsConfig';
const MaintenanceInstructionsFilter = (props) => {
    const { filterValues } = props;
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const categories = useSelector(getMaintenanceInstructionsCategories);
    return (
        <CustomFilter {...props}>
            <CustomSelectInput
                label="resources.maintenance-instructions.fields.applianceType"
                source="applianceType"
                alwaysOn
                choices={applianceTypes}
            />
            <CustomSelectInput
                label="resources.maintenance-instructions.fields.brand"
                source="brand"
                alwaysOn
                choices={brands}
                disabled={!filterValues.applianceType}
            />
            <CustomSelectInput
                label="resources.maintenance-instructions.fields.series"
                source="series"
                alwaysOn
                choices={series}
                disabled={!filterValues.applianceType}
            />
            <CustomSelectInput
                label="resources.maintenance-instructions.fields.category"
                source="category"
                alwaysOn
                choices={categories}
                disabled={!filterValues.applianceType}
            />
        </CustomFilter>
    );
};
const MaintenanceInstructionsList = ({ permissions, ...props }) => {
    const translation = useTranslate();

    const categories = useSelector(getMaintenanceInstructionsCategories);
    const categoryById = Object.fromEntries(
        categories.map(({ id, name }) => [id, name])
    );
    const breadcrumbsData = [
        {
            text: translation('resources.maintenance-instructions.name'),
            url: '',
            icon: <WorkIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                filters={<MaintenanceInstructionsFilter />}
                exporter={false}
                perPage={25}
                {...props}
                pagination={
                    <DynamoPagination resource="maintenance-instructions" />
                }
                bulkActionButtons={
                    permissions &&
                    permissions['maintenance-instructions'] &&
                    permissions['maintenance-instructions'].delete
                }
            >
                <CustomDatagrid
                    selectable
                    rowClick="edit"
                    choiceColumns={true}
                    defaultColumns={[
                        'applianceType',
                        'brand',
                        'series',
                        'seriesVersion',
                    ]}
                >
                    <TextField source="applianceType" label="Appliance Type" />
                    <TextField source="brand" label="Brand" />
                    <TextField source="series" label="Series" />
                    <TextField source="seriesVersion" label="Series Version" />
                    <TextField source="loadingType" label="Loading Type" />
                    <TextField source="drawerType" label="Drawer Type" />
                    <TextField
                        source="installationType"
                        label="Installation Type"
                    />
                    <TextField
                        source="dryingTechnology"
                        label="Drying Technology"
                    />
                    <TextField source="tankPosition" label="Tank Position" />
                    {/* <TextField source="loadingType" /> */}
                    <FunctionField
                        label="Category"
                        render={({ category }) => categoryById[category] || ''}
                        source="categories"
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default MaintenanceInstructionsList;
