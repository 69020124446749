import SettingsIcon from '@material-ui/icons/Settings';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
} from 'candy-commons-components';
import { Button } from '@material-ui/core';
import React from 'react';
import { TextInput, TextField, Loading, DateTimeInput } from 'react-admin';
import CustomDatagrid from '../../../components/organisms/customDatagrid';
import CustomFilterPost from '../../../components/organisms/customFilterForm';
import fetcher from '../../../fetcher';
import moment from 'moment';
import { getDefaultDayAgo, getEndOfCurrentDay } from '../../../utils/dateUtils';
export const PromoTrackingList = ({ permissions, ...props }) => {
    const filterDefaultValues = {
        createdAtFrom: getDefaultDayAgo(7),
        createdAtTo: getEndOfCurrentDay(),
    };
    const breadcrumbsData = [
        {
            text: 'Tracking',
            url: '',
            icon: <SettingsIcon />,
        },
    ];
    const PromoTrackingFilter = (props) => {
        const { filterValues } = props;
        const [isLoading, setIsLoading] = React.useState(false);
        const downloadTracking = (filterFields) => async () => {
            setIsLoading(true);
            const response = await fetcher({
                method: 'GET',
                url: `/promo-user-redemptions/excel`,
                params: {
                    filter: filterFields,
                },
            });
            if (response && response.data) {
                setIsLoading(false);
                window.open(response.data.url, '_blank');
            } else {
                setIsLoading(false);
            }
        };
        const dateFormatter = (v) => {
            const d = new Date(v);
            if (isNaN(d)) return;
            return moment(d).format('YYYY-MM-DDTHH:mm:ss');
        };
        const PostFilter = (props) => {
            return props.context === 'form' ? (
                <PromoTrackingFilter {...props} />
            ) : null;
        };
        return (
            <div>
                <CustomFilterPost
                    {...props}
                    context="form"
                    displayedFilters={[
                        'userSfId',
                        'promoSpaceSlug',
                        'promoCampaignSlug',
                        'createdAtFrom',
                        'createdAtTo',
                    ]}
                >
                    <TextInput
                        label="User ID"
                        source="userSfId"
                        variant="outlined"
                        alwaysOn
                    />
                    <TextInput
                        label="Promo Space"
                        source="promoSpaceSlug"
                        variant="outlined"
                        alwaysOn
                    />
                    <TextInput
                        label="Campaign"
                        source="promoCampaignSlug"
                        variant="outlined"
                        alwaysOn
                    />
                    <DateTimeInput
                        label="From"
                        variant="outlined"
                        source="createdAtFrom"
                        format={(d) => dateFormatter(d)}
                        parse={(d) => dateFormatter(d)}
                        alwaysOn
                    />
                    <DateTimeInput
                        label="To"
                        variant="outlined"
                        source="createdAtTo"
                        format={(d) => dateFormatter(d)}
                        parse={(d) => dateFormatter(d)}
                        alwaysOn
                    />
                </CustomFilterPost>
                {isLoading ? (
                    <Loading />
                ) : (
                    <Button onClick={downloadTracking(props?.filterValues)}>
                        Export Excel
                    </Button>
                )}
            </div>
        );
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                {...props}
                actions={null}
                exporter={true}
                bulkActionButtons={
                    permissions &&
                    permissions['promo-user-redemptions'] &&
                    permissions['promo-user-redemptions'].delete
                }
                filters={<PromoTrackingFilter />}
                filterDefaultValues={{ ...filterDefaultValues }}
            >
                <CustomDatagrid selectable>
                    <TextField source="userSfId" label="User ID" />
                    <TextField source="promoCampaignSlug" label="Campaign" />
                    <TextField source="promoSpaceSlug" label="Promo Space" />
                    <TextField source="promoRewardType" label="Reward Type" />
                    <TextField
                        source="createdAt"
                        label="Redemption Timestamp"
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
