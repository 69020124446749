import { call, put, takeEvery } from 'redux-saga/effects';
import {
    FETCH_CMS_CONFIG_REQUEST,
    fetchCmsConfigSuccess,
    fetchCmsConfigFailure,
} from '../actions/cmsConfig.actions';

const fetchCmsConfigApi = (dataProvider) => {
    return dataProvider.getCmsConfig();
};

function* fetchCmsConfig(dataProvider) {
    try {
        const response = yield call(fetchCmsConfigApi, dataProvider);
        yield put(fetchCmsConfigSuccess(response.data));
    } catch (error) {
        yield put(fetchCmsConfigFailure(error));
    }
}

function* cmsConfigSaga(dataProvider) {
    yield takeEvery(FETCH_CMS_CONFIG_REQUEST, fetchCmsConfig, dataProvider);
}

export default cmsConfigSaga;
