import { makeStyles } from '@material-ui/core/styles';
import AssistantIcon from '@material-ui/icons/Assistant';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    FieldsGenerator,
} from 'candy-commons-components';
import React, { useEffect, useState } from 'react';
import {
    ArrayInput,
    BooleanInput,
    Edit,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    useEditController,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import { showListData } from './assistanceStaticData';
const styles = {
    hidden: { display: 'none' },
    fullLine: { width: '100%' },
    flex: { display: 'flex' },
};
const AssistanceEdit = ({ permissions = {}, ...props }) => {
    const controllerProps = useEditController(props);
    const { record = {} } = controllerProps;
    const [isMulti, setIsMulti] = useState(record.isMultipleRecord);
    const classes = useStyles();
    const translation = useTranslate();
    const isEditDisabled = !permissions?.assistance?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: translation('resources.assistance.name'),
            url: 'assistance',
            icon: <AssistantIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const ShowList = ({ innerRecord, ...innerProps }) => (
        <FieldsGenerator
            fields={showListData}
            record={innerRecord}
            {...innerProps}
        />
    );
    useEffect(() => {
        setIsMulti(record.isMultipleRecord);
    }, [record.isMultipleRecord]);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ShowList />
                    <BooleanInput
                        label="Multiple Records"
                        source="isMultipleRecord"
                        disabled
                        onClick={(e) => setIsMulti(e.target.checked)}
                    />
                    <ContainerFieldsList style={!isMulti ? {} : styles.hidden}>
                        <TextInput source="country" disabled={isEditDisabled} />
                        <TextInput source="phone" disabled={isEditDisabled} />
                        <TextInput source="link" disabled={isEditDisabled} />
                    </ContainerFieldsList>
                    {/*Multi record enabled*/}
                    <ArrayInput
                        source="multipleRecords"
                        style={isMulti ? {} : styles.hidden}
                        disabled={isEditDisabled}
                    >
                        <SimpleFormIterator>
                            {/*Country or zone or region or language declination*/}
                            <TextInput source="country" />
                            <TextInput source="phone" />
                            <TextInput source="link" />
                            <TextInput source="description" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default AssistanceEdit;
