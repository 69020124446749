import { connect } from 'react-redux';
import LanguageIcon from '@material-ui/icons/Language';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import IconContentPublish from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
} from 'candy-commons-components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Storage } from 'aws-amplify';
import {
    TextField,
    TextInput,
    CreateButton,
    useNotify,
    useTranslate,
    useRefresh,
} from 'react-admin';
import axios from 'axios';
import config from '../../config';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDataGrid from '../../components/organisms/customDatagrid';
import Popup from '../../components/Popup/Popup';
import { checkFileErrors, checkLanguageErrors } from '../../utils/fileUtils';
const LanguageFilter = (props) => (
    <CustomFilter {...props}>
        <TextInput
            label="resources.language.fields.search"
            source="q"
            alwaysOn
        />
    </CustomFilter>
);
const LanguageListAction = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions,
    data,
    getErrors,
}) => {
    const notify = useNotify();
    const translation = useTranslate();
    const refresh = useRefresh();
    const refreshTimeout = useRef(0);
    const languagesExporting = useMemo(
        () =>
            Object.values(data).some((item) => item.langStatus === 'exporting'),
        [data]
    );
    const canExportLanguages = useMemo(
        () =>
            Object.values(data).some((item) =>
                ['draft', 'draft_imported'].includes(item.langStatus)
            ),
        [data]
    );
    useEffect(() => {
        if (languagesExporting) {
            refreshTimeout.current = setInterval(() => {
                refresh();
            }, 5000);
        }
        if (!languagesExporting && refreshTimeout.current) {
            clearTimeout(refreshTimeout.current);
            refreshTimeout.current = 0;
            notify(
                'resources.language.notification.publish_translations_completed'
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagesExporting]);
    const [isLoading, setLoading] = useState(false);
    const uploadFile = async (event) => {
        setLoading(true);
        const reader = new FileReader();
        const file = event.target.files[0];
        if (!file) {
            notify('no file');
            setLoading(false);
            return;
        }
        let errors;
        reader.onload = async (e) => {
            errors = await checkLanguageErrors(e.target.result);
            if (errors && errors.length) {
                getErrors([
                    `Error: invalid language codes detected in the input file: ${errors}. Please, check the content of the file and try again.`,
                ]);
                setLoading(false);
                return;
            }
            errors = checkFileErrors(e.target.result);
            getErrors(errors);
            if (!errors.length) {
                const user = await localStorage.getItem(
                    `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
                );
                const key = `translations/translation_all_${user}_${new Date().toISOString()}.xlsx`;
                await Storage.put(key, file, {
                    contentType: file.type,
                });
                notify('resources.language.notification.import-all-completed');
            }
            setLoading(false);
        };
        reader.readAsBinaryString(file);
    };
    const downloadFile = async () => {
        const { bucket } = config;
        window.open(
            `https://${bucket}.s3.amazonaws.com/public/translations_all.xlsx`,
            '_blank'
        );
        notify('only published languages can be exported!');
    };
    const exportAllLanguages = async () => {
        const exportConfirm = window.confirm(
            translation(
                'resources.language.action.publish_translations_confirm'
            )
        );
        if (exportConfirm) {
            const username = localStorage.getItem(
                `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
            );
            const token = localStorage.getItem(
                `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`
            );
            const headers = { Authorization: token };
            await axios.post(
                `${config.basePath}/language/publish`,
                {},
                { headers }
            );
            notify('resources.language.notification.publishing_translations');
            refresh();
        }
    };
    return (
        <Toolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {permissions &&
                permissions.translation &&
                permissions.translation.update &&
                !permissions.translation.restrictLanguages && (
                    <div>
                        <label
                            htmlFor="upload-file"
                            style={{ cursor: 'pointer' }}
                        >
                            <Button
                                color="primary"
                                label="Upload translation"
                                disabled
                                style={{
                                    color: '#3f51b5',
                                    cursor: 'pointer',
                                }}
                            >
                                {isLoading ? (
                                    <CircularProgress size={25} thickness={2} />
                                ) : (
                                    <IconContentPublish />
                                )}
                                <span>
                                    {translation(
                                        'resources.language.action.upload_translation'
                                    )}
                                </span>
                            </Button>
                        </label>
                        <input
                            id="upload-file"
                            type="file"
                            onChange={uploadFile}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            style={{
                                display: 'none',
                            }}
                        />
                    </div>
                )}
            {permissions &&
                permissions.translation &&
                permissions.translation.read &&
                !permissions.translation.restrictLanguages && (
                    <Button
                        color="primary"
                        label="Export translations"
                        onClick={downloadFile}
                    >
                        <GetAppIcon />
                        <span>
                            {translation(
                                'resources.language.action.export_translations'
                            )}
                        </span>
                    </Button>
                )}
            {permissions &&
                permissions.translation &&
                permissions.translation.read &&
                !permissions.translation.restrictLanguages && (
                    <Button
                        color="primary"
                        label="resources.language.action.publish_translations"
                        onClick={exportAllLanguages}
                        disabled={!canExportLanguages || languagesExporting}
                    >
                        <GetAppIcon />
                        <span>
                            {translation(
                                'resources.language.action.publish_translations'
                            )}
                        </span>
                    </Button>
                )}
            {permissions &&
                permissions.language &&
                permissions.language.create && (
                    <CreateButton basePath={basePath} />
                )}
        </Toolbar>
    );
};
const LanguageList = ({ permissions, data, ...props }) => {
    const [errors, setErrors] = useState([]);
    const _getErrors = (errors) => setErrors(errors);
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.language.name'),
            url: '',
            icon: <LanguageIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                {...props}
                exporter={false}
                pagination={<DynamoPagination resource="language" />}
                filters={<LanguageFilter />}
                actions={
                    <LanguageListAction
                        permissions={permissions}
                        data={data}
                        getErrors={_getErrors}
                    />
                }
                bulkActionButtons={
                    permissions &&
                    permissions.language &&
                    permissions.language.delete
                }
            >
                <CustomDataGrid rowClick="edit" selectable>
                    <TextField source="code" />
                    <TextField source="langName" />
                    <TextField source="version" />
                    <TextField source="langStatus" />
                </CustomDataGrid>
            </CustomList>
            {errors.length > 0 && (
                <Popup
                    errors={errors}
                    onClose={() => {
                        setErrors([]);
                        window.location.reload();
                    }}
                />
            )}
        </>
    );
};
const mapStateToProps = (state) => ({
    data: state.admin.resources.language.data,
});
export default connect(mapStateToProps)(LanguageList);
