import { makeStyles } from '@material-ui/core/styles';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useMemo } from 'react';
import {
    BooleanInput,
    Edit,
    SimpleForm,
    required,
    useGetList,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../../components/CustomToolbar';
const GuidedWashingChoicesCreate = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const tranlation = useTranslate();
    const isEditDisabled = !permissions?.['guided-washing-choices']?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: tranlation(
                'resources.guided-washing-choices.name_breadcrumbs'
            ),
            url: 'guided-washing-choices',
            icon: <SwapHorizIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const { ids = [], data } = useGetList('guided-washing-cluster', {}, {});
    const types = useMemo(
        () =>
            ids
                .filter((x) => data[x].category === 'FABRICS')
                .map((x) => ({
                    id: data[x].clusterType,
                    name: data[x].clusterType,
                })),
        [ids, data]
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ContainerFieldsList>
                        <CustomSelectArrayInput
                            source="selection"
                            choices={types}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="choiceResult"
                            choices={types}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <BooleanInput
                            initialValue={false}
                            source="recommended"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default GuidedWashingChoicesCreate;
