import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import { TextField, TextInput, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';

const SmartCheckupList = ({ permissions, ...props }) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.smart-checkup.name'),
            url: '',
            icon: <ErrorIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                pagination={<DynamoPagination resource="smart-checkup" />}
                exporter={false}
                filters={
                    <CommonApplianceFilter
                        applianceType
                        optionalFilters={[
                            <TextInput
                                source="errorCode"
                                label="Error Code"
                                alwaysOn
                            />,
                            <TextInput source="title" label="Title" alwaysOn />,
                        ]}
                    />
                }
                {...props}
                bulkActionButtons={
                    permissions &&
                    permissions['smart-checkup'] &&
                    permissions['smart-checkup'].delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField
                        source="errorCode"
                        label="Technical Error Code"
                    />
                    <TextField source="applianceType" />
                    <TextField source="series" />
                    <TextField source="seriesVersion" />
                    <TextField source="title" />
                    <TextField source="description" />
                    <TextField source="cdmParameter" />
                    <TextField source="category" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default SmartCheckupList;
