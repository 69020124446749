const Season = [{
  name: 'winter',
  from: '12-21',
  to: '03-20'
}, {
  name: 'spring',
  from: '03-20',
  to: '06-21'
}, {
  name: 'summer',
  from: '06-21',
  to: '09-23'
}, {
  name: 'autumn',
  from: '09-23',
  to: '12-21'
}];
module.exports = Season;