import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import loadingTypes from '../../data/loadingType.json';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
} from '../../selectors/cmsConfig';
const SupportCreate = (props) => {
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands).filter((x) => x.id !== 'all');
    const series = useSelector(getSeries);
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.support.name'),
            url: 'support',
            icon: <ContactSupportIcon />,
        },
        {
            text: translation('resources.support.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm redirect="list">
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            validate={required()}
                            choices={applianceTypes}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={brands}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={series}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="loadingType"
                            choices={loadingTypes}
                        />
                        <TextInput source="title" validate={required()} />
                        <TextInput source="type" validate={required()} />
                        <TextInput source="screen" validate={required()} />
                        <TextInput source="iconPath" validate={required()} />
                        <TextInput source="description" validate={required()} />
                        <TextInput
                            source="tutorialName"
                            validate={required()}
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
const useStyles = makeStyles({});
export default SupportCreate;
