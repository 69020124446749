import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import {
    ArrayInput,
    BooleanInput,
    Create,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
} from '../../selectors/cmsConfig';
const breadcrumbsData = [
    {
        text: 'Appliance Configuration',
        url: '',
        icon: <ViewComfyIcon />,
    },
    {
        text: 'Create',
        url: '',
    },
];
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const ApplianceConfigurationCreate = (props) => {
    const classes = useStyles();
    const brands = useSelector(getBrands);
    const applianceTypes = useSelector(getApplianceTypes);
    const series = useSelector(getSeries);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceTypes}
                        />
                        <TextInput source="applianceModel" />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={series}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={brands}
                        />
                    </ContainerFieldsList>
                    <hr className={classes.width} />
                    <ArrayInput source="sections">
                        <SimpleFormIterator>
                            <CustomSelectInput
                                source="title"
                                choices={[
                                    { id: 'enrollment', name: 'Enrollment' },
                                    { id: 'demo', name: 'Demo' },
                                    { id: 'chatbot', name: 'Chatbot' },
                                    {
                                        id: 'inventory',
                                        name: 'Label Inventory',
                                    },
                                    {
                                        id: 'guided_washing',
                                        name: 'Guided Washing',
                                    },
                                    { id: 'snap_wash', name: 'Snap & Wash' },
                                    {
                                        id: 'snap_wash_dw',
                                        name: 'Snap & Wash Dishwasher',
                                    },
                                    { id: 'hints', name: 'Hints & Tips' },
                                ]}
                            />
                            <BooleanInput
                                label="Hide section"
                                source="visibility"
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="topicsSubscribe">
                        <SimpleFormIterator>
                            <TextInput source="topic" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="topicsPublish">
                        <SimpleFormIterator>
                            <TextInput source="topic" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default ApplianceConfigurationCreate;
