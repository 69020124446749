import React from 'react';
import { CustomFilter, CustomSelectInput } from 'candy-commons-components';
import { useSelector } from 'react-redux';
import { TextInput } from 'react-admin';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';

const CommonApplianceFilter = ({
    applianceType: applianceTypeProp,
    brand: brandProp,
    series: seriesProp,
    seriesVersion: seriesVersionProp,
    optionalFilters = [],
    ...props
}) => {
    const brands = useSelector(getBrands);
    const applianceTypes = useSelector(getApplianceTypes);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);

    return (
        <CustomFilter {...props}>
            {applianceTypeProp && (
                <CustomSelectInput
                    source="applianceType"
                    choices={applianceTypes}
                    label="Appliance Type"
                    alwaysOn
                    allowEmpty
                />
            )}
            {brandProp && (
                <CustomSelectInput
                    source="brand"
                    label="Brand"
                    choices={brands}
                    alwaysOn
                    allowEmpty
                />
            )}
            {seriesProp && (
                <CustomSelectInput
                    source="series"
                    label="Series"
                    choices={series}
                    alwaysOn
                    allowEmpty
                />
            )}
            {seriesVersionProp && (
                <CustomSelectInput
                    label="Series Version"
                    source="seriesVersion"
                    choices={seriesVersion}
                    alwaysOn
                    allowEmpty
                />
            )}
            {optionalFilters.map((filter, index) => filter)}
        </CustomFilter>
    );
};

export default CommonApplianceFilter;
