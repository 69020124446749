import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
    FieldsGenerator,
} from 'candy-commons-components';
import {
    ArrayInput,
    BooleanInput,
    Edit,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import countries from '../../data/countries.json';
import loadingTypes from '../../data/loadingType.json';
import connectivity from './applianceConnectivityChoices';
import { sectionChoices } from './sectionChoices';
import { useSelector } from 'react-redux';
import {
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const ApplianceConfigurationEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const isEditDisabled = !permissions['appliance-configuration']?.update;
    const { id } = props;
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersions = useSelector(getSeriesVersion);
    const breadcrumbsData = [
        {
            text: 'Appliance Configuration',
            url: 'appliance-configuration',
            icon: <ViewComfyIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator
            fields={[
                {
                    label: 'applianceType',
                    type: 'textField',
                    source: 'applianceType',
                },
                {
                    label: 'Series Version',
                    type: 'textField',
                    source: 'applianceModel',
                },
                {
                    label: 'series',
                    type: 'textField',
                    source: 'series',
                },
                {
                    label: 'brand',
                    type: 'textField',
                    source: 'brand',
                },
                {
                    label: 'connectivity',
                    type: 'textField',
                    source: 'connectivity',
                },
            ]}
            record={record}
            {...props}
        />
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ShowList />
                    <hr className={classes.width} />
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceModel"
                            label="Series Version"
                            disabled={isEditDisabled}
                            choices={seriesVersions}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={series}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={brands}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="country"
                            choices={countries}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="connectivity"
                            choices={connectivity}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="loadingType"
                            choices={loadingTypes}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <ArrayInput source="sections" disabled={isEditDisabled}>
                        <SimpleFormIterator>
                            <CustomSelectInput
                                source="title"
                                choices={sectionChoices.sort((a, b) =>
                                    a.id.localeCompare(b.id)
                                )}
                            />
                            <BooleanInput label="" source="visibility" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput
                        source="topicsSubscribe"
                        disabled={isEditDisabled}
                    >
                        <SimpleFormIterator>
                            <TextInput source="topic" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput
                        source="topicsPublish"
                        disabled={isEditDisabled}
                    >
                        <SimpleFormIterator>
                            <TextInput source="topic" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
export default ApplianceConfigurationEdit;
