import { makeStyles } from '@material-ui/core/styles';
import Fastfood from '@material-ui/icons/Fastfood';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconContentPublish from '@material-ui/icons/Publish';
import { Storage } from 'aws-amplify';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    FieldsGenerator,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    Button,
    CardActions,
    Edit,
    FileField,
    FileInput,
    ImageField,
    SimpleForm,
    TextInput,
    useNotify,
    useTranslate,
    withDataProvider,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import config from '../../config';
const ShowList = ({ record, ...props }) => (
    <FieldsGenerator
        fields={[
            {
                label: 'applianceType',
                type: 'textField',
                source: 'applianceType',
            },
            {
                label: 'applianceModel',
                type: 'textField',
                source: 'applianceModel',
            },
            {
                label: 'series',
                type: 'textField',
                source: 'series',
            },
            {
                label: 'brand',
                type: 'textField',
                source: 'brand',
            },
        ]}
        record={record}
        {...props}
    />
);
const PostEditActions = ({ data, resource, dataProvider, code }) => {
    const download = () => {
        window.location.href = data.exportLink;
    };
    return (
        <CardActions>
            <Button label="resources.language.action.upload">
                <IconContentPublish />
            </Button>
            <Button
                disabled={
                    !data || !data.langStatus || data.langStatus !== 'published'
                }
                label="resources.language.action.export"
                onClick={download}
            >
                <GetAppIcon />
            </Button>
        </CardActions>
    );
};
const RecipeEdit = ({
    data,
    resource,
    basePath,
    dataProvider,
    dispatch,
    permissions,
    ...props
}) => {
    const classes = useStyles();
    const translation = useTranslate();
    const isEditDisabled = !permissions?.recipe?.update;
    const { id } = props;
    const { bucketBea } = config;
    const breadcrumbsData = [
        {
            text: translation('resources.recipe.name'),
            url: 'recipe',
            icon: <Fastfood />,
        },
        {
            text: id,
            url: '',
            capitalize: true,
        },
    ];
    const [isLoading, setLoading] = useState(false);
    const notify = useNotify();
    // const history = useHistory();
    const handleClick = async (event) => {
        setLoading(true);
        const file = event;
        if (!file) {
            notify('no file');
            return;
        }
        const applianceType = id.split('#')[1];
        const key = `recipes/${applianceType}/${file.name}`;
        const upload = await Storage.put(key, file, {
            contentType: file.type,
            // bucket: bucketBea
        });
        setLoading(false);
        notify('resources.language.notification.import-all-completed');
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit
                // actions={<PostEditActions dataProvider={dataProvider} dispatch={dispatch} />}
                id={id}
                className={classes.invertButtonSave}
                resource={resource}
                basePath={basePath}
            >
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ShowList />
                    <hr className={classes.width} />
                    <ContainerFieldsList>
                        <TextInput source="id" disabled />
                        <TextInput source="title" disabled />
                        <TextInput source="course" disabled />
                        <TextInput source="difficulty" disabled />
                    </ContainerFieldsList>
                    <ImageField source="image" />
                    {!isEditDisabled && (
                        <FileInput
                            source="files"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={handleClick}
                        >
                            <FileField source="src" title="title" />
                        </FileInput>
                    )}
                    {/* <input
           id="upload-file"
           type="file"
           onChange={handleClick}
           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
           style={{
             display: 'none',
           }}
         /> */}
                    {/* <ArrayInput source="sections">
            <SimpleFormIterator>
              <CustomSelectInput
                allowEmpty
                source="title"
                choices={[
                  { id: 'enrollment', name: 'Enrollment' },
                  { id: 'demo', name: 'Demo' },
                  { id: 'chatbot', name: 'Chatbot' },
                  { id: 'inventory', name: 'Label Inventory' },
                  { id: 'guided_washing', name: 'Guided Washing' },
                  { id: 'snap_wash', name: 'Snap & Wash' },
                  { id: 'snap_wash_dw', name: 'Snap & Wash Dishwasher' },
                  { id: 'hints', name: 'Hints & Tips' },
                ]}
              />
              <BooleanInput label="Hide sections" source="visibility" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="topicsSubscribe">
            <SimpleFormIterator>
              <TextInput source="topic" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="topicsPublish">
            <SimpleFormIterator>
              <TextInput source="topic" />
            </SimpleFormIterator>
          </ArrayInput> */}
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
export default withDataProvider(RecipeEdit);
