import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    ImageField,
    useTranslate,
} from 'react-admin';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
} from 'candy-commons-components';
import ImageIcon from '@material-ui/icons/Image';
import ImageInput from '../../components/ImageInput';
const AssetCreate = (props) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.asset.name'),
            url: 'asset',
            icon: <ImageIcon />,
        },
        {
            text: translation('resources.asset.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm redirect="list">
                    <ContainerFieldsList>
                        <TextInput source="code" validate={required()} />
                        <TextInput source="assetName" validate={required()} />
                    </ContainerFieldsList>
                    <ImageInput
                        source="pictures"
                        accept="image/*"
                        validate={required()}
                        placeholder={
                            <p>
                                {translation(
                                    'resources.asset.fields.input_file'
                                )}
                            </p>
                        }
                    >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default AssetCreate;
