import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPagination } from '../actions';
const DynamoPagination = (props) => {
    console.log('props: ', props);
    const { ids: data = [] } = props;
    const isNoData = !data.length;
    const {
        page,
        perPage,
        total,
        setPage,
        setPerPage,
        setPaginationAction,
        pagination,
        resource,
    } = props;
    const classes = useStyles();
    const { paginationToken } = pagination[resource] || {};
    const setNextPage = () => {
        const newPage = page + 1;
        const paginationDynamo = {
            resource,
            newToken: {
                [newPage]: paginationToken,
            },
        };
        setPaginationAction(paginationDynamo);
        setPage(newPage);
    };
    const setPrevPage = () => {
        const newPage = page - 1;
        setPage(newPage);
    };
    const handlePerPageChange = (event) => {
        setPerPage(event.target.value);
    };
    const rowsPerPageOptions = [5, 10, 25];
    if (isNoData) {
        console.log('is not data', isNoData);
        return (
            <Box className={classes.noData}>
                {translate('ra.navigation.no_results')}
            </Box>
        );
    }
    return (
        <Toolbar className={classes.toolbar}>
            <IconButton
                onClick={setPrevPage}
                disabled={page === 1}
                aria-label="prev page"
                color="primary"
            >
                <KeyboardArrowLeft />
            </IconButton>
            <Typography variant="caption" style={{ marginRight: '1%' }}>
                {translate('ra.navigation.page_rows_per_page')}
            </Typography>
            <Select
                input={<Input disableUnderline />}
                value={perPage}
                onChange={handlePerPageChange}
            >
                {rowsPerPageOptions.map((rowsPerPageOption) => (
                    <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                        {rowsPerPageOption}
                    </MenuItem>
                ))}
            </Select>
            <IconButton
                onClick={setNextPage}
                disabled={!paginationToken}
                aria-label="next page"
                color="primary"
            >
                <KeyboardArrowRight />
            </IconButton>
        </Toolbar>
    );
};
const useStyles = makeStyles((theme) => {
    return {
        toolbar: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        noData: {
            padding: 20,
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
    };
});
const mapStateToProps = (state) => ({
    pagination: state.custom.pagination || {},
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setPaginationAction: setPagination,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(DynamoPagination);
