import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import React, { useRef, useState, useEffect } from 'react';
import { Datagrid, DatagridBody, useTranslate } from 'react-admin';
import RootRef from '@material-ui/core/RootRef';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import CustomizableDatagrid from '../../customLib/ra-customizable-datagrid';
const checkIsAllFalse = (raColumnsConfigResourceName) => {
    const onlyTrueColumns = Object.keys(raColumnsConfigResourceName).filter(
        (key) => raColumnsConfigResourceName[key] === true
    );
    return !onlyTrueColumns.length;
};
export const CustomDatagrid = ({
    label,
    record,
    source,
    selectable,
    className,
    children,
    rowClick,
    goToLink,
    choiceColumns,
    defaultColumns,
    ...otherProps
}) => {
    const history = useHistory();
    const translate = useTranslate();
    const classes = useStyles();
    const tableRef = useRef(null);
    const tableBodyRef = useRef(null);
    const [tableDisplayBlock, setTableDisplayBlock] = useState(false);
    const [windowWidth, setWindowWidth] = useState(false);
    const clientWidthTableBody =
        tableBodyRef &&
        tableBodyRef.current &&
        tableBodyRef.current.clientWidth;
    const checkWidthTable = () => {
        const widthTable =
            tableRef && tableRef.current && tableRef.current.clientWidth;
        const widthTableBody =
            tableBodyRef &&
            tableBodyRef.current &&
            tableBodyRef.current.clientWidth;
        if (
            widthTableBody &&
            widthTable &&
            widthTableBody >= widthTable &&
            widthTableBody !== widthTable
        ) {
            setTableDisplayBlock(true);
        } else {
            setTableDisplayBlock(false);
        }
    };
    const CustomStorage = {
        get: (resourceName) => {
            const raColumnsConfig = JSON.parse(
                localStorage.getItem('raColumnsConfig')
            );
            const raColumnsConfigResourceName =
                raColumnsConfig && raColumnsConfig[resourceName];
            const columns = raColumnsConfigResourceName;
            if (
                raColumnsConfigResourceName &&
                checkIsAllFalse(raColumnsConfigResourceName)
            ) {
                if (defaultColumns && defaultColumns.length) {
                    defaultColumns.map((key) => {
                        columns[key] = true;
                    });
                }
            }
            return columns;
        },
        set: (resourceName, selectedColumns) => {
            const raColumnsConfig =
                JSON.parse(localStorage.getItem('raColumnsConfig')) || {};
            raColumnsConfig[resourceName] = selectedColumns;
            localStorage.setItem(
                'raColumnsConfig',
                JSON.stringify(raColumnsConfig)
            );
        },
        checkWidthTable: () => {
            checkWidthTable();
        },
    };
    useEffect(() => {
        checkWidthTable();
    }, [clientWidthTableBody, windowWidth]);
    window.addEventListener('resize', () => {
        if (window.innerWidth !== windowWidth) {
            setWindowWidth(window.innerWidth);
        }
    });
    const CustomDatagridRow = ({
        record,
        resource,
        id,
        onToggleItem,
        children,
        selected,
        basePath,
        ...props
    }) => {
        const { pathname = '', search = '', state = {} } = goToLink || {};
        const historyPathname = rowClick
            ? `${basePath}/${id}/${rowClick}`
            : pathname || '';
        const historySearch = search || '';
        let historyState = state || {};
        if (goToLink) {
            const filterDefaultValues =
                state &&
                state.filterDefaultValues &&
                state.filterDefaultValues.length
                    ? state.filterDefaultValues.reduce((sum, filterName) => {
                          const newSum = { ...sum };
                          newSum[filterName] = record[filterName];
                          return newSum;
                      }, {})
                    : [];
            historyState = { ...state, filterDefaultValues };
        }
        return (
            <TableRow key={id} className={historyPathname ? classes.row : null}>
                {selectable && props.hasBulkActions && (
                    <TableCell className={classes.resetStyle}>
                        <Box className={classes.containerElement}>
                            <Checkbox
                                color="primary"
                                checked={selected}
                                onClick={(event) => onToggleItem(id, event)}
                            />
                        </Box>
                    </TableCell>
                )}
                {React.Children.map(children, (field) => (
                    <TableCell
                        key={`${id}-${field.props.source}`}
                        onClick={() =>
                            historyPathname || rowClick
                                ? history.push({
                                      pathname: historyPathname,
                                      search: historySearch,
                                      state: historyState,
                                  })
                                : null
                        }
                    >
                        <Box className={classes.containerElement}>
                            {React.cloneElement(field, {
                                record,
                                basePath,
                                resource,
                            })}
                        </Box>
                    </TableCell>
                ))}
            </TableRow>
        );
    };
    const CustomDatagridBody = (props) => (
        <RootRef rootRef={tableBodyRef}>
            <DatagridBody {...props} row={<CustomDatagridRow />} />
        </RootRef>
    );
    const MyDatagrid = (props) => {
        const { ids: data = [] } = props;
        const isNoData = !data.length;
        return !isNoData && choiceColumns ? (
            <CustomizableDatagrid
                {...props}
                storage={CustomStorage}
                defaultColumns={defaultColumns}
                translate={translate}
                body={<CustomDatagridBody />}
            />
        ) : (
            <Datagrid {...props} body={<CustomDatagridBody />} />
        );
    };
    return (
        <RootRef rootRef={tableRef}>
            <Box className={clsx(classes.box, className)}>
                <MyDatagrid
                    style={{ display: tableDisplayBlock ? 'block' : 'table' }}
                    className={clsx(
                        classes.datagrid,
                        selectable ? null : classes.notSelectable
                    )}
                    label={label}
                    record={record}
                    source={source}
                    {...otherProps}
                >
                    {children}
                </MyDatagrid>
            </Box>
        </RootRef>
    );
};
const useStyles = makeStyles((theme) => ({
    box: {
        '& .MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
            marginTop: 10,
            marginBottom: 10,
        },
    },
    resetStyle: {
        padding: 0,
    },
    notSelectable: {
        '& .MuiTableCell-paddingCheckbox': {
            display: 'none',
        },
    },
    datagrid: {
        overflowX: 'auto',
        '& .MuiTableCell-head': {
            backgroundColor: theme.palette.common.linkWater,
            borderBottom: `solid 1px ${theme.palette.common.ceruleanBlue}`,
            fontWeight: 600,
            whiteSpace: 'nowrap',
        },
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight': {
            textAlign: 'left',
        },
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-paddingCheckbox': {
            padding: 9,
        },
        '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl':
            {
                marginTop: 0,
                '&:before, &:after': {
                    content: 'none',
                },
            },
    },
    row: {
        '&:hover': {
            backgroundColor: theme.palette.common.grey,
            cursor: 'pointer',
        },
    },
    containerElement: {
        minHeight: 20,
    },
}));
export default CustomDatagrid;
