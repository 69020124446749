import { makeStyles } from '@material-ui/core/styles';
import AssistantIcon from '@material-ui/icons/Assistant';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React, { useCallback } from 'react';
import { FunctionField, TextField, useTranslate } from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const NewFeaturesBannerList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.new-features-banner.name'),
            url: '',
            icon: <AssistantIcon />,
        },
    ];
    const renderApplianceTypes = useCallback((item) => {
        if (item?.isAllAppliance) {
            return 'ALL';
        }
        if (item?.isNoAppliance) {
            return 'None';
        }
        if (Array.isArray(item?.applianceType)) {
            return item.applianceType.join(',');
        }
    }, []);
    const renderBrands = useCallback((item) => {
        if (item?.isAllBrand) {
            return 'ALL';
        }
        if (Array.isArray(item?.brand)) {
            return item.brand.join(',');
        }
    }, []);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={25}
                exporter={false}
                bulkActionButtons={
                    permissions &&
                    permissions.assistance &&
                    permissions.assistance.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="title" label="Title" />
                    {/* <TextField source="description" label="Description" /> */}
                    <FunctionField
                        label="Appliance type"
                        render={renderApplianceTypes}
                        source="applianceType"
                    />
                    <FunctionField
                        label="Brand"
                        render={renderBrands}
                        source="brand"
                    />
                    <TextField source="pageContent" label="Page content" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
const useStyles = makeStyles({});
export default NewFeaturesBannerList;
