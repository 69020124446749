import { number } from 'react-admin';
export default [
    number(),
    (value) => {
        if (value < -1) {
            return 'Order must be 0 or more';
        }
        if (!Number.isInteger(value)) {
            return 'Order must be a decimal number';
        }
        return null;
    },
];
