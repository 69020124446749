const dev = {
    env: 'dev',
    label: 'Dev',
    logger: true,
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_OGlmSODwg',
    userPoolWebClientId: '4s8q0fbigvidibo9g70s210d3i',
    basePath: 'http://localhost:3000/cms',
    identityPoolId: 'eu-central-1:8ff7f1b3-aba5-4424-8ce4-3b62c4d15ee7',
    switcherUrl: '',
    bucket: 'candy-assets-dev',
    bucketBea: 'candy-assets-dev',
    genAiBucket: 'haier-ai-hon-i18n-dev',
};
const prod = {
    env: 'prod',
    label: 'Prod',
    logger: false,
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_bImlsWn0B',
    userPoolWebClientId: '1i6th9deddh9mcd0gkgadsfom1',
    basePath: 'https://api-iot.he.services/cms',
    identityPoolId: 'eu-west-1:72cbdf70-6e76-4b58-bbdc-fb6d471ee3b4',
    switcherUrl: 'https://admin-iot.he.services/admin-portal', // The admin uri for portal switching,
    bucket: 'candy-assets-prd',
    bucketBea: 'candy-assets-prd',
    genAiBucket: 'haier-ai-hon-i18n-prd',
};
const staging = {
    env: 'staging',
    label: 'Dev!',
    logger: false,
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_OGlmSODwg',
    userPoolWebClientId: '4s8q0fbigvidibo9g70s210d3i',
    basePath: 'https://dev.api-iot.he.services/cms',
    identityPoolId: 'eu-central-1:8ff7f1b3-aba5-4424-8ce4-3b62c4d15ee7',
    switcherUrl: 'https://dev.admin-iot.he.services/admin-portal', // The admin uri for portal switching
    bucket: 'candy-assets-dev',
    bucketBea: 'candy-assets-dev',
    genAiBucket: 'haier-ai-hon-i18n-dev',
};
const test = {
    env: 'test',
    label: 'Test',
    logger: false,
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_66xhkLFjl',
    userPoolWebClientId: '3gv7atp4sl127hsqp8qbcidego',
    basePath: 'https://test.api-iot.he.services/cms',
    identityPoolId: 'eu-west-2:403edff0-8943-4077-9fc4-71ced1894014',
    switcherUrl: 'https://test.admin-iot.he.services/admin-portal', // The admin uri for portal switching
    bucket: 'candy-assets-tst',
    bucketBea: 'candy-assets-tst',
    genAiBucket: 'haier-ai-hon-i18n-tst',
};
const stg = {
    env: 'stg',
    label: 'Stage',
    logger: false,
    region: 'eu-north-1',
    userPoolId: 'eu-north-1_21s0HJqds',
    userPoolWebClientId: '3bjvehd2q4tshb2a5p99mq1060',
    basePath: 'https://stg.api-iot.he.services/cms',
    identityPoolId: 'eu-north-1:337680c0-d17d-497e-b20b-ecc7e3a07e79',
    switcherUrl: 'https://stg.admin-iot.he.services/admin-portal', // The admin uri for portal switching
    bucket: 'candy-assets-stg',
    bucketBea: 'candy-assets-stg',
    genAiBucket: 'haier-ai-hon-i18n-stg',
};
const envConfig = () => {
    switch (process.env.REACT_APP_ENV || process.env.NODE_ENV) {
        case 'production':
            return prod;
        case 'staging':
            return staging;
        case 'aws-dev':
            return staging;
        case 'test':
            return test;
        case 'aws-tst':
            return test;
        case 'aws-prd':
            return prod;
        case 'aws-stg':
            return stg;
        case 'prod':
            return prod;
        case 'development':
            return dev;
        case 'stg':
            return stg;
        default:
            return dev;
    }
};
export default {
    // Add common config values here
    start: new Date(),
    ...envConfig(),
};
