import WorkIcon from '@material-ui/icons/Work';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import { BooleanField, TextField, TextInput, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
} from '../../selectors/cmsConfig';
const MaintenanceFilter = (props) => {
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);

    return (
        <CustomFilter {...props}>
            <CustomSelectInput
                source="productLine"
                choices={applianceTypes}
                alwaysOn
                initialValue=""
                allowEmpty={false}
            />
            <CustomSelectInput
                source="brand"
                allowEmpty
                alwaysOn
                choices={brands}
            />
            <CustomSelectInput source="series" choices={series} alwaysOn />
        </CustomFilter>
    );
};
const MaintenanceList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.maintenance.name'),
            url: '',
            icon: <WorkIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                exporter={false}
                perPage={25}
                {...props}
                filters={<MaintenanceFilter />}
                pagination={<DynamoPagination resource="maintenance" />}
                bulkActionButtons={
                    permissions &&
                    permissions.maintenance &&
                    permissions.maintenance.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="productLine" label="Product Line" />
                    <TextField source="brand" />
                    <TextField source="interfaceType" />
                    <TextField source="country" />
                    <BooleanField source="generic" />
                    <TextField source="link" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default MaintenanceList;
