import SettingsIcon from '@material-ui/icons/Settings';
import { CustomBreadcrumbs } from 'candy-commons-components';
import React, { useState } from 'react';
import {
    BooleanInput,
    Create,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    useTranslate,
} from 'react-admin';
import { QueryFormManager } from '../components/QueryFormManager';
import { FORM_TYPE, PROMO } from '../config';
import {
    normalizeFormDataToSend,
    useGetSelectedCriteria,
} from '../hooks/normalizeFormDataToSend';
import { useStyles } from '../styles';
import { PromoRewardInput } from './components/PromoRewardInput';
const PromoCampaignsCreate = (props) => {
    const translation = useTranslate();
    const classes = useStyles();
    const [isShowNestedCriteria, toggleShowNestedCriteria] = useState(false);
    const [crt, getSelectedCriteriaHandler] = useGetSelectedCriteria();
    const breadcrumbsData = [
        {
            text: translation('resources.promo-campaigns.name'),
            url: 'promo-campaigns',
            icon: <SettingsIcon />,
        },
        {
            text: translation('resources.promo-campaigns.name.create.name'),
            url: '',
        },
    ];
    const CustomToolbar = (props) => {
        return (
            <Toolbar {...props}>
                <SaveButton
                    redirect="list"
                    transform={(data) => {
                        return normalizeFormDataToSend({ data, crt });
                    }}
                />
            </Toolbar>
        );
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm
                    redirect="list"
                    className={classes.fullWidth}
                    toolbar={<CustomToolbar />}
                >
                    <BooleanInput
                        initialValue={true}
                        source="active"
                        label="Active"
                        validate={[required()]}
                        className={classes.fullWidth}
                    />
                    <TextInput
                        source="slug"
                        label="Slug"
                        validate={required()}
                        className={classes.fullWidth}
                    />
                    <TextInput
                        source="content.externalCmsStructureId"
                        label="External CMS Structure Id"
                        className={classes.fullWidth}
                    />
                    <TextInput
                        source="content.externalCmsContentId"
                        label="External CMS Content Id"
                        className={classes.fullWidth}
                    />
                    <NumberInput
                        min="1"
                        source="content.redemptions"
                        label="Redemptions"
                        className={classes.fullWidth}
                        validate={[required()]}
                    />
                    <NumberInput
                        min="1"
                        source="content.redemptionsPerUser"
                        label="Redemptions per user"
                        className={classes.fullWidth}
                    />
                    <PromoRewardInput />
                    <QueryFormManager
                        variant={FORM_TYPE.CREATE}
                        promoType={PROMO.CAMPAIGNS}
                        getActiveCriteria={getSelectedCriteriaHandler}
                    />
                </SimpleForm>
            </Create>
        </>
    );
};
export default PromoCampaignsCreate;
