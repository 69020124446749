import { makeStyles } from '@material-ui/core/styles';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import { TextField, ArrayField, SingleFieldList, ChipField } from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import DynamoPagination from '../../components/DynamoPagination';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';

const breadcrumbsData = [
    {
        text: 'Appliance Configuration',
        url: '',
        icon: <ViewComfyIcon />,
    },
];
const ApplianceConfigurationList = ({ permissions, ...props }) => (
    <>
        <CustomBreadcrumbs data={breadcrumbsData} />
        <CustomList
            perPage={25}
            filters={
                <CommonApplianceFilter
                    applianceType
                    brand
                    series
                    seriesVersion
                />
            }
            pagination={<DynamoPagination resource="appliance-configuration" />}
            exporter={false}
            {...props}
            bulkActionButtons={
                permissions &&
                permissions['appliance-configuration'] &&
                permissions['appliance-configuration'].delete
            }
        >
            <CustomDatagrid
                selectable
                rowClick="edit"
                choiceColumns
                defaultColumns={[
                    'applianceType',
                    'brand',
                    'series',
                    'applianceModel',
                    'sections',
                ]}
            >
                <TextField source="applianceType" />
                <TextField source="brand" label="Brand" />
                <TextField source="series" label="Series" />
                <TextField source="applianceModel" label="Series Version" />
                <TextField source="connectivity" />
                <ArrayField source="sections">
                    <SingleFieldList>
                        <ChipField source="title" />
                    </SingleFieldList>
                </ArrayField>
            </CustomDatagrid>
        </CustomList>
    </>
);
export default ApplianceConfigurationList;
