import React from 'react';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import { FunctionField, TextField, TextInput, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';

const TroubleshootingList = ({ permissions, ...props }) => {
    const transaltion = useTranslate();
    const breadcrumbsData = [
        {
            text: transaltion('resources.troubleshooting.name'),
            url: '',
            icon: <ErrorIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                pagination={<DynamoPagination resource="troubleshooting" />}
                exporter={false}
                filters={
                    <CommonApplianceFilter
                        applianceType
                        brand
                        optionalFilters={[
                            <TextInput
                                label="Appliance Model"
                                source="applianceModel"
                                alwaysOn
                            />,
                            <TextInput
                                label="Error Code"
                                source="errorCode"
                                alwaysOn
                            />,
                        ]}
                    />
                }
                {...props}
                bulkActionButtons={
                    permissions &&
                    permissions.troubleshooting &&
                    permissions.troubleshooting.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="applianceType" />
                    <TextField source="brand" />

                    <TextField source="applianceModel" />
                    <TextField source="loadingType" />

                    <TextField source="platform" />
                    <TextField
                        source="errorCode"
                        label="Technical Error Code"
                    />
                    <TextField source="title" />
                    <TextField source="category" />
                    <TextField source="connectivity" />
                    <FunctionField
                        label="Service App Sync"
                        render={(item) => {
                            return item.serviceAppSync && 'Enabled';
                        }}
                        source="serviceAppSync"
                    />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default TroubleshootingList;
