import { makeStyles } from '@material-ui/core/styles';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import {
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
    FieldsGenerator,
} from 'candy-commons-components';
import React, { useMemo } from 'react';
import {
    ArrayInput,
    BooleanInput,
    Edit,
    SimpleForm,
    SimpleFormIterator,
    useGetList,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../../components/CustomToolbar';
import { showListData } from './guidedWashingClusterStaticData';
const GuidedWashingClusterEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const isEditDisabled = !permissions?.['guided-washing-cluster']?.update;
    const { ids = [], data } = useGetList('guided-washing-cluster', {}, {});
    const types = useMemo(
        () =>
            ids
                .filter((x) => data[x].category === 'FABRICS')
                .map((x) => ({
                    id: data[x].clusterType,
                    name: data[x].clusterType,
                })),
        [ids, data]
    );
    const ShowList = ({ record, ...props }) => (
        <FieldsGenerator fields={showListData} record={record} {...props} />
    );
    const { id } = props;
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation(
                'resources.guided-washing-cluster.name_breadcrumbs'
            ),
            url: 'guided-washing-cluster',
            icon: <GroupWorkIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ShowList />
                    <CustomSelectInput
                        source="category"
                        choices={[
                            { id: 'HOME_CARE', name: 'HOME_CARE' },
                            { id: 'SPECIAL_CARE', name: 'SPECIAL_CARE' },
                            { id: 'FABRICS', name: 'FABRICS' },
                        ]}
                        disabled={isEditDisabled}
                    />
                    <ArrayInput source="outputCycle" disabled={isEditDisabled}>
                        <SimpleFormIterator classes={{ form: classes.form }}>
                            <CustomSelectInput
                                source="type"
                                choices={[
                                    { id: 'WD', name: 'W-WD' },
                                    { id: 'D', name: 'D' },
                                ]}
                            />
                            <BooleanInput source="steam" />
                            <CustomSelectArrayInput
                                source="dry"
                                choices={[
                                    { id: 'IRON', name: 'IRON' },
                                    { id: 'EXTRA', name: 'EXTRA' },
                                    { id: 'CLOSET', name: 'CLOSET' },
                                    { id: 'NO_DRY', name: 'NO_DRY' },
                                    { id: 'DRY', name: 'DRY' },
                                ]}
                            />
                            <CustomSelectInput
                                source="multiple"
                                choices={types}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -8,
        marginLeft: -8,
        '& .MuiFormControl-root.MuiTextField-root': {
            width: 260,
            margin: 8,
        },
        '& >.ra-input': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default GuidedWashingClusterEdit;
