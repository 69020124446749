console.log('loaded');
const _extends =
    Object.assign ||
    function (target) {
        for (let i = 1; i < arguments.length; i++) {
            const source = arguments[i];
            for (const key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
const _createClass = (function () {
    function defineProperties(target, props) {
        for (let i = 0; i < props.length; i++) {
            const descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor) descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps) defineProperties(Constructor.prototype, protoProps);
        if (staticProps) defineProperties(Constructor, staticProps);
        return Constructor;
    };
})();
const _react = require('react');
const _react2 = _interopRequireDefault(_react);
const _propTypes = require('prop-types');
const _propTypes2 = _interopRequireDefault(_propTypes);
const _isEmpty = require('lodash/isEmpty');
const _reactAdmin = require('react-admin');
const _isEmpty2 = _interopRequireDefault(_isEmpty);
const _filter = require('lodash/filter');
const _filter2 = _interopRequireDefault(_filter);
const _get = require('lodash/get');
const _get2 = _interopRequireDefault(_get);
const _ViewColumn = require('@material-ui/icons/ViewColumn');
const _ViewColumn2 = _interopRequireDefault(_ViewColumn);
const _Button = require('@material-ui/core/Button');
const _Button2 = _interopRequireDefault(_Button);
const _SelectionDialog = require('./SelectionDialog');
const _SelectionDialog2 = _interopRequireDefault(_SelectionDialog);
const _LocalStorage = require('./LocalStorage');
const _LocalStorage2 = _interopRequireDefault(_LocalStorage);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
function _objectWithoutProperties(obj, keys) {
    const target = {};
    for (const i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value,
            enumerable: true,
            configurable: true,
            writable: true,
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _possibleConstructorReturn(self, call) {
    if (!self) {
        throw new ReferenceError(
            "this hasn't been initialised - super() hasn't been called"
        );
    }
    return call && (typeof call === 'object' || typeof call === 'function')
        ? call
        : self;
}
function _inherits(subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
        throw new TypeError(
            `Super expression must either be null or a function, not ${typeof superClass}`
        );
    }
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true,
        },
    });
    if (superClass)
        Object.setPrototypeOf
            ? Object.setPrototypeOf(subClass, superClass)
            : (subClass.__proto__ = superClass);
}
const arrayToSelection = function arrayToSelection(values) {
    return values.reduce((selection, columnName) => {
        selection[columnName] = true;
        return selection;
    }, {});
};
// CustomizableDatagrid allows to show/hide columns dynamically
// the preferences are stored in local storage
const CustomizableDatagrid = (function (_Component) {
    _inherits(CustomizableDatagrid, _Component);
    function CustomizableDatagrid(props) {
        _classCallCheck(this, CustomizableDatagrid);
        const _this = _possibleConstructorReturn(
            this,
            (
                CustomizableDatagrid.__proto__ ||
                Object.getPrototypeOf(CustomizableDatagrid)
            ).call(this, props)
        );
        _this.updateStorage = function () {
            _this.setState({ selection: _this.state.selectionCheckbox }, () => {
                _this.props.storage.set(
                    _this.props.resource,
                    _this.state.selectionCheckbox
                );
                _this.props.storage.checkWidthTable();
            });
        };
        _this.translate = props.translate;
        _this.toggleColumn = function (columnName) {
            const previousSelection = _this.state.selectionCheckbox;
            const selectionCheckbox = _extends(
                {},
                previousSelection,
                _defineProperty({}, columnName, !previousSelection[columnName])
            );
            _this.setState({ selectionCheckbox });
        };
        _this.handleOpen = function () {
            return _this.setState({ modalOpened: true });
        };
        _this.handleClose = function () {
            _this.updateStorage();
            return _this.setState({ modalOpened: false });
        };
        _this.renderChild = function (child) {
            let source = (0, _get2.default)(child, ['props', 'source']);
            const duplicate = (0, _get2.default)(child, ['props', 'duplicate']);
            const { selection } = _this.state;
            if (source && duplicate) {
                source = `${source}c`;
            }
            // Show children without source, or children explicitly visible
            if ((!source || selection[source]) && child) {
                return _react2.default.cloneElement(child, {});
            }
            return null;
        };
        _this.state = {
            modalOpened: false,
            selection: _this.getInitialSelection(),
            selectionCheckbox: _this.getInitialSelection(),
        };
        return _this;
    }
    function disableButton(allColumns, selectionCheckbox) {
        let disabled = [];
        let counter = 0;
        const loadSelectionCheckbox = [];
        const allColumnsArray = Object.values(allColumns); // Transform allColumns in array
        // Trasform array with necessary value
        for (var value of allColumnsArray) {
            loadSelectionCheckbox.push(selectionCheckbox[value.source]);
        }
        // Check if array have value of true
        for (var [index, value] of loadSelectionCheckbox.entries()) {
            if (loadSelectionCheckbox[index] === true) {
                counter++;
            }
        }
        // Check counter
        return counter == 1
            ? (disabled = [...loadSelectionCheckbox])
            : (disabled = []);
    }
    _createClass(CustomizableDatagrid, [
        {
            key: 'getColumnNames',
            value: function getColumnNames() {
                const { children } = this.props;
                return (0, _filter2.default)(
                    _react2.default.Children.map(children, (field) =>
                        (0, _get2.default)(field, ['props', 'source'])
                    )
                );
            },
        },
        {
            key: 'getColumnLabels',
            value: function getColumnLabels() {
                const { children } = this.props;
                return (0, _filter2.default)(
                    _react2.default.Children.map(
                        children,
                        (field) =>
                            field && {
                                source: (0, _get2.default)(field, [
                                    'props',
                                    'source',
                                ]),
                                label: (0, _get2.default)(field, [
                                    'props',
                                    'label',
                                ]),
                            }
                    ),
                    (item) => item && item.source
                );
            },
        },
        {
            key: 'getInitialSelection',
            value: function getInitialSelection() {
                const _props = this.props;
                const { defaultColumns } = _props;
                const { resource } = _props;
                const { children } = _props;
                const { storage } = _props;
                const previousSelection = storage.get(resource);
                // if we have a previously stored value, let's return it
                if (!(0, _isEmpty2.default)(previousSelection)) {
                    return previousSelection;
                }
                // if defaultColumns are set let's return them
                if (!(0, _isEmpty2.default)(defaultColumns)) {
                    return arrayToSelection(defaultColumns);
                }
                // otherwise we fallback on the default behaviour : display all columns
                return arrayToSelection(this.getColumnNames());
            },
            // updates the storage with the internal state value
        },
        {
            key: 'render',
            value: function render() {
                const _props2 = this.props;
                const { resource } = _props2;
                const { children } = _props2;
                const { defaultColumns } = _props2;
                const rest = _objectWithoutProperties(_props2, [
                    'children',
                    'defaultColumns',
                ]);
                const _state = this.state;
                var { selectionCheckbox } = _state;
                const { modalOpened } = _state;
                const allColumns = { ...this.getColumnLabels() }; // Load allColumns object with actualColumns
                const allColumnsArray = Object.values(allColumns); // Trasform allColumns in array of objects
                // Search if two element of array are equal
                for (let i = 0; i < allColumnsArray.length - 1; i++) {
                    for (let j = i + 1; j < allColumnsArray.length; j++) {
                        if (
                            allColumnsArray[j].source ==
                            allColumnsArray[i].source
                        ) {
                            allColumnsArray[
                                j
                            ].source = `${allColumnsArray[j].source}c`;
                        }
                    }
                }
                var selectionCheckbox = { ...selectionCheckbox }; // Load selectionCheckbox object with actualselectionCheckbox
                const disabled = disableButton(allColumns, selectionCheckbox);
                return _react2.default.createElement(
                    'div',
                    null,
                    _react2.default.createElement(
                        'div',
                        { style: { float: 'right', marginRight: '1rem' } },
                        _react2.default.createElement(
                            _Button2.default,
                            {
                                variant: 'outlined',
                                mini: true,
                                'aria-label': 'add',
                                onClick: this.handleOpen,
                            },
                            _react2.default.createElement(
                                _ViewColumn2.default,
                                null
                            )
                        )
                    ),
                    modalOpened &&
                        _react2.default.createElement(
                            _SelectionDialog2.default,
                            {
                                selectionCheckbox,
                                columns: allColumnsArray,
                                resource,
                                onColumnClicked: this.toggleColumn,
                                onClose: this.handleClose,
                                translate: this.translate,
                                disabled,
                            }
                        ),
                    _react2.default.createElement(
                        _reactAdmin.Datagrid,
                        rest,
                        _react2.default.Children.map(children, this.renderChild)
                    )
                );
            },
        },
    ]);
    return CustomizableDatagrid;
})(_react.Component);
CustomizableDatagrid.propTypes = {
    defaultColumns: _propTypes2.default.arrayOf(_propTypes2.default.string),
    storage: _propTypes2.default.shape({
        get: _propTypes2.default.func.isRequired,
        set: _propTypes2.default.func.isRequired,
    }),
};
CustomizableDatagrid.defaultProps = {
    defaultColumns: [],
    storage: _LocalStorage2.default,
};
export default CustomizableDatagrid;
