import { makeStyles } from '@material-ui/core/styles';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    ArrayInput,
    Create,
    ImageField,
    ImageInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import supportGuideHelpType from '../../data/supportGuideType.json';
import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
    getSeriesVersion,
} from '../../selectors/cmsConfig';
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const SupportGuideHelpCreate = (props) => {
    const classes = useStyles();
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.support-guide-help.name'),
            url: 'support-guide-help',
            icon: <SettingsIcon />,
        },
        {
            text: translation('resources.support-guide-help.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={[
                                { id: 'GENERIC', name: 'GENERIC' },
                                ...applianceTypes,
                            ]}
                            validate={required()}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={brands}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={series}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="seriesVersion"
                            choices={seriesVersion}
                        />
                        <TextInput source="title" />
                        <TextInput source="description" />
                        <CustomSelectInput
                            source="supportGuideHelpType"
                            choices={supportGuideHelpType}
                            validate={required()}
                        />
                    </ContainerFieldsList>
                    <hr className={classes.width} />
                    <ArrayInput source="steps">
                        <SimpleFormIterator>
                            <TextInput source="title" />
                            <ArrayInput source="descriptions">
                                <SimpleFormIterator>
                                    <TextInput source="description" />
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ImageInput
                                source="imagePath"
                                label="Image"
                                accept="image/*"
                                placeholder={
                                    <p>
                                        {translation(
                                            'resources.applianceWakeUp.fields.input_file'
                                        )}
                                    </p>
                                }
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    );
};
export default SupportGuideHelpCreate;
