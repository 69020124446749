import React from 'react';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { CustomBreadcrumbs } from 'candy-commons-components';
import {
    BooleanInput,
    DeleteButton,
    Edit,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    useTranslate,
} from 'react-admin';
import { QueryFormManager } from '../components/QueryFormManager';
import { FORM_TYPE, PROMO } from '../config';
import {
    normalizeFormDataToSend,
    useGetSelectedCriteria,
} from '../hooks/normalizeFormDataToSend';
import { useStyles } from '../styles';
import { ImpressionLogicInput } from './components/ImpressionLogicInput';
const PromoSpacesEdit = ({ permissions = {}, ...props }) => {
    const { id } = props;
    const isEditDisabled = !permissions?.['promo-spaces']?.update;
    const translation = useTranslate();
    const classes = useStyles();
    const [crt, getSelectedCriteriaHandler] = useGetSelectedCriteria();
    const breadcrumbsData = [
        {
            text: translation('resources.promo-spaces.name'),
            url: 'promo-spaces',
            icon: <TimelapseIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const CustomToolbar = (props) => {
        return (
            <Toolbar {...props}>
                <SaveButton
                    redirect="list"
                    transform={(data) => {
                        return normalizeFormDataToSend({ data, crt });
                    }}
                    disabled={isEditDisabled}
                />
                <DeleteButton disabled={isEditDisabled} undoable />
            </Toolbar>
        );
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props} undoable={false}>
                <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
                    <BooleanInput
                        initialValue={true}
                        source="active"
                        label="Active"
                        validate={[required()]}
                        disabled={isEditDisabled}
                    />
                    <TextInput
                        source="slug"
                        label="Slug"
                        validate={required()}
                        disabled={isEditDisabled}
                    />
                    <TextInput
                        source="content.externalCmsStructureId"
                        label="External CMS Structure Id"
                        validate={[required()]}
                        disabled={isEditDisabled}
                    />
                    <TextInput
                        source="content.externalCmsContentId"
                        label="External CMS Content Id"
                        validate={[required()]}
                        disabled={isEditDisabled}
                    />
                    <TextInput
                        source="content.deepLink"
                        label="Hon deeplink"
                        disabled={isEditDisabled}
                    />
                    <ImpressionLogicInput
                        variant={FORM_TYPE.EDIT}
                        queryID={id}
                        isEditDisabled={isEditDisabled}
                    />
                    <QueryFormManager
                        variant={FORM_TYPE.EDIT}
                        promoType={PROMO.SPACE}
                        queryID={id}
                        getActiveCriteria={getSelectedCriteriaHandler}
                        isEditDisabled={isEditDisabled}
                    />
                </SimpleForm>
            </Edit>
        </>
    );
};
export default PromoSpacesEdit;
