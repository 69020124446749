import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import { TextField, useTranslate } from 'react-admin';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import CustomDatagrid from '../../components/organisms/customDatagrid';
const ServiceCommunicationsList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.service-communications.name'),
            url: '',
            icon: <SpeakerNotesIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                exporter={false}
                pagination={false}
                {...props}
                bulkActionButtons={
                    permissions &&
                    permissions['service-communications'] &&
                    permissions['service-communications'].delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="popupId" label="Id" />
                    <TextField source="popupType" label="Type" />
                    <TextField source="active" label="Active" />
                    <TextField source="title" label="Title" />
                    <TextField source="body" label="Body" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default ServiceCommunicationsList;
