import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import RowingIcon from '@material-ui/icons/Rowing';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
    CustomSelectArrayInput,
} from 'candy-commons-components';
import React, { useEffect, useState } from 'react';
import {
    ArrayInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    ImageField,
    NumberInput,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    TextField,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import connectivityOptions from '../../data/connectivityOptions.json';
import connectivityOptionsExtra from '../../data/connectivityOptionsExtra.json';
import { useEditController } from 'react-admin';
import countries from '../../data/countries.json';
import { useSelector } from 'react-redux';
import {
    getBrands,
    getSeries,
    getSeriesVersion,
    getDrawerTypes,
} from '../../selectors/cmsConfig';
const CustomImageField = ({ field, formData, isEditDisabled, ...rest }) => {
    const form = useForm();
    const translation = useTranslate();
    return (
        <>
            <ImageField source={field} label="" {...rest} />
            {!isEditDisabled && (
                <Button
                    color="primary"
                    onClick={() => form.change(`${field}`, null)}
                    {...rest}
                >
                    {translation('resources.hints.button.delete')}
                </Button>
            )}
        </>
    );
};
export const HintEdit = ({ permissions = {}, ...props }) => {
    const classes = useStyles();
    const translation = useTranslate();
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const seriesVersion = useSelector(getSeriesVersion);
    const drawerTypes = useSelector(getDrawerTypes);
    const isEditDisabled = !permissions?.hints?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: translation('resources.hints.name'),
            url: 'hints',
            icon: <RowingIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const [sourceFile, setSourceFile] = useState(null);
    const { record } = useEditController(props);
    useEffect(() => {
        if (record?.sourceFile) setSourceFile(record.sourceFile);
    }, [record]);
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit className={classes.reverseButtonSave} {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                    redirect="list"
                >
                    <ContainerFieldsList>
                        <TextField source="category" />
                        <CustomSelectArrayInput
                            label="Countries"
                            source="countries"
                            choices={countries}
                            parse={(x) => (x.includes('ALL') ? ['ALL'] : x)}
                            disabled={isEditDisabled}
                        />
                        <TextInput source="title" disabled={isEditDisabled} />
                        <TextInput source="body" disabled={isEditDisabled} />
                        <CustomSelectArrayInput
                            label="Brand"
                            source="brand"
                            choices={brands}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            label="Series"
                            source="series"
                            disabled={isEditDisabled}
                            choices={series}
                        />
                        <CustomSelectArrayInput
                            label="Series Version"
                            source="seriesVersion"
                            choices={seriesVersion}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            label="Appliance Model"
                            source="applianceModel"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            source="connectivity"
                            allowEmpty
                            choices={[
                                ...connectivityOptions,
                                ...connectivityOptionsExtra,
                            ]}
                            disabled={isEditDisabled}
                        />
                        <NumberInput
                            label="order"
                            source="sortOrder"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="drawerType"
                            label="Drawer Type"
                            choices={[
                                {
                                    id: '',
                                    name: '-',
                                },
                                ...drawerTypes.filter(
                                    (x) => x.id !== 'FL-CHG-AQUA'
                                ),
                            ]}
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <SimpleShowLayout>
                        <FormDataConsumer>
                            {(formDataProps) =>
                                formDataProps.formData.thumbnailImage && (
                                    <>
                                        <CustomImageField
                                            {...formDataProps}
                                            isEditDisabled={isEditDisabled}
                                            field="thumbnailImage"
                                        />
                                    </>
                                )
                            }
                        </FormDataConsumer>
                    </SimpleShowLayout>
                    {!isEditDisabled && (
                        <>
                            <Typography variant="h6" component="h6">
                                Thumbnail image
                            </Typography>
                            <ImageInput
                                source="thumbnailImage"
                                accept="image/*"
                                placeholder={
                                    <p>
                                        {translation(
                                            'resources.hints.fields.input_file'
                                        )}
                                    </p>
                                }
                            >
                                <ImageField
                                    source="thumbnailImage"
                                    title="title"
                                />
                            </ImageInput>
                        </>
                    )}
                    <BooleanInput
                        label="Multiple cards"
                        source="multipleCards"
                        disabled={isEditDisabled}
                    />
                    <hr className={classes.width} />
                    <Typography variant="h6" component="h6">
                        Image above text
                    </Typography>
                    <CustomSelectInput
                        label="Image above Text"
                        source="sourceFile"
                        choices={[
                            { name: 'Asset already exist', id: 'exist' },
                            { name: 'Upload new asset', id: 'new' },
                        ]}
                        onChange={(e) => setSourceFile(e.target.value)}
                        disabled={isEditDisabled}
                    />
                    <SimpleShowLayout>
                        <FormDataConsumer>
                            {(formDataProps) =>
                                formDataProps.formData.pictureAbove && (
                                    <>
                                        <CustomImageField
                                            {...formDataProps}
                                            isEditDisabled={isEditDisabled}
                                            field="pictureAbove"
                                        />
                                    </>
                                )
                            }
                        </FormDataConsumer>
                    </SimpleShowLayout>
                    {sourceFile === 'exist' && (
                        <>
                            <TextInput
                                label="Asset id"
                                source="assetId"
                                disabled={isEditDisabled}
                                fullWidth
                            />
                        </>
                    )}
                    {sourceFile === 'new' && !isEditDisabled && (
                        <>
                            <ImageInput
                                source="pictureAbove"
                                accept="image/*"
                                placeholder={
                                    <p>
                                        {translation(
                                            'resources.hints.fields.input_file'
                                        )}
                                    </p>
                                }
                            >
                                <ImageField
                                    source="pictureAbove"
                                    title="title"
                                />
                            </ImageInput>
                        </>
                    )}
                    <hr className={classes.width} />
                    <ArrayInput source="steps" disabled={isEditDisabled}>
                        <SimpleFormIterator>
                            <SimpleShowLayout>
                                <ImageField source="imagePath" label="" />
                            </SimpleShowLayout>
                            <TextInput
                                label="title"
                                source="title"
                                validate={required()}
                            />
                            <TextInput
                                label="description"
                                source="description"
                            />
                            <TextInput source="warningText" />
                            <TextInput source="linkVideo" />
                            <ImageInput
                                source="imagePath"
                                label="Image"
                                accept="image/*"
                                placeholder={<p>Drop your file here</p>}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
    reverseButtonSave: {
        '& .MuiToolbar-root>div': {
            flexDirection: 'row-reverse',
        },
    },
});
export default HintEdit;
