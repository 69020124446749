import React from 'react';
import {
    Create,
    SimpleForm,
    useTranslate,
    TextField,
    NumberField,
    SelectInput,
    TextInput,
    NumberInput,
} from 'react-admin';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectInput,
} from 'candy-commons-components';
import COUNTRIES from '../../data/countries.json';
const AppBundleUpdateCreate = (props) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.app-bundle-update.name'),
            url: 'app-bundle-update',
        },
        {
            text: translation('resources.app-bundle-update.create.name'),
            url: '',
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Create {...props}>
                <SimpleForm>
                    <ContainerFieldsList>
                        <TextInput
                            source="version"
                            label="Version"
                            placeholder="2.9.0.1.1"
                        />
                        <SelectInput
                            // allowEmpty
                            source="country"
                            choices={COUNTRIES.filter((f) => f.id !== 'all')}
                        />
                        <NumberInput source="maxDownloads" />
                    </ContainerFieldsList>
                </SimpleForm>
            </Create>
        </>
    );
};
export default AppBundleUpdateCreate;
