import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TranslateIcon from '@material-ui/icons/Translate';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
} from 'candy-commons-components';
import { TextField, useTranslate } from 'react-admin';
import CustomDataGrid from '../../components/organisms/customDatagrid';
const TransaltionFilter = (props) => <CustomFilter {...props}></CustomFilter>;
const TransaltionList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const breadcrumbsData = [
        {
            text: translate('resources.translation.name'),
            url: '',
            icon: <TranslateIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                exporter={false}
                bulkActionButtons={
                    permissions &&
                    permissions.translation &&
                    permissions.translation.delete
                }
            >
                <CustomDataGrid
                    choiceColumns
                    defaultColumns={['code', 'counter']}
                    rowClick="show"
                    className={classes.grid}
                    selectable
                >
                    <TextField source="code" />
                    <TextField source="counter" label={'Missing counter'} />
                </CustomDataGrid>
            </CustomList>
        </>
    );
};
const useStyles = makeStyles({
    grid: {
        wordBreak: 'break-word',
        '& .MuiButton-label': {
            display: 'contents',
        },
        '& tr td:nth-child(2)': {
            '& span': {
                maxWidth: '500px',
            },
        },
        '& span': {
            display: 'block',
            overflow: 'hidden',
            maxHeight: '40px',
            maxWidth: '310px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
});
export default TransaltionList;
