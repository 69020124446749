import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import ImageIcon from '@material-ui/icons/Image';
import IconContentPublish from '@material-ui/icons/Publish';
import axios from 'axios';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
} from 'candy-commons-components';
import React, { useEffect, useState } from 'react';
import {
    CreateButton,
    TextField,
    TextInput,
    useNotify,
    useTranslate,
} from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import config from '../../config';
const { basePath: apiPath } = config;
const AssetFilter = (props) => (
    <CustomFilter {...props}>
        <TextInput label="resources.asset.fields.search" source="q" alwaysOn />
    </CustomFilter>
);
const AssetListAction = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions,
}) => {
    const notify = useNotify();
    const translation = useTranslate();
    const [currentVersion, setCurrentVersion] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const username = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
    );
    const token = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`
    );
    useEffect(() => {
        axios
            .get(`${apiPath}/asset-version`, {
                params: {},
                headers: { Authorization: token },
            })
            .then((response) => {
                if (response && response.data && response.data.version) {
                    setCurrentVersion(response.data.version);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClick = () => {
        setLoading(true);
        axios
            .get(`${apiPath}/asset-publish`, {
                params: {},
                headers: { Authorization: token },
            })
            .then((response) => {
                if (response && response.data && response.data.version) {
                    setCurrentVersion(response.data.version);
                    notify(`version ${response.data.version} published`);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    return (
        <Toolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <span>
                {translation('resources.asset.action.current_version')}{' '}
                {currentVersion}{' '}
            </span>
            {permissions && permissions.asset && permissions.asset.update && (
                <Button
                    color="primary"
                    label="resources.asset.action.publish_in_app"
                    onClick={handleClick}
                >
                    {isLoading ? (
                        <CircularProgress size={25} thickness={2} />
                    ) : (
                        <IconContentPublish />
                    )}
                    <span>
                        {translation('resources.asset.action.publish_in_app')}
                    </span>
                </Button>
            )}
            {permissions && permissions.asset && permissions.asset.create && (
                <CreateButton basePath={basePath} />
            )}
        </Toolbar>
    );
};
const AssetList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.asset.name'),
            url: '',
            icon: <ImageIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={25}
                pagination={<DynamoPagination resource="asset" />}
                filters={<AssetFilter />}
                actions={<AssetListAction permissions={permissions} />}
                bulkActionButtons={
                    permissions && permissions.asset && permissions.asset.delete
                }
            >
                <CustomDatagrid rowClick="edit" selectable>
                    <TextField source="code" />
                    <TextField source="assetName" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default AssetList;
