import * as xlsx from 'xlsx';
import config from '../config';
import axios from 'axios';

export const validImageFormats = ['image/jpeg', 'image/png', 'image/svg+xml'];
export const MAX_IMAGE_KB = 800;

const { basePath } = config;
const alphaToNum = (alpha) => {
    let num = 0;
    for (let i = 0; i < alpha.length; i++) {
        num = num * 26 + alpha.charCodeAt(i) - 0x40;
    }
    return num - 1;
};
const numToAlpha = (num) => {
    let alpha = '';
    for (; num >= 0; num = parseInt(num / 26, 10) - 1) {
        alpha = String.fromCharCode((num % 26) + 0x41) + alpha;
    }
    return alpha;
};
const getColumns = (range) => {
    const res = [];
    const rangeNum = range.split(':').map(function (val) {
        return alphaToNum(val.replace(/[0-9]/g, ''));
    });
    for (let i = rangeNum[0]; i < rangeNum[1] + 1; i++) {
        res.push(numToAlpha(i));
    }
    return res;
};
const isEnglish = (lang) => lang === 'en';
export const checkFileErrors = (file) => {
    const workbook = xlsx.read(file, {
        type: 'binary',
    });
    const sheetName = workbook.SheetNames[0];
    const sheetData = workbook.Sheets[sheetName];
    const columns = getColumns(sheetData['!ref']);
    const pattern = /^[A-Z]\d+$/; //*Pattern per matchare le chiavi del tipo "A1"
    const maxRow = Number(
        Object.keys(sheetData)
            .filter(
                (key) =>
                    key != 'A1' &&
                    key != 'B1' &&
                    pattern.test(key) &&
                    key.includes('A')
            )
            .reduce((acc, curr) => {
                const current = Number(curr.substring(1));
                const max = Number(acc.substring(1));
                return current > max ? curr : acc;
            }, 'A1')
            .substring(1)
    );
    const newCodes = [];
    const errors = [];
    for (let i = 2; i <= maxRow; i++) {
        const code = sheetData[`A${i}`]?.v;
        if (!code) break;
        if (newCodes.includes(code)) {
            errors.push(`The code ${code} at row ${i} is duplicated`);
        } else if (!code.match(/^[A-Z0-9\._&#-:]+(?!\n)$/g)) {
            errors.push(`The code ${code} at row ${i} contains invalid chars`);
        } else {
            newCodes.push(code);
        }
        for (let j = 1; j < columns.length; j++) {
            const lang = sheetData[`${columns[j]}${1}`]?.v;
            let value = sheetData[`${columns[j]}${i}`]?.v;
            if (isEnglish(lang) && !value) {
                errors.push(
                    `The english translation for the code ${code} at row ${i} is missing`
                );
            }
            if (value) {
                if (typeof value !== 'string') {
                    value = String(value);
                }
                if (value.match(/^\d+$/g)) {
                    errors.push(
                        `The translation for the code ${code} at row ${i} contains only digits`
                    );
                } else if (
                    !!value.match(/\r/g) ||
                    !!value.match(/^\n+|\n+$/g) ||
                    !!value.match(/\n{3,}/g)
                ) {
                    errors.push(
                        `The translation for the code ${code} at row ${i} contains invalid characters`
                    );
                }
            }
        }
    }
    return [...new Set(errors)];
};
export const checkLanguageErrors = async (file) => {
    const workbook = xlsx.read(file, {
        type: 'binary',
    });
    const sheetData = workbook.Sheets[workbook.SheetNames[0]];
    const headerValues = [];
    let column = 'B';
    while (sheetData[column + '1']) {
        const cell = sheetData[column + '1'];
        headerValues.push(cell.v);
        column = String.fromCharCode(column.charCodeAt(0) + 1);
    }
    const username = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
    );
    const token = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`
    );
    const acceptedLanguage = async () => {
        try {
            const { data } = await axios.get(`${basePath}/language`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return data;
        } catch (err) {
            console.log(err);
            return [];
        }
    };
    const supportedLanguages = await acceptedLanguage();
    if (supportedLanguages.length > 0) {
        const supportedLanguagesCodes = supportedLanguages.map(
            (item) => item.code
        );
        const errors = headerValues.filter(
            (country) => !supportedLanguagesCodes.includes(country)
        );
        if (errors.length) {
            return errors;
        }
    }
    return null;
};

export const checkImageFile = async (file) => {
    const MAX_SIZE = MAX_IMAGE_KB * 1024; // 800kb
    const MAX_DPI = 72;
    const MAX_WIDTH = 1920;
    const image = new Image();
    return new Promise((resolve) => {
        if (!file) {
            return resolve('File is missing');
        }
        if (file.size > MAX_SIZE) {
            return resolve('File size exceeds 800 KB');
        }

        if (!validImageFormats.includes(file.type)) {
            return resolve(
                'Invalid file format. Only jpg, svg, and png are allowed'
            );
        }

        image.onload = () => {
            const dpi = Math.round((image.width / file.size) * 25.4);
            if (dpi > MAX_DPI) {
                return resolve('Image resolution exceeds 72dpi');
            }

            if (
                ['image/jpeg', 'image/png'].includes(file.type) &&
                Math.max(image.width, image.height) > MAX_WIDTH
            ) {
                return resolve(
                    'Image dimensions exceed 1920px on the longest side'
                );
            }

            return resolve(null); // Nessun errore
        };

        image.onerror = () => {
            resolve('Error reading image file');
        };

        image.src = URL.createObjectURL(file);
    });
};
