import config from '../config';
export function getUsername() {
    return localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
    );
}
export function getToken() {
    return localStorage.getItem(
        `CognitoIdentityServiceProvider.${
            config.userPoolWebClientId
        }.${getUsername()}.idToken`
    );
}
