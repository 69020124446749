import { makeStyles } from '@material-ui/core/styles';
import TranslateIcon from '@material-ui/icons/Translate';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React from 'react';
import { TextField, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDataGrid from '../../components/organisms/customDatagrid';
import { getRestrictedLanguages } from '../../libs/utils';
import TranslationFilter from '../../components/TranslationFilter';
const TransaltionList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const breadcrumbsData = [
        {
            text: translate('resources.translation.name'),
            url: '',
            icon: <TranslateIcon />,
        },
    ];
    let restrictedLanguages = [];
    let defaultLangColumn = 'lang_it';
    if (
        permissions &&
        permissions.translation &&
        permissions.translation.restrictLanguages
    ) {
        restrictedLanguages = getRestrictedLanguages({ permissions });
        if (restrictedLanguages.length) {
            defaultLangColumn = `lang_${restrictedLanguages[0]}`;
        }
    }
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                {...props}
                exporter={false}
                pagination={<DynamoPagination resource="translation" />}
                filters={<TranslationFilter />}
                bulkActionButtons={
                    permissions &&
                    permissions.translation &&
                    permissions.translation.delete &&
                    (!permissions.translation.restrictLanguages ||
                        permissions.translation.en)
                }
            >
                <CustomDataGrid
                    choiceColumns
                    defaultColumns={['code', 'lang_en', defaultLangColumn]}
                    rowClick="show"
                    className={classes.grid}
                    selectable
                >
                    <TextField source="code" />
                    <TextField source="lang_en" />
                    {[
                        'it',
                        'cs',
                        'de',
                        'el',
                        'es',
                        'fr',
                        'hr',
                        'hu',
                        'nl',
                        'nr',
                        'pl',
                        'pt',
                        'ro',
                        'ru',
                        'sk',
                        'sl',
                        'sr',
                        'tr',
                        'zh',
                    ]
                        .map((lang) => {
                            if (
                                permissions &&
                                permissions.translation &&
                                (!permissions.translation.restrictLanguages ||
                                    permissions.translation[lang])
                            ) {
                                return <TextField source={`lang_${lang}`} />;
                            }
                        })
                        .filter(Boolean)}
                </CustomDataGrid>
            </CustomList>
        </>
    );
};
const useStyles = makeStyles({
    grid: {
        wordBreak: 'break-word',
        '& .MuiButton-label': {
            display: 'contents',
        },
        '& tr td:nth-child(2)': {
            '& span': {
                maxWidth: '500px',
            },
        },
        '& span': {
            display: 'block',
            overflow: 'hidden',
            maxHeight: '40px',
            maxWidth: '310px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
});
export default TransaltionList;
