import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import React, { useCallback, useMemo } from 'react';
import {
    FunctionField,
    ArrayField,
    TextField,
    useTranslate,
    NumberField,
    DateField,
} from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';

const DiscoverList = ({ permissions, ...props }) => {
    const classes = useStyles();
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.discover.name'),
            url: '',
            icon: <PageviewIcon />,
        },
    ];
    const renderCountries = useCallback(
        (item) =>
            Array.isArray(item.countries)
                ? item.countries.join(',')
                : item.countries || '',
        []
    );
    const renderApplianceTypes = useCallback(
        (item) =>
            Array.isArray(item.applianceType)
                ? item.applianceType.join(',')
                : item.applianceType || '',
        []
    );
    const renderBrands = useCallback(
        (item) =>
            Array.isArray(item.brand) ? item.brand.join(',') : item.brand || '',
        []
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                filters={<CommonApplianceFilter applianceType brand />}
                perPage={25}
                exporter={false}
                {...props}
                bulkActionButtons={
                    permissions &&
                    permissions.assistance &&
                    permissions.assistance.delete
                }
                pagination={<DynamoPagination resource="maintenance" />}
            >
                <CustomDatagrid selectable rowClick="edit">
                    <NumberField label="Order" source="order" />
                    <TextField source="title" />
                    <FunctionField
                        label="Appliance type"
                        render={renderApplianceTypes}
                        source="applianceType"
                    />
                    <FunctionField
                        label="Countries"
                        render={renderCountries}
                        source="countries"
                    />
                    <FunctionField
                        label="Brand"
                        render={renderBrands}
                        source="brand"
                    />
                    <TextField source="category" />
                    <TextField source="description" />
                    <DateField label="Valid from" source="from" />
                    <DateField label="Valid to" source="to" />
                    <TextField source="link" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
const useStyles = makeStyles({});
export default DiscoverList;
