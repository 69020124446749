export const FETCH_CMS_CONFIG_REQUEST = 'FETCH_CMS_CONFIG_REQUEST';
export const FETCH_CMS_CONFIG_SUCCESS = 'FETCH_CMS_CONFIG_SUCCESS';
export const FETCH_CMS_CONFIG_FAILURE = 'FETCH_CMS_CONFIG_FAILURE';

export const fetchCmsConfigRequest = () => ({
    type: FETCH_CMS_CONFIG_REQUEST,
});

export const fetchCmsConfigSuccess = (config) => ({
    type: FETCH_CMS_CONFIG_SUCCESS,
    payload: config,
});

export const fetchCmsConfigFailure = (error) => ({
    type: FETCH_CMS_CONFIG_FAILURE,
    payload: error,
});
