import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
const useFetchSelectValues = (props) => {
    const dataProvider = useDataProvider();
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        dataProvider
            .getList('app-configuration', {
                pagination: { page: 1, perPage: 100 }, // Imposta i parametri per la paginazione se necessario
                sort: { field: 'name', order: 'ASC' }, // Ordinamento opzionale
                filter: { type: 'CMS', key: 'countries' }, // Filtri opzionali
            })
            .then(({ data }) => {
                console.log('app-configuration');
                // Mappa i risultati nel formato adatto per il SelectInput
                const countries = data;
                setCountries(countries);
            })
            .catch((error) => {
                console.error('Errore nel caricamento delle categorie:', error);
            });
    }, [dataProvider]);

    return { countries };
};

export default useFetchSelectValues;
