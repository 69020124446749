export const showListData = [
    {
        label: 'resources.guided-washing.fields.cycleType',
        type: 'textField',
        source: 'cycleType',
    },
    {
        label: 'resources.guided-washing.fields.cluster',
        type: 'textField',
        source: 'cluster',
    },
    {
        label: 'resources.guided-washing.fields.soil',
        type: 'numberField',
        source: 'soil',
    },
    {
        label: 'resources.guided-washing.fields.color',
        type: 'textField',
        source: 'color',
    },
    {
        label: 'resources.guided-washing.fields.dry',
        type: 'textField',
        source: 'dry',
    },
    {
        label: 'resources.guided-washing.fields.steam',
        type: 'booleanField',
        source: 'steam',
    },
];
