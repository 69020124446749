import AssistantIcon from '@material-ui/icons/Assistant';
import {
    CustomBreadcrumbs,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React from 'react';
import { TextField, TextInput, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';
import countries from '../../data/countries.json';
const AssistanceList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.assistance.name'),
            url: '',
            icon: <AssistantIcon />,
        },
    ];
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                exporter={false}
                perPage={25}
                {...props}
                pagination={<DynamoPagination resource="assistance" />}
                filters={
                    <CommonApplianceFilter
                        applianceType
                        brand
                        optionalFilters={[
                            <CustomSelectInput
                                label="Country"
                                source="countryCode"
                                alwaysOn
                                choices={countries}
                            />,
                        ]}
                    />
                }
                bulkActionButtons={
                    permissions &&
                    permissions.assistance &&
                    permissions.assistance.delete
                }
            >
                <CustomDatagrid selectable rowClick="edit">
                    <TextField source="applianceType" />

                    <TextField source="brand" />
                    <TextField source="countryCode" />

                    <TextField source="isMultipleRecord" label="Multi Record" />
                    {/*<TextField source="country" />*/}
                    {/*<TextField source="phone" />*/}
                    {/*<TextField source="link" />*/}
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default AssistanceList;
