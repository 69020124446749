export default [
    {
        id: 'custom',
        name: 'Custom',
    },
    {
        id: 'draw',
        name: 'Draw',
    },
    {
        id: 'feedback',
        name: 'Feedback',
    },
    {
        id: 'activeFeedback',
        name: 'Active Feedback',
    },
    {
        id: 'url',
        name: 'URL',
    },
    {
        id: 'wpExpirationDate',
        name: 'wpExpirationDate',
    },
    {
        id: 'demoExclusionsLanguage',
        name: 'Demo Enable Language',
    },
    {
        id: 'appOperation',
        name: 'App Operation',
    },
    {
        id: 'whatsapp-support',
        name: 'Whatsapp Support',
    },
    {
        id: 'jsonMatrix',
        name: 'JSON Matrix',
    },
    {
        id: 'shopping',
        name: 'Shopping',
    },
    {
        id: 'app-update',
        name: 'App Update',
    },
    {
        id: 'warranty',
        name: 'Warranty',
    },
    {
        id: 'InventoryBarcodeScanning',
        name: 'Inventory Barcode Scanning',
    },
    {
        id: 'SmartInventoryBarcodeScanning',
        name: 'SmartInventory Barcode Scanning',
    },
];
