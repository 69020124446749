import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TranslateIcon from '@material-ui/icons/Translate';
import { CustomBreadcrumbs, CustomList } from 'candy-commons-components';
import { TextField, useTranslate } from 'react-admin';
import DynamoPagination from '../../components/DynamoPagination';
import TranslationFilter from '../../components/TranslationFilter';
import CustomDataGrid from '../../components/organisms/customDatagrid';
import { getRestrictedLanguages } from '../../libs/utils';
const TransaltionList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const breadcrumbsData = [
        {
            text: translate('resources.translation.name'),
            url: '',
            icon: <TranslateIcon />,
        },
    ];
    let restrictedLanguages = [];
    let defaultLangColumn = 'lang_it';
    if (
        permissions &&
        permissions.translation &&
        permissions.translation.restrictLanguages
    ) {
        restrictedLanguages = getRestrictedLanguages({ permissions });
        if (restrictedLanguages.length) {
            defaultLangColumn = `lang_${restrictedLanguages[0]}`;
        }
    }
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                perPage={25}
                {...props}
                exporter={false}
                pagination={<DynamoPagination resource="missing-translation" />}
                filters={<TranslationFilter />}
                bulkActionButtons={
                    permissions &&
                    permissions.translation &&
                    permissions.translation.delete &&
                    (!permissions.translation.restrictLanguages ||
                        permissions.translation.en)
                }
            >
                <CustomDataGrid
                    choiceColumns
                    defaultColumns={['code', 'lang_en', defaultLangColumn]}
                    rowClick="show"
                    className={classes.grid}
                    selectable
                >
                    <TextField source="code" />
                    <TextField source="lang_en" />
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.it) && (
                            <TextField source="lang_it" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.cs) && (
                            <TextField source="lang_cs" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.de) && (
                            <TextField source="lang_de" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.el) && (
                            <TextField source="lang_el" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.es) && (
                            <TextField source="lang_es" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.fr) && (
                            <TextField source="lang_fr" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.hr) && (
                            <TextField source="lang_hr" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.nl) && (
                            <TextField source="lang_nl" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.nr) && (
                            <TextField source="lang_nr" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.pl) && (
                            <TextField source="lang_pl" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.pt) && (
                            <TextField source="lang_pt" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.ro) && (
                            <TextField source="lang_ro" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.ru) && (
                            <TextField source="lang_ru" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.sk) && (
                            <TextField source="lang_sk" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.sl) && (
                            <TextField source="lang_sl" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.sr) && (
                            <TextField source="lang_sr" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.tr) && (
                            <TextField source="lang_tr" />
                        )}
                    {permissions &&
                        permissions.translation &&
                        (!permissions.translation.restrictLanguages ||
                            permissions.translation.zh) && (
                            <TextField source="lang_zh" />
                        )}
                </CustomDataGrid>
            </CustomList>
        </>
    );
};
const useStyles = makeStyles({
    grid: {
        wordBreak: 'break-word',
        '& .MuiButton-label': {
            display: 'contents',
        },
        '& tr td:nth-child(2)': {
            '& span': {
                maxWidth: '500px',
            },
        },
        '& span': {
            display: 'block',
            overflow: 'hidden',
            maxHeight: '40px',
            maxWidth: '310px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
});
export default TransaltionList;
