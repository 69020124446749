import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useState } from 'react';
import {
    BooleanInput,
    DateInput,
    Edit,
    FormDataConsumer,
    SimpleForm,
    TextInput,
    required,
    useTranslate,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import countries from '../../data/countries.json';
import l from '../../data/languages.json';
import popupTypes from '../../data/serviceCommunications.popupTypes.json';

import { useSelector } from 'react-redux';
import {
    getApplianceTypes,
    getBrands,
    getSeries,
} from '../../selectors/cmsConfig';
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});
const ServiceCommunicationsEdit = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const classes = useStyles();
    const isEditDisabled = !permissions?.['service-communications']?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: translation('resources.service-communications.name'),
            url: 'service-communications',
            icon: <SpeakerNotesIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];
    const [allApplianceFlag, setAllApplianceFlag] = useState(false);
    const applianceTypes = useSelector(getApplianceTypes);
    const brands = useSelector(getBrands);
    const series = useSelector(getSeries);
    const handleAllAppliance = (e) => setAllApplianceFlag(e?.target?.checked);

    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <Typography style={{ paddingTop: 10 }}>
                        {translation(
                            'resources.service-communications.titles.settings'
                        )}
                    </Typography>
                    <ContainerFieldsList>
                        <TextInput
                            source="popupId"
                            label="Popup Id"
                            validate={required()}
                            disabled
                        />
                        <CustomSelectInput
                            source="popupType"
                            label="Popup Type"
                            choices={popupTypes}
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <BooleanInput
                            source="active"
                            label="active"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                    <hr className={classes.width} />
                    <Typography style={{ paddingTop: 10 }}>
                        {translation(
                            'resources.service-communications.titles.content'
                        )}
                    </Typography>
                    <TextInput
                        source="title"
                        label="Title"
                        fullWidth
                        validate={required()}
                        disabled={isEditDisabled}
                    />
                    <TextInput
                        source="body"
                        label="Body Content"
                        fullWidth
                        multiline
                        validate={required()}
                        disabled={isEditDisabled}
                    />
                    <hr className={classes.width} />
                    <Typography style={{ paddingTop: 10 }}>
                        {translation(
                            'resources.service-communications.titles.criteria'
                        )}
                    </Typography>
                    <ContainerFieldsList>
                        <FormDataConsumer>
                            {() => (
                                <>
                                    {!allApplianceFlag && (
                                        <CustomSelectArrayInput
                                            label="Appliance types"
                                            source="applianceType"
                                            choices={applianceTypes}
                                            disabled={
                                                allApplianceFlag ||
                                                isEditDisabled
                                            }
                                        />
                                    )}
                                    <BooleanInput
                                        source="AllAppliance"
                                        onClick={handleAllAppliance}
                                        disabled={isEditDisabled}
                                    />
                                </>
                            )}
                        </FormDataConsumer>
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="series"
                            label="Series"
                            choices={series}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            label="Series Version"
                            source="seriesVersion"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="brand"
                            label="Brand"
                            choices={brands}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            source="language"
                            label="App Language"
                            choices={l}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="minAppVersion"
                            label="Minimum App Version"
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="maxAppVersion"
                            label="Maximum App Version"
                            disabled={isEditDisabled}
                        />
                        <DateInput
                            source="startTime"
                            label="Start Time"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <DateInput
                            source="endTime"
                            label="End Time"
                            validate={required()}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            label="countries"
                            source="countries"
                            choices={countries}
                            disabled={isEditDisabled}
                        />
                        <BooleanInput
                            source="countriesToExclude"
                            label="Are countries to exclude?"
                            disabled={isEditDisabled}
                        />
                    </ContainerFieldsList>
                </SimpleForm>
            </Edit>
        </>
    );
};
export default ServiceCommunicationsEdit;
