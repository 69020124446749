const mainSelector = (state) => state?.custom?.config;

export const getApplianceTypes = (state) =>
    mainSelector(state)?.applianceType || [];
export const getBrands = (state) => mainSelector(state)?.brand || [];
export const getDrawerTypes = (state) => mainSelector(state)?.drawerType || [];

export const getMaintenanceInstructionsCategories = (state) =>
    mainSelector(state)?.maintenanceInstructionsCategories || [];
export const getSeries = (state) => mainSelector(state)?.series || [];
export const getSeriesVersion = (state) =>
    mainSelector(state)?.seriesVersion || [];
