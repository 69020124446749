import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import MarkdownIt from 'markdown-it';
import PropTypes from 'prop-types';
import { translate, useTranslate } from 'ra-core';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-admin';
import MdEditor from 'react-markdown-editor-lite';
import compose from 'recompose/compose';
import { Field, propTypes, reduxForm } from 'redux-form';
import { Box } from '@material-ui/core';
const mdParser = new MarkdownIt();
const validate = (values, props) => {
    const errors = {};
    const { translate } = props;
    if (!values.language) errors.language = translate('ra.validation.required');
    if (!values.translation) {
        errors.translation = translate('ra.validation.required');
    }
    return errors;
};
const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return null;
    }
    return <FormHelperText>{touched && error}</FormHelperText>;
};
const renderMdE = ({
    meta: { touched, error } = {}, // eslint-disable-line react/prop-types
    input: { ...inputProps }, // eslint-disable-line react/prop-types
    value,
    ...props
}) => {
    const handleChange = (event) => {
        inputProps.onChange(event.text);
    };
    return (
        <MdEditor
            value={inputProps.value || ''}
            onChange={handleChange}
            renderHTML={(text) => mdParser.render(text)}
            config={{
                view: {
                    menu: true,
                    md: true,
                    fullScreen: false,
                    bold: false,
                },
            }}
        />
    );
};
// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {}, // eslint-disable-line react/prop-types
    input: { ...inputProps }, // eslint-disable-line react/prop-types
    value,
    ...props
}) => (
    <TextField
        error={Boolean(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);
const renderSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    id,
    name,
    ...custom
}) => (
    <FormControl fullWidth error={touched && error}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select
            // native
            {...input}
            {...custom}
            inputProps={{
                name,
                id,
            }}
        >
            {children}
        </Select>
        {renderFromHelper({ touched, error })}
    </FormControl>
);
const GetTranslation = (props) => {
    const [state, setState] = useState({
        translation: '',
    });
    const { langs, id, label } = props;
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'translation',
        payload: { id },
    });
    const handleChange = (event) => {
        setState({
            translation:
                data &&
                data.languages &&
                data.languages.find((x) => x.language == event.target.value)
                    .translation,
        });
    };
    useEffect(() => {
        setState({
            translation:
                data &&
                data.languages &&
                data.languages.find((x) => x.language == 'en').translation,
        });
    }, [data]);
    if (!data) return null;
    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select onChange={handleChange} defaultValue="en">
                {langs &&
                    langs.length &&
                    data &&
                    data.languages &&
                    data.languages.length &&
                    data.languages.map(({ language }) => (
                        <MenuItem key={language} value={language}>
                            {langs.find((x) => x.id === language)
                                ? langs.find((x) => x.id === language).name
                                : language}
                        </MenuItem>
                    ))}
            </Select>
            <TextField
                defaultValue={state.translation}
                InputProps={{
                    readOnly: true,
                }}
                multiline
            />
        </FormControl>
    );
};
export const MissingTranslationForm = ({
    isLoading,
    handleSubmit,
    translate,
    saveAction,
    handleCloseClick,
    allLanguages,
    availableLanguages,
    initialValues,
    edit,
    id,
}) => {
    const translation = useTranslate();
    const classes = useStyles();
    const currentLanguage = allLanguages.filter(
        (x) => x.id === initialValues.language
    );
    return (
        <form onSubmit={handleSubmit(saveAction)}>
            <DialogContent>
                <Box className={classes.dialogContainer}>
                    <Box className={classes.leftColumn}>
                        <GetTranslation
                            langs={allLanguages}
                            id={id}
                            label={translation(
                                'resources.translation.translation_form.fields.check_other_language'
                            )}
                        />
                    </Box>
                    <Box className={classes.rightColumn}>
                        {edit ? (
                            <TextField
                                label={currentLanguage[0].name}
                                className={classes.selectCurrentLanguage}
                                inputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                disabled
                            />
                        ) : (
                            <Field
                                id="language"
                                name="language"
                                component={renderSelect}
                                label={translation(
                                    'resources.translation.translation_form.fields.add_language'
                                )}
                                disabled={isLoading}
                            >
                                {availableLanguages.map(({ id, name }) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Field>
                        )}
                        {edit || availableLanguages.length ? (
                            <Box className={classes.editorContainer}>
                                <Field
                                    id="translation"
                                    name="translation"
                                    component={renderMdE}
                                    label="Translation"
                                    disabled={isLoading}
                                    multiline
                                />
                            </Box>
                        ) : (
                            ''
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    startIcon={
                        isLoading ? (
                            <CircularProgress size={25} thickness={2} />
                        ) : (
                            <IconSave />
                        )
                    }
                >
                    {`${translate('ra.action.save')} `}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCloseClick}
                    startIcon={<IconCancel />}
                >
                    {`${translate('ra.action.cancel')} `}
                </Button>
            </DialogActions>
        </form>
    );
};
MissingTranslationForm.propTypes = {
    ...propTypes,
    redirectTo: PropTypes.string,
};
const enhance = compose(
    translate,
    reduxForm({
        form: 'languageTranslation',
        validate,
    })
);
const useStyles = makeStyles({
    dialogContainer: {
        display: 'flex',
    },
    leftColumn: {
        flexGrow: 1,
        flexBasis: 0,
        marginRight: 10,
    },
    rightColumn: {
        flexGrow: 2,
        flexBasis: 0,
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    selectCurrentLanguage: {
        marginBottom: 10,
    },
    editorContainer: {
        flexGrow: 1,
        minHeight: 400,
        display: 'flex',
        flexDirection: 'column',
        '& .rc-md-editor': {
            marginTop: 10,
            flexGrow: 1,
        },
        '& .button-wrap': {
            '& .button-type-header': {
                display: 'none',
            },
            '& .button-type-unordered': {
                display: 'none',
            },
            '& .button-type-ordered': {
                display: 'none',
            },
            '& .button-type-quote': {
                display: 'none',
            },
            '& .button-type-hr': {
                display: 'none',
            },
            '& .button-type-inlinecode': {
                display: 'none',
            },
            '& .button-type-code': {
                display: 'none',
            },
            '& .button-type-table': {
                display: 'none',
            },
            '& .button-type-clear': {
                display: 'none',
            },
            '& .button-type-undo': {
                display: 'none',
            },
            '& .button-type-redo': {
                display: 'none',
            },
        },
    },
    td: {
        verticalAlign: 'text-top',
        width: '40%',
    },
});
export default enhance(MissingTranslationForm);
