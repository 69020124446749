import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    ContainerFieldsList,
    FieldsGenerator,
    CustomBreadcrumbs,
} from 'candy-commons-components';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React, { useState, cloneElement } from 'react';
import {
    ArrayInput,
    Create,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    BooleanInput,
    FormDataConsumer,
    useCreateController,
    required,
    useQueryWithStore,
} from 'react-admin';
import { adminPortalSections } from './mapping';
import _ from 'lodash';
const rolesSection = require('./rolesSection.json');
const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
    formIterator: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: '8px',
        paddingBottom: '0px',
    },
});
const RoleCreate = ({ permissions, languages, ...props }) => {
    const classes = useStyles();
    const breadcrumbsData = [
        {
            text: 'Role',
            url: 'role',
            icon: <PersonAddIcon />,
        },
        {
            url: '',
        },
    ];
    const MyCreate = (props) => {
        const {
            basePath, // deduced from the location, useful for action buttons
            defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
            record, // empty object, unless some values were passed in the location state to prefill the form
            redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
            resource, // the resource name, deduced from the location. e.g. 'posts'
            save, // the create callback, to be passed to the underlying form as submit handler
            saving, // boolean that becomes true when the dataProvider is called to create the record
            version, // integer used by the refresh feature
        } = useCreateController(props);
        const permissions = [
            ...(record && record.permissions ? record.permissions : []),
            ...rolesSection.map((x) => ({
                section: x,
                create: false,
                read: false,
                update: false,
                delete: false,
            })),
        ].map((el) => ({
            ...el,
            adminPortal:
                adminPortalSections.indexOf(el.section) >= 0 ? true : false,
        }));
        return (
            <div>
                {cloneElement(props.children, {
                    basePath,
                    record: {
                        'all-create': false,
                        'all-read': false,
                        'all-update': false,
                        'all-delete': false,
                        ...record,
                        permissions: _.orderBy(
                            permissions,
                            [(c) => c.adminPortal, (c) => c.section],
                            ['asc', 'asc']
                        ),
                        ...props.currentRecord,
                    },
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })}
            </div>
        );
    };
    const [currentRecord, setCurrentRecord] = useState({
        'all-create': false,
        'all-read': false,
        'all-update': false,
        'all-delete': false,
    });
    const updateAllRecords = ({ formData, action }) => {
        setCurrentRecord({
            permissions: formData.permissions.map((x) => ({
                ...x,
                [action]: !currentRecord[`all-${action}`],
            })),
            'all-create': currentRecord['all-create'],
            'all-read': currentRecord['all-read'],
            'all-update': currentRecord['all-update'],
            'all-delete': currentRecord['all-delete'],
            [`all-${action}`]: !currentRecord[`all-${action}`],
            description: formData.description,
            name: formData.name,
        });
    };
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <MyCreate currentRecord={currentRecord} {...props}>
                <SimpleForm>
                    <TextInput source="name" validate={[required()]} />
                    <TextInput multiline source="description" />
                    <hr className={classes.width} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <div className={classes.formIterator}>
                                <span style={{ width: 250, marginLeft: 50 }}>
                                    set all
                                </span>
                                <BooleanInput
                                    source="all-create"
                                    label="create"
                                    style={styles.boolean}
                                    onClick={() =>
                                        updateAllRecords({
                                            formData,
                                            action: 'create',
                                        })
                                    }
                                />
                                <BooleanInput
                                    source="all-read"
                                    label="read"
                                    style={styles.boolean}
                                    onClick={() =>
                                        updateAllRecords({
                                            formData,
                                            action: 'read',
                                        })
                                    }
                                />
                                <BooleanInput
                                    source="all-update"
                                    label="update"
                                    style={styles.boolean}
                                    onClick={() =>
                                        updateAllRecords({
                                            formData,
                                            action: 'update',
                                        })
                                    }
                                />
                                <BooleanInput
                                    source="all-delete"
                                    label="delete"
                                    style={styles.boolean}
                                    onClick={() =>
                                        updateAllRecords({
                                            formData,
                                            action: 'delete',
                                        })
                                    }
                                />
                            </div>
                        )}
                    </FormDataConsumer>
                    <ArrayInput addLabel={false} label="" source="permissions">
                        <SimpleFormIterator disableAdd disableRemove>
                            <FormDataConsumer>
                                {({
                                    formData, // The whole form data
                                    scopedFormData, // The data for this item of the ArrayInput
                                    getSource, // A function to get the valid source inside an ArrayInput
                                    ...rest
                                }) => (
                                    <div>
                                        <div className={classes.formIterator}>
                                            <span style={{ width: 250 }}>
                                                {scopedFormData.section}
                                            </span>
                                            <BooleanInput
                                                style={styles.boolean}
                                                source={getSource('create')}
                                            />
                                            <BooleanInput
                                                style={styles.boolean}
                                                source={getSource('read')}
                                            />
                                            <BooleanInput
                                                style={styles.boolean}
                                                source={getSource('update')}
                                            />
                                            <BooleanInput
                                                style={styles.boolean}
                                                source={getSource('delete')}
                                            />
                                            {scopedFormData.section ===
                                                'appliance' && (
                                                <BooleanInput
                                                    style={styles.boolean}
                                                    source={getSource(
                                                        'disenroll'
                                                    )}
                                                />
                                            )}
                                            {scopedFormData.section ===
                                                'translation' && (
                                                <BooleanInput
                                                    style={styles.boolean}
                                                    label="restrict languages"
                                                    source={getSource(
                                                        'restrictLanguages'
                                                    )}
                                                />
                                            )}
                                            {scopedFormData.section ===
                                                'asset' && (
                                                <BooleanInput
                                                    style={styles.boolean}
                                                    label="publish asset"
                                                    source={getSource(
                                                        'asset-publish'
                                                    )}
                                                />
                                            )}
                                            {scopedFormData.section ===
                                                'mock-demo' && (
                                                <BooleanInput
                                                    style={styles.boolean}
                                                    label="publish mock"
                                                    source={getSource(
                                                        'mock-publish'
                                                    )}
                                                />
                                            )}
                                        </div>
                                        <div className={classes.formIterator}>
                                            {scopedFormData.restrictLanguages &&
                                                permissions &&
                                                permissions.language &&
                                                permissions.language.read &&
                                                languages &&
                                                languages.length &&
                                                languages.map((lang) => (
                                                    <BooleanInput
                                                        style={styles.boolean}
                                                        source={getSource(
                                                            lang.id
                                                        )}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </MyCreate>
        </>
    );
};
const mapStateToProps = (state) => ({
    languages: state.admin.resources.language.data,
});
const styles = {
    boolean: {
        flexDirection: 'row',
        marginRight: 20,
    },
};
export default connect(mapStateToProps)(RoleCreate);
