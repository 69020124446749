import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CustomSelectInput } from 'candy-commons-components';
import React, { Fragment, useEffect, useLayoutEffect } from 'react';
import { Loading, required, useGetList } from 'react-admin';
import {
    CONNECTIVE,
    CRITERIA,
    CRITERIA_MAP,
    FORM_TYPE,
    INPUT_VARIANT,
    PROMO,
    getCriteriaBasedOnPromoType,
} from '../config';
import { useGetOneQuery } from '../hooks/normalizeFormDataToSend';
import { BasicQueryInput } from './BasicQueryInput';
const WrapAccordion = ({ title, children, defaultExpanded = false }) => {
    return (
        <Accordion defaultExpanded={defaultExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};
export const QueryForm = ({
    level,
    promoType,
    criteria,
    getSelectedCriteria,
    isEditDisabled,
}) => {
    const actions = { add: 'ADD', remove: 'REMOVE' };
    const [activeInput, updateActiveInput] = React.useState(
        getCriteriaBasedOnPromoType(level)
    );
    const {
        data: promoSpaceData,
        total,
        isLoading: isLoadingPromoSpaceInput,
        error,
    } = useGetList('promo-spaces');
    useEffect(() => {
        getSelectedCriteria({ criteria: activeInput, level });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeInput, level]);
    const hasAtLeastOneActiveInput = () => {
        return Object.values(activeInput).some((el) => el.isActive);
    };
    const updateQueryInput = ({ action, key }) => {
        updateActiveInput((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                isActive: action === actions.add ? true : false,
            },
        }));
    };
    const addQueryInputHandler = (key) => () => {
        updateQueryInput({ action: actions.add, key });
    };
    const removeQueryInputHandler = (key) => () => {
        updateQueryInput({ action: actions.remove, key });
    };
    const ActiveInputKeys = () => {
        return (
            <Box>
                {Object.keys(activeInput)
                    .filter((key) => CRITERIA[key]?.showIn.includes(promoType))
                    .filter((key) => !activeInput[key].isActive)
                    .filter((key) => {
                        if (level === 1 && promoType === PROMO.CAMPAIGNS) {
                            return key !== CRITERIA.country.type;
                        } else if (level === 1) {
                            return (
                                key !== CRITERIA?.triggerEvent?.type &&
                                key !== CRITERIA?.country?.type
                            );
                        } else {
                            return true;
                        }
                    })
                    .map((key) => (
                        <Chip
                            key={key}
                            label={CRITERIA[key].label}
                            onClick={addQueryInputHandler(key)}
                            style={{ margin: '5px' }}
                            disabled={isEditDisabled}
                        />
                    ))}
            </Box>
        );
    };
    useLayoutEffect(() => {
        if (criteria) {
            Object.keys(criteria).map((k) => {
                updateActiveInput((prev) => ({
                    ...prev,
                    [k]: {
                        ...prev[k],
                        isActive: criteria[k]?.rule ? true : false,
                    },
                }));
            });
        }
    }, [criteria]);
    if (isLoadingPromoSpaceInput) {
        return <Loading />;
    }
    return (
        <>
            <Box>
                <ActiveInputKeys />
            </Box>
            <Box>
                {hasAtLeastOneActiveInput() && (
                    <CustomSelectInput
                        validate={[required()]}
                        label={`${
                            level === 1 ? 'Criteria' : 'Nested Criteria'
                        }`}
                        source={`${
                            level === 1
                                ? 'criteria.connective'
                                : 'nestedCriteria.connective'
                        }`}
                        choices={[CONNECTIVE.and, CONNECTIVE.or]}
                        disabled={isEditDisabled}
                    />
                )}
            </Box>
            <Box>
                {CRITERIA_MAP.filter((v) => v.showIn.includes(promoType))
                    .filter((v) => v.type !== CRITERIA.promoSpaces.type)
                    .map((v, index) => {
                        const isRequiredByDefault = v?.requiredByDefault({
                            level,
                            promoType,
                        });
                        return (
                            <Fragment key={index}>
                                <BasicQueryInput
                                    typeKey={v.type}
                                    level={level}
                                    variant={v.inputType}
                                    optionsList={v?.optionList}
                                    isActive={
                                        isRequiredByDefault
                                            ? true
                                            : activeInput[v.type]?.isActive
                                    }
                                    removeInputHandler={
                                        isRequiredByDefault
                                            ? null
                                            : removeQueryInputHandler(v.type)
                                    }
                                    isEditDisabled={isEditDisabled}
                                />
                            </Fragment>
                        );
                    })}
                {promoType === PROMO.CAMPAIGNS &&
                    promoSpaceData &&
                    !isLoadingPromoSpaceInput && (
                        <BasicQueryInput
                            typeKey={CRITERIA.promoSpaces.type}
                            level={level}
                            isActive={
                                activeInput[CRITERIA.promoSpaces.type]?.isActive
                            }
                            removeInputHandler={removeQueryInputHandler(
                                CRITERIA.promoSpaces.type
                            )}
                            variant={INPUT_VARIANT.MULTIPLE_CHOICES}
                            optionsList={Object.values(promoSpaceData).map(
                                (ps) => ({
                                    id: ps.id,
                                    name: ps.slug,
                                })
                            )}
                        />
                    )}
            </Box>
        </>
    );
};
export const QueryFormManager = ({
    variant,
    promoType,
    queryID,
    getActiveCriteria,
    isEditDisabled,
}) => {
    const {
        data: detailData,
        isLoading,
        error,
        hasNestedCriteria,
        toggleHasNestedCriteria,
    } = useGetOneQuery(
        promoType === PROMO.SPACE ? 'promo-spaces' : 'promo-campaigns',
        queryID
    );
    const addNestedCriteria = () => {
        toggleHasNestedCriteria((prev) => {
            if (prev) {
                getActiveCriteria({ criteria: null, level: 2 });
            }
            return !hasNestedCriteria;
        });
    };
    if (isLoading) return <Loading />;
    if (!detailData && variant === FORM_TYPE.EDIT) return null;
    if (error) return null;
    return (
        <>
            <WrapAccordion title="Criteria" defaultExpanded>
                <QueryForm
                    level={1}
                    promoType={promoType}
                    criteria={detailData?.criteria}
                    getSelectedCriteria={getActiveCriteria}
                    isEditDisabled={isEditDisabled}
                />
            </WrapAccordion>
            <Button
                variant="outlined"
                onClick={addNestedCriteria}
                style={{ margin: '20px 0' }}
                disabled={isEditDisabled}
            >
                {hasNestedCriteria ? 'remove' : 'add'} nested criteria
            </Button>
            {hasNestedCriteria && (
                <WrapAccordion
                    title="Nested Criteria"
                    defaultExpanded={hasNestedCriteria}
                >
                    <QueryForm
                        level={2}
                        promoType={promoType}
                        criteria={detailData?.nestedCriteria}
                        getSelectedCriteria={getActiveCriteria}
                    />
                </WrapAccordion>
            )}
        </>
    );
};
