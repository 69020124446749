module.exports = {
  CREATED: 10,
  NOTIFIED: 11,
  QUEUED: 12,
  SUCCEEDED: 13,
  TERMINAL_FAIL: 14,
  REJECTED: 15,
  IN_PROGRESS: 20,
  TIMED_OUT: 21,
  FAILED: 22,
  REMOVED: 30,
  CANCELED: 31
};