import Toolbar from '@material-ui/core/Toolbar';
import SettingsIcon from '@material-ui/icons/Settings';
import {
    CustomBreadcrumbs,
    CustomFilter,
    CustomList,
    CustomSelectInput,
} from 'candy-commons-components';
import React, { useCallback } from 'react';
import {
    CreateButton,
    FunctionField,
    TextField,
    useTranslate,
} from 'react-admin';
import CustomDatagrid from '../../components/organisms/customDatagrid';
import config from '../../config';
import options from './serviceMode.mock';
import CommonApplianceFilter from '../../components/organisms/commonApplianceFilter';

const AssetListAction = ({
    basePath,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    permissions,
}) => {
    const username = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.LastAuthUser`
    );
    const token = localStorage.getItem(
        `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.idToken`
    );
    return (
        <Toolbar>
            {filters &&
                React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {permissions && permissions.asset && permissions.asset.create && (
                <CreateButton basePath={basePath} />
            )}
        </Toolbar>
    );
};
const ServiceModeList = ({ permissions, ...props }) => {
    const translation = useTranslate();
    const breadcrumbsData = [
        {
            text: translation('resources.service-mode.name'),
            url: '',
            icon: <SettingsIcon />,
        },
    ];
    const renderBrands = useCallback(
        (item) =>
            Array.isArray(item.brand) ? item.brand.join(',') : item.brand || '',
        []
    );
    const renderSeries = useCallback(
        (item) =>
            Array.isArray(item.series)
                ? item.series.join(',')
                : item.series || '',
        []
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <CustomList
                {...props}
                perPage={25}
                exporter={false}
                filters={
                    <CommonApplianceFilter
                        applianceType
                        brand
                        series
                        seriesVersion
                        optionalFilter={[
                            <CustomSelectInput
                                allowEmpty
                                source="serviceability"
                                label="serviceability"
                                choices={options}
                            />,
                        ]}
                    />
                }
                actions={<AssetListAction permissions={permissions} />}
                bulkActionButtons={
                    permissions && permissions.asset && permissions.asset.delete
                }
            >
                <CustomDatagrid
                    selectable
                    rowClick="edit"
                    choiceColumns
                    defaultColumn={
                        ('applianceType', 'brand', 'series', 'serviceability')
                    }
                >
                    <TextField source="applianceType" />
                    <FunctionField
                        label="Brand"
                        render={renderBrands}
                        source="brand"
                    />
                    <FunctionField
                        label="Series"
                        render={renderSeries}
                        source="brand"
                    />
                    <TextField source="SeriesVersion" />
                    <TextField source="serviceability" />

                    <TextField
                        source="InstallationType"
                        label="Installation Type"
                    />
                    <TextField source="loadingType" label="Loading Type" />
                    <TextField source="Display" label="Display" />
                </CustomDatagrid>
            </CustomList>
        </>
    );
};
export default ServiceModeList;
